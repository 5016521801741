import { Chip, withStyles } from "@material-ui/core";
import React from "react";

const StyleChip = withStyles({
  root: {
    backgroundColor: "transparent",
    "&&:hover": {
      backgroundColor: "rgba(169, 171, 173, 0.5)",
    },
  },
})(Chip);

class CustomChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  onChipClick = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  componentDidMount() {
    this.setState({
      isActive: this.props.selectedValue === this.props.item.ID,
    });
  }

  componentDidUpdate() {
    if (this.state.isActive && this.props.selectedValue !== this.props.item.ID)
      this.setState({ isActive: false });
    else if (
      !this.state.isActive &&
      this.props.selectedValue === this.props.item.ID
    )
      this.setState({ isActive: true });
  }

  render() {
    const { ID, name } = this.props.item;
    return (
      <div
        style={{
          backgroundColor: this.state.isActive
            ? "#A9ABAD"
            : "rgba(169, 171, 173, 0.2)",
          borderRadius: "50px",
        }}
      >
        <StyleChip
          className="chip"
          label={<div className="ws-pre">{name}</div>}
          onClick={() => {
            this.props.onChipClick(ID);
            this.props.setSelectedValue(ID);
          }}
          style={{ color: this.state.isActive ? "white" : "#848588" }}
          variant="outlined"
          clickable
        />
      </div>
    );
  }
}

export default CustomChip;
