import { Box } from "@material-ui/core";
import React from "react";
import { departments } from "../AboutUs/departmentsArray";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import InnerAccordion from "../Custom Components/InnerAccordion";

class AsAnExhibitor extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/AsExhibitor/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">As an Exhibitor</h3>
          <br></br>
          <p className="mt-3 body-text">
            Exhibits are intended to provide a unique opportunity for
            researchers and practitioners, including representatives of
            important companies in the topic areas of an event, to present their
            projects, their products and prototypes, interactive demonstrations,
            and display other materials that reveal to the community new
            directions of research and development, or to showcase new
            approaches, features, products and services.{" "}
          </p>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="Benefits of Attending an INSTICC Event as an
              Exhibitor:"
            content={
              <div>
                <ul>
                  <li>
                    Make new professional contacts with experts in the event's
                    field;
                  </li>
                  <br></br>
                  <li>
                    Strengthen your Employer Brand and increase awareness and
                    visibility of your organization;
                  </li>
                  <br></br>
                  <li>Advertise in the event Program and Book of Abstracts;</li>
                  <br></br>
                  <li>Promote new products and services;</li>
                  <br></br>
                  <li>Distribute promotional materials and merchandise;</li>
                  <br></br>
                  <li>
                    Attend event sessions, to learn from researchers the new
                    methodologies, trends and ideas;
                  </li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="2"
            title="Other registrations options:"
            content={
              <div>
                Companies and other institutions may also acquire special
                registration packages, allowing for a number of their co-workers
                or external invitees to attend the conference or congress.
                Packages can be adapted according to their registration needs,
                on the items included in the registration and on the number of
                extras required.
              </div>
            }
          />
          <h3 className="yellow-text m-5">
            <b>
              Please contact our{" "}
              <HtmlTooltip
                department={departments[0]}
                text="Events Department"
              />{" "}
              to know more about exhibiting at an INSTICC sponsored Event.
            </b>
          </h3>
        </Box>
      </div>
    );
  }
}

export default AsAnExhibitor;
