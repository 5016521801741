import { Box, Grid } from "@material-ui/core";
import React from "react";
import Information from "../Information";
import DepartmentCard from "./DepartmentCard";
import { departments } from "./departmentsArray";

class AboutUs extends React.Component {
  render() {
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/Contacts/original.png" alt="Banner" className="w-100"></img>
        <Box className="main-box">
          <p className="title gold">Departments</p>
          <br></br>
          <Grid container spacing={5}>
            {departments.map((department, index) => {
              return (
                <Grid item xs={12} sm={6} md={3}>
                  <DepartmentCard department={department} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <div className="silver-background">
          <Information />
        </div>
      </div>
    );
  }
}

export default AboutUs;
