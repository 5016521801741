import axios from "axios";
import React from "react";
import queryString from "query-string";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { postLog } from "../../js/httpRequests";

export default class BecomeHonorary extends React.Component {
  state = {
    isLoading: true,
    success: false,
    time: 5,
    text: "",
  };

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    axios
      .post(`/api/users/${params["ID"]}/becomeHonorary`)
      .then(async (res) => {
        if (res.status !== 200) throw res.data;
        let user = await (await axios.get(`/api/login/user`)).data;
        postLog(
          "User became Honorary Member",
          "User became an honorary member via an invitation link",
          user.firstName + " " + user.lastName,
          user.email
        );
        this.setState({
          success: true,
          isLoading: false,
        });
        setInterval(() => {
          this.setState({ time: --this.state.time });
          if (this.state.time === 0) window.location.replace("/");
        }, 1000);
      })
      .catch((err) =>
        this.setState({
          text: "An error occurred while validating your account.",
          isLoading: false,
        })
      );
  }

  render() {
    return (
      <div className="content">
        <Box p={5}>
          <Grid container justify="center" style={{ padding: "200px 0" }}>
            {this.state.isLoading ? (
              <CircularProgress />
            ) : (
              <h1>
                {this.state.success
                  ? `You've successfully became an Honorary member of INSTICC! Redirecting to Homepage in ${this.state.time} seconds...`
                  : this.state.text}
              </h1>
            )}
          </Grid>
        </Box>
      </div>
    );
  }
}
