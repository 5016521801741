import { Box } from "@material-ui/core";
import React from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import { departments } from "../AboutUs/departmentsArray";

class PartnershipOpportunities extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PartnershipOpportunities/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Partnership Opportunities</h3>
          <br></br>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="Joining the INSTICC Network"
            content={
              <div>
                Following its goal of contributing towards the advancement of
                science and the dissemination of knowledge, INSTICC is
                constantly on the outlook for new partners to expand and
                diversify its network, thus creating added-value for the INSTICC
                community. Simultaneously, we believe our publications, events
                and activities are a great way for industrial partners,
                universities, research groups and other international
                associations to interact with a vibrant community of decision
                makers, researchers, scientists and graduate students.
              </div>
            }
          />
          <InnerAccordion
            defaultActiveKey="2"
            eventKey="2"
            title="Partnership Opportunities at INSTICC Events"
            content={
              <div>
                Partners can benefit from INSTICC events by presenting their
                research and scientific results, be it by presenting a paper,
                hosting a tutorial or instructional course or demonstrating its
                products in demo sessions, by contributing towards panels and
                discussions in the event's field of interest or by presenting
                their organization, be it by setting up an exhibition booth, by
                advertising in the event's web presence or printed materials or
                by sponsoring keynote speakers, specific sessions or additional
                social events.
              </div>
            }
          />
          <InnerAccordion
            defaultActiveKey="3"
            eventKey="3"
            title="Partnership Opportunities through INSTICC Publications
              and Media"
            content={
              <div>
                INSTICC publications include journals published with partner
                organizations, the INSTICC newsletter and the INSTICC website,
                as well as a variety of social media outlets. Partners can
                benefit from the audience of INSTICC media by submitting papers,
                proposing stories, profiles or opinion columns, sending
                information or advertisements relevant to the INSTICC community
                and advertising in all the INSTICC publications and websites.
                INSTICC is also open to new publication suggestions and
                ventures.
              </div>
            }
          />
          <InnerAccordion
            defaultActiveKey="4"
            eventKey="4"
            title="Contact"
            content={
              <div>
                INSTICC is open to partnership possibilities beyond the examples
                mentioned here and welcomes suggestions and cooperation
                proposals. Those interested in cooperating with INSTICC should
                contact our{" "}
                <HtmlTooltip
                  department={departments[3]}
                  text="Institutional Relations Office"
                />
                .
              </div>
            }
          />
        </Box>
      </div>
    );
  }
}

export default PartnershipOpportunities;
