import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Slide,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "axios";
import { postLog } from "../../js/httpRequests";
import ForgotPassword from "./ForgotPassword";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #9f9234",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid #9f9234`,
        },
        "&:after": {
          borderBottom: `2px solid #9f9234`,
        },
      },
    },
  },
});

class LoginSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      forgotPasswordIsOpen: false,
      isLoading: false,
      form: {
        username: "",
        password: "",
      },
      error: "",
    };
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) this.props.onClose();
  };

  handleForgotPasswordClose = () => {
    this.setState({ forgotPasswordIsOpen: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    if (this.props.onOpen) this.props.onOpen();
  };

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true, error: "" });
    Axios.post("/api/login", this.state.form)
      .then((req) => {
        this.setState({ isLoading: false });
        if (req.response && req.response.status === 401)
          throw req.response.data;
        else if (req.status === 200) {
          localStorage.setItem("isLoggedIn", true);
          postLog(
            "Login",
            "User logged in",
            req.data.name,
            this.state.form.username
          );
          window.location.href = "/MyAccount";
        }
        //if (req.status === 200) window.location.reload();
      })
      .catch((err) => this.setState({ error: err }));
  };

  render() {
    const { open, forgotPasswordIsOpen } = this.state;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Tooltip title="Log in">
            <Button
              disableElevation
              variant="contained"
              size="small"
              className="gray-login-button"
              onClick={this.handleClickOpen}
              startIcon={<VpnKeyIcon className="dimgray-icon" />}
            >
              {!this.props.noText ? (
                <span className="dimgray-label">Log in</span>
              ) : null}
            </Button>
          </Tooltip>
          <Dialog
            className="popup-dialog"
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            scroll="body"
            PaperProps={{
              style: { borderRadius: 25 },
            }}
          >
            <Box className="yellow-border" borderRadius={25}>
              <DialogContent>
                <Box p={5}>
                  <form onSubmit={this.onSubmit}>
                    <Grid container spacing={2} component={Box}>
                      <Grid item xs={12}>
                        <TextField
                          label="Username"
                          fullWidth
                          placeholder="Enter your username"
                          name="username"
                          InputLabelProps={{
                            style: { color: "#9f9234" },
                          }}
                          onChange={this.onTextFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          name="password"
                          type="password"
                          fullWidth
                          placeholder="Enter your password"
                          InputLabelProps={{
                            style: { color: "#9f9234" },
                          }}
                          onChange={this.onTextFieldChange}
                        />
                      </Grid>
                      <Grid item xs>
                        <Grid container justify="flex-end">
                          <Link
                            href="#"
                            className="dimgray-label"
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                              this.setState({ forgotPasswordIsOpen: true });
                            }}
                          >
                            Forgot your password?
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br></br>
                    {this.state.isLoading ? (
                      <Grid container justify="center">
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Button
                          type="submit"
                          fullWidth
                          className="login-button mb-3"
                        >
                          Log In
                        </Button>
                      </Grid>
                    )}
                    {this.state.error ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {this.state.error}
                      </p>
                    ) : null}
                  </form>
                </Box>
                <Dialog
                  className="popup-dialog"
                  open={forgotPasswordIsOpen}
                  maxWidth="md"
                  fullWidth
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.handleForgotPasswordClose}
                  scroll="body"
                  PaperProps={{
                    style: { borderRadius: 25 },
                  }}
                >
                  <Box className="yellow-border" borderRadius={25}>
                    <DialogContent>
                      <ForgotPassword
                        callback={() => {
                          this.setState({ forgotPasswordIsOpen: false });
                        }}
                      />
                    </DialogContent>
                  </Box>
                </Dialog>
              </DialogContent>
            </Box>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default LoginSignUp;
