import { Card, CardActionArea, Grid } from "@material-ui/core";
import React from "react";
import config from "../../config";

class PartnerCard extends React.Component {
  render() {
    return (
      <Card elevation={0}>
        <CardActionArea
          href={config.partnersBasePath + "/" + this.props.partner._id}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Grid container justify="center">
                <img
                  className="partner-card-image"
                  src={
                    config.partnersImageBasePath + this.props.partner.mainPhoto
                  }
                  alt="Partner"
                ></img>
              </Grid>
            </Grid>
            <Grid item xs={12} className="pl-2 pr-2">
              <br></br>
              <div>
                <h4
                  style={{ height: this.props.titleHeight }}
                  className="news-card-title text-align-center"
                >
                  <b>{this.props.partner.name}</b>
                </h4>
              </div>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}

export default PartnerCard;
