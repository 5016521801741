import { Box, Grid,Button } from "@material-ui/core";
import React from "react";
import Moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import MoreButton from "../../Custom Components/MoreButton";
import config from "../../../config";
import { getTopicName } from "../../../js/auxFunctions";
import NewsCard from "../../Community/News/NewsCard";
import MultiSlider from "../../MultiSlider";
import NavigationBar from "../../NavigationBar";
import Footer from "../../Footer";
import swal from "sweetalert";


class NewsPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: {},
      relatedNews: [],
      topics: [],
      isLoading: true,
      loadedImage: false,
    };
  }

  async componentDidMount() {

    const news = JSON.parse(localStorage.getItem("previewNews"));
    const relatedNews = await (await axios.get("/api/news/Random/3")).data;
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });
    this.setState({
      news: news,
      relatedNews: relatedNews,
      topics: topicsReq.data,
      isLoading: false,
      isUpdate: news._id != undefined ? true:false,
    });
    
    document.title = "News " + news.title;
  }

  
  static getDerivedStateFromProps(props, state) {
    if (props.preview) state.news = props.news;

    return state.news;
  }


  // onSubmit = async (isUpdate) => {
  
  //   if (!this.checkForErrors()) {
  //     let object = Object.assign({}, this.state.news);
  //     object.content = editorToHtml(this.state.news.content);
  //     object.highlightedText = editorToHtml(this.state.news.highlightedText);

  //     if (!isUpdate) {
  //       object["insertedDate"]["personID"] = this.state.user._id;


  //       let objectID = await Axios.post("/api/news", {
  //         body: object,
  //       })

  //       if (document.getElementById("fileBanner").files.length > 0 && objectID) {
  //         var file = document.getElementById("fileBanner").files[0];
  //         var formData = new FormData();
  //         formData.append("file", file);
  //         fetch(imageRepositoryUrl + `/api/Materials/613f5d386b364a4e38e1dd00/${objectID.data}/PortalManager`,
  //           {
  //             method: "POST",
  //             body: formData,
  //           }).then((response) => {
  //             if (response.status == 200) {
  //               let pathImage = `https://insticc.org/images/portal/news/${objectID.data}/banner/original.png`;
  //               object.mainPhoto = pathImage;
  //               put(objectID.data, object, "news", () => {
  //                 postLog(
  //                   "User updated news",
  //                   "User updated a news information",
  //                   this.state.user.firstName + " " + this.state.user.lastName,
  //                   this.state.user.email
  //                 );
  //                 this.props.history.push("/Backoffice/News");
  //               });
  //             }
  //             else {
  //             }
  //           }).catch((exception) => {
  //             console.log(exception);
  //           });
  //       }
  //       else {

  //         swal({
  //           title: "Sucess",
  //           text: "Information successfully updated",
  //           icon: "success",
  //         });
  //       }

  //     } else {
  //       if (
  //         this.state.lastStatus !== "Published" &&
  //         object.status === "Published"
  //       ) {
  //         object["publishedDate"]["personID"] = this.state.user._id;
  //         object["publishedDate"]["date"] = Date.now();
  //       }

  //       let objectID = await Axios.put("/api/news/" + object._id, {
  //         body: object,
  //       })

  //       if (document.getElementById("fileBanner").files.length > 0 && object._id) {
  //         var file = document.getElementById("fileBanner").files[0];
  //         var formData = new FormData();
  //         formData.append("file", file);
  //         fetch(imageRepositoryUrl + `/api/Materials/613f5d386b364a4e38e1dd00/${object._id}/PortalManager`,
  //           {
  //             method: "POST",
  //             body: formData,
  //           }).then((response) => {
  //             if (response.status == 200) {
  //               let pathImage = `https://insticc.org/images/portal/news/${object._id}/banner/original.png`;
  //               object.mainPhoto = pathImage;
  //               put(object._id, object, "news", () => {
  //                 postLog(
  //                   "User updated news",
  //                   "User updated a news information",
  //                   this.state.user.firstName + " " + this.state.user.lastName,
  //                   this.state.user.email
  //                 );
  //                 this.props.history.push("/Backoffice/News");
  //               });
  //             }
  //             else {
  //             }
  //           }).catch((exception) => {
  //             console.log(exception);
  //           });
  //       }
  //       else {
  //         swal({
  //           title: "Sucess",
  //           text: "Information successfully updated",
  //           icon: "success",
  //         });
  //       }


  //       // });

  //     }
  //   }
  //   this.setState({
  //     isLoading: false,
  //   });
  // };

  render() {
    const { news, relatedNews, isLoading, topics } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div>
          <NavigationBar />
          <div className={this.props.preview ? "" : "content"}>
            <Button
              variant="contained"
              className="yellow-bg"
              onClick={() => this.onSubmit(this.state.isUpdate)}
            >
              {this.state.isUpdate ? "Update" : "Create"}
            </Button>
            <Box className="main-box">
              <div className="details-image-div">
                <img
                  src={news.mainPhoto}
                  className="details-image"
                  style={this.state.loadedImage ? {} : { display: "none" }}
                  onLoad={() => this.setState({ loadedImage: true })}
                  alt="Banner"
                />
              </div>
              <Grid container justify="center">
                <Grid item xs={12} md={10}>
                  <Grid container justify="center"></Grid>
                </Grid>
              </Grid>
              <br></br>
              <br></br>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="title gold">{news.title}</h3>
                  <h4 className="news-details-date paragraph">
                    {news.interestAreas
                      .map((id) => {
                        return getTopicName(id, topics);
                      })
                      .join(", ")}
                    {"\n\n"}
                    Posted{" "}
                    {news.firstName || news.middleName || news.lastName
                      ? "by " +
                      news.firstName +
                      " " +
                      news.middleName +
                      " " +
                      news.lastName
                      : ""}{" "}
                    on{" "}
                    {Moment(news.publishedDate.date).format(
                      "DD MMM yyyy | h:mm A"
                    )}
                  </h4>
                  <br></br>
                  <br></br>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: news.content,
                    }}
                  />
                  {news.gallery.length > 0 && (
                    <div className="gallery-slider-div">
                      <MultiSlider
                        items={news.gallery}
                        autoPlaySpeed={2000}
                        desktopItems={news.gallery.length / 2}
                        autoPlay
                        borderColor="transparent"
                        arrows={false}
                        imageBaseRef={""}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <br></br>
                  <h4 className="subtitle fw-bold text-align-center">
                    Related News
                  </h4>
                  <br></br>
                  <br></br>
                  <Grid container spacing={2}>
                    {relatedNews.map((item, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={"Related #" + index}
                        >
                          <NewsCard
                            news={item}
                            showDescription={false}
                            refID={this.state.news._id}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container justify="center">
                    <MoreButton title="More News" href={config.newsBasePath} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
          <Footer />
        </div>
      );
  }
}

export default NewsPreview;
