import React from "react";
import Select from "react-select";

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 25,
    backgroundColor: state.selectProps.backgroundColor || "transparent",
    borderColor: state.selectProps.baseBorderColor || "#9f9234",
    boxShadow: null,
    "&:hover": {
      borderColor: state.selectProps.hoverBorderColor || "#756c27",
    },
    paddingLeft: 10,
    paddingBottom: state.selectProps.paddingBottom || 0,
    maxHeight: state.selectProps.height || 40,
    minHeight: state.selectProps.height || "none",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};

const CustomSelect = ({
  defaultValue,
  options,
  value,
  onChange,
  name,
  height,
  paddingBottom,
  baseBorderColor,
  hoverBorderColor,
  backgroundColor,
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      value={value}
      styles={customStyles}
      height={height}
      paddingBottom={paddingBottom}
      baseBorderColor={baseBorderColor}
      hoverBorderColor={hoverBorderColor}
      backgroundColor={backgroundColor}
      onChange={onChange}
      name={name}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: "#d1c045",
          primary25: "#ede5a6",
          primary: "#d1c045",
        },
      })}
    />
  );
};

export default CustomSelect;
