import { Box, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import {
  isLoggedIn,
  passwordTester,
  passwordTesterHelperText,
} from "../../js/auxFunctions";
import RoundTextField from "../Custom Components/RoundTextField";
import StandardButton from "../Custom Components/StandardButton";
import { postLog, put, sendEmail } from "../../js/httpRequests";
import axios from "axios";
// var crypto = require('crypto');

const CustomTooltip = (props) => {
  return (
    <Tooltip
      title={
        !props.customTitle ? (
          <p className="pt-3">
            Must respect the following:
            <ul className="pl-3">
              {props.isRequired ? <li>Not empty</li> : null}
              <li>No special characters</li>
              <li>Maximum 25 characters</li>
            </ul>
          </p>
        ) : (
            props.customTitle
          )
      }
      className="mb-3"
    >
      <HelpIcon style={{ fontSize: 15 }} />
    </Tooltip>
  );
};

export default class SetNewPassword extends React.Component {
  state = {
    pin: "",
    password: "",
    confirmPassword: "",
    uri: window.location.href.split("?")[0],
    isLoading: true,
  };

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  validate = () => {
    const { password, confirmPassword, pin } = this.state;
    let temp = {};
    temp.pin = pin !== "";
    temp.password = !passwordTester(password);
    temp.confirmPassword = password === confirmPassword;

    return Object.values(temp).every((x) => x === true);
  };

  onSubmit = async (event) => {
    event.preventDefault();
    put(
      "",
      this.state,
      "users/RecoverPassword",
      async () => {
        let pinObj = await (await axios.get(`/api/users/pin/${this.state.pin}`))
          .data;
        let user = await (await axios.get(`/api/users/${pinObj.idPerson}`)).data;
        axios
          .post("/api/login", {
            password: this.state.password,
            username: user.email,
          })
          .then((req) => {
            if (req.response && req.response.status === 401)
              throw req.response.data;
            else if (req.status === 200) {
              localStorage.setItem("isLoggedIn", true);
              postLog(
                "Login",
                "User logged in",
                user.firstName + " " + user.lastName,
                user.email
              );
              window.location.href = "/MyAccount";
            }
          })
          .catch((err) => alert(err));
      },
      "",
      false,
      "warning",
      async (err) => {
        if (err.includes("Token Expired")) {
          let pinObj = await (
            await axios.get(`/api/users/pin/${this.state.pin}`)
          ).data;
          let user = await (await axios.get(`/api/users/${pinObj.idPerson}`)).data;
          let status = await sendEmail("Forgot Password", user);
          if (!status) alert("An error occurred while sending you an email");
        }
      }
    );
  };

  async componentDidMount() {
    if (await isLoggedIn()) window.location.href = "/myAccount";
    this.setState({ isLoading: false, token: this.props.match.params.token });
  }

  render() {
    const { password, confirmPassword, pin, isLoading } = this.state;
    return (
      <div className="content">
        <Box p={5}>
          {isLoading ? (
            <CircularProgress />
          ) : (
              <div>
                <h1>Set a new password</h1>
                <form>
                  <Grid container spacing={3} className="pt-5">
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={2} className="text-align-right">
                          <b>
                            Pin:
                          <CustomTooltip
                              customTitle={
                                <p className="pt-3">
                                  The token received via email
                              </p>
                              }
                            />
                          </b>
                        </Grid>
                        <Grid item xs>
                          <RoundTextField
                            name="pin"
                            backgroundColor="#ece9d6"
                            error={!pin}
                            helperText={!pin ? "Required" : ""}
                            onChange={this.onEventChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={2} className="text-align-right">
                          <b>
                            Password:
                          <CustomTooltip
                              customTitle={
                                <p className="pt-3">
                                  Must respect the following:
                                <ul className="pl-3">
                                    <li>Not empty</li>
                                    <li>At least 1 uppercase</li>
                                    <li>At least 1 lowercase</li>
                                    <li>At least 1 special character</li>
                                    <li>At least 1 digit</li>
                                    <li>Between 8 and 16 characters</li>
                                    <li>Mustn't be equal to old password</li>
                                  </ul>
                                </p>
                              }
                            />
                          </b>
                        </Grid>
                        <Grid item xs>
                          <RoundTextField
                            type="password"
                            name="password"
                            backgroundColor="#ece9d6"
                            error={passwordTester(password)}
                            helperText={
                              passwordTesterHelperText(password) !== ""
                                ? passwordTesterHelperText(password)
                                : ""
                            }
                            onChange={this.onEventChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={2} className="text-align-right">
                          <b>Confirm Password:</b>
                        </Grid>
                        <Grid item xs>
                          <RoundTextField
                            type="password"
                            name="confirmPassword"
                            backgroundColor="#ece9d6"
                            error={password !== confirmPassword && password}
                            helperText={
                              password !== confirmPassword && password
                                ? "Passwords dont match"
                                : ""
                            }
                            onChange={this.onEventChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" className="pt-2 pb-5">
                    <StandardButton
                      type="submit"
                      variant="outlined"
                      disabled={!this.validate()}
                      onClick={this.onSubmit}
                    >
                      Reset Password
                  </StandardButton>
                  </Grid>
                </form>
              </div>
            )}
        </Box>
      </div>
    );
  }
}
