import React, { Component } from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";
import Accordion from "../Custom Components/Accordion";
import { departments } from "../AboutUs/departmentsArray";
import HtmlTooltip from "../Custom Components/HtmlTooltip";

class EventStructure extends Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/EventStructure/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Accordion
          defaultActiveKey="0"
          eventKey="0"
          headerTitle="Event Structure"
          headerBody="INSTICC international events are unique in their role for
                      knowledge dissemination, as they combine advantages of
                      publication including a rigorous double blind review
                      process, indexed proceedings and also special journal
                      editions and books with the opportunity of direct feedback
                      from scientists and authors from all over the world,
                      including internationally renowned keynotes speakers.
                      Below are highlighted some points of interest:"
          body={
            <p className="paragraph full-width-accordion-padding body-text">
              Keynote lectures, discussion panels and paper presentations
              provide an overview of the state of the art of the event's area of
              interest and of all major research questions in the field;
              {"\n"}
              {"\n"} Speakers inspire each other with new methodologies,
              approaches and results;{"\n"}
              {"\n"} All participants have access to all articles presented at
              the event;{"\n"}
              {"\n"} Attendees benefit from several networking opportunities,
              meet colleagues from all over the world, gain insight into ongoing
              and future research projects and have direct access to world-class
              keynote speakers, conference chairs and other high-ranking members
              of the community;
              {"\n"}
              {"\n"} Authors benefit from a double-blind independent review
              process and from direct feedback and personalized improvement
              suggestions. All papers are published in the event proceedings
              under an ISBN and are made available in the{" "}
              <a
                href="http://www.insticc.org/portal/Publications/DigitalLibrary.aspx"
                target="_blank"
                className="yellow-link"
                rel="noopener noreferrer"
              >
                SCITEPRESS Digital Library
              </a>{" "}
              with an individual DOI and submitted for indexation to all major
              indexes.
            </p>
          }
        />
        <Accordion
          defaultActiveKey="1"
          eventKey="1"
          headerBody="Conferences and Congresses are scientific events comprising
                    the presentation and discussion of academic research, the
                    publication of papers and collaboration opportunities for
                    researchers and practitioners to discuss and network with
                    each other. Keynote lectures, discussion panels and paper
                    presentations offer insights into the state of the art in
                    the event's area of interest as well as relevant research
                    questions in the field."
          bodyClassName="white"
          body={
            <div>
              <InnerAccordion
                eventKey="2"
                title="Tutorials and Instructional Courses"
                content={
                  <div>
                    Tutorials and Instructional Courses are practical sessions
                    dedicated to hands-on sessions in a specialized field,
                    technique, skill or application, presented by an
                    internationally distinguished instructor in the field.
                    Tutorials and Instructional Courses complement the
                    conference or congress technical sessions by providing
                    participants a different learning experience. They can be
                    scheduled for half-day or full-day. If you’d like to suggest
                    holding a Tutorial or an Instructional Course at an INSTICC
                    conference or congress, please contact the event secretariat
                    or the{" "}
                    <HtmlTooltip
                      department={departments[0]}
                      text="INSTICC Events Department"
                    />
                    .
                  </div>
                }
              />
              <InnerAccordion
                eventKey="3"
                title="Special Sessions and Symposia"
                content={
                  <div>
                    The goal of Special Sessions and Symposia - minimum four
                    papers, maximum nine - is to provide a forum for debating
                    well focused and innovative topics. Paper presentations and
                    publication in the proceedings functions are similarly to
                    any other conference or congress session. Any proponents are
                    welcome to suggest Special Sessions or Symposia to take
                    place at an INSTICC conference or congress, by filling out a
                    form at the corresponding website or by sending an email to
                    the event secretariat.
                  </div>
                }
              />
              <InnerAccordion
                eventKey="4"
                title="Panels"
                content={
                  <div>
                    Panels are debate sessions where a small number of experts
                    are invited to engage in a public discussion of a particular
                    theme, usually starting with short position statements and
                    then debating their points of view with the audience, during
                    a limited slot of time of about 90 minutes. Two types of
                    panel can be considered: Research Panels, based on the
                    presence of academic community members and focused on the
                    discussion of research topics; and Industrial Panels, with
                    the presence of practitioners and oriented to promote
                    knowledge exchanges between academia and industry.
                  </div>
                }
              />
              <InnerAccordion
                eventKey="5"
                title="Workshops"
                content={
                  <div>
                    Workshops are independent events that take advantage of
                    using the logistics settings of a conference or a congress.
                    This is an opportunity to address a topic area in depth,
                    giving it the proper visibility, and allowing also more time
                    than usual at a regular conference or congress sessions for
                    debate. Workshops include 10 or more paper presentations and
                    can be scheduled for one day or two days, integrated in
                    INSTICC existing events. To propose a workshop please use a
                    form at the corresponding website, send an email to the
                    event secretariat or contact the{" "}
                    <HtmlTooltip
                      department={departments[0]}
                      text="INSTICC Events Department"
                    />
                  </div>
                }
              />
              <InnerAccordion
                eventKey="6"
                title="Demonstrations"
                content={
                  <div>
                    Demonstrations provide researchers and practitioners with an
                    exciting and interactive opportunity to showcase their
                    systems, artifacts and/or research prototypes, either in a
                    regular oral session or as an exhibitor. Any written support
                    materials may be distributed locally but not published in
                    the proceedings. Authors who already present a paper at the
                    conference may apply for a demonstration, to complement but
                    not to replace their paper presentation. Demonstrations can
                    also be made by sponsor companies or as a mixed initiative
                    involving researchers and industrial partners.
                    Demonstrations are based on an informal setting that
                    encourages presenters and participants to engage in
                    discussions about presented work. This is an opportunity for
                    the participants to disseminate practical results of their
                    research and to network with other applied researchers or
                    business partners. Concerning the format of the demo, we
                    could accommodate it either as demonstration in a booth
                    (physical area of 4 sq.meter, with a table and 2 chairs) at
                    the posters area, for a half-day period, or as an oral
                    presentation at a session especially set up for
                    demonstrations. All interested parties in organizing a
                    demonstration at an INSTICC event should contact the event
                    secretariat or the{" "}
                    <HtmlTooltip
                      department={departments[0]}
                      text="INSTICC Events Department"
                    />
                    .
                  </div>
                }
              />
              <InnerAccordion
                eventKey="7"
                title="Exhibitions"
                content={
                  <div>
                    Exhibitions are intended to display information about
                    organizations, products or services. A limited space for a
                    stand is made available at an appropriate area, easily
                    accessible to all event delegates. Different sizes and types
                    of stand are usually available, although the default is to
                    provide a table, two chairs and an internet connection.
                  </div>
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}
export default EventStructure;
