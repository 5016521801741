import { TextareaAutosize } from "@material-ui/core";
import React from "react";

const RoundTextareaAutosize = ({
  name,
  value,
  onChange,
  minHeight,
  placeholder,
  borderRadius,
  backgroundColor,
  children,
  params,
}) => {
  return (
    <TextareaAutosize
      {...params}
      name={name}
      value={value}
      placeholder={placeholder}
      style={{
        backgroundColor: backgroundColor || "white",
        padding: "15px",
        borderRadius: borderRadius || "50px",
        width: "100%",
        minHeight: minHeight || "40px",
      }}
      className="textarea"
      onChange={onChange}
    >
      {children}
    </TextareaAutosize>
  );
};

export default RoundTextareaAutosize;
