import { Box, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import NewsCard from "./NewsCard";
import ChipMultiSlider from "../../Custom Components/ChipMultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hasFilteredTopics } from "../../../js/auxFunctions";
import axios from "axios";

const itemsPerPage = 100;

class NewsArchive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
      news: [],
      filterOptions: [],
      selectedTopics: [],
    };
  }

  handlePaginationChange = (event, value) => {
    const diff = value - this.state.page;
    this.setState({
      page: value,
      startIndex: this.state.startIndex + diff * itemsPerPage,
      endIndex: this.state.endIndex + diff * itemsPerPage,
    });
  };

  onChipClick = (value) => {
    let newSelected = [...this.state.selectedTopics];
    if (newSelected.includes(value)) newSelected = [];
    else {
      newSelected = [];
      newSelected.push(value);
    }

    this.setState({
      selectedTopics: newSelected,
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  async componentDidMount() {
    const res = await axios.get("/api/news");
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    this.setState({
      news: res.data || [],
      filterOptions: topicsReq.data || [],
      isLoading: false,
    });
  }

  render() {
    let {
      news,
      startIndex,
      endIndex,
      selectedTopics,
      filterOptions,
      isLoading,
    } = this.state;
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/News/original.png" alt="Banner" className="w-100"></img>
        {!isLoading && (
          <div className="pt-5 pb-5">
            <ChipMultiSlider
              filterOptions={filterOptions}
              onChipClick={this.onChipClick}
            />
          </div>
        )}
        <Box className="main-box" style={{ paddingTop: 0 }}>
          {this.state.isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Grid container component={Box} spacing={5}>
                {news
                  .filter(function (item, index) {
                    return hasFilteredTopics(
                      selectedTopics,
                      item.interestAreas
                    );
                  })
                  .map((item, index) => {
                    if (index >= startIndex && index <= endIndex)
                      return (
                        <Grid item xs={12} sm={6} lg={3} key={"News #" + index}>
                          <NewsCard news={item} titleHeight="50px" />
                        </Grid>
                      );
                    else return null;
                  })}
                {news.length <= 0 && (
                  <h3 className="text-align-center">No news avaialable</h3>
                )}
              </Grid>
            </div>
          )}
          <Grid container justify="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              style={{ padding: "50px" }}
              count={Math.ceil(
                news.filter((x) => {
                  return hasFilteredTopics(selectedTopics, x.interestAreas);
                }).length / itemsPerPage
              )}
              page={this.state.page}
              onChange={this.handlePaginationChange}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default NewsArchive;
