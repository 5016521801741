import { Card, CardActionArea, CardMedia, Grid } from "@material-ui/core";
import React from "react";
import config from "../../../config";

class KeynoteInterviewCard extends React.Component {
  render() {
    
    return (
      <Card elevation={0} className="transparent-bg">
        <CardActionArea
          href={
            config.keynotesInterviewsBasePath +
            "/" +
            this.props.keynoteInterview._id +
            (this.props.refID ? "?refID=" + this.props.refID : "")
          }
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <CardMedia
                frameBorder={0}
                component="iframe"
                style={{ height: "250px" }}
                src={this.props.keynoteInterview.mainVideo}
                title="Contemplative Reptile"
              />
            </Grid>
            <Grid item xs={12}>
              <div className="p-2">
                <div style={{ minHeight: this.props.minTitleHeight }}>
                  <h4 className="kl-card-title">
                    <b>
                      {this.props.keynoteInterview.type === "Keynotes" ||
                        this.props.keynoteInterview.type === ""
                        ? "Keynote by "
                        : "Interview with "}
                      {this.props.keynoteInterview.personTitle}{" "}
                      {this.props.keynoteInterview.firstName}{" "}
                      {this.props.keynoteInterview.middleName}{" "}
                      {this.props.keynoteInterview.lastName}
                    </b>
                  </h4>
                  <h5 className="kl-card-subtitle">
                    {this.props.keynoteInterview.title}
                  </h5>
                  <h5 className="kl-card-subtitle">
                    {this.props.keynoteInterview.event.acronym + " " + this.props.keynoteInterview.event.year + ": " + (this.props.keynoteInterview.event.location.toLowerCase().includes("online streaming") ? "Online Streaming" : this.props.keynoteInterview.event.location)}
                  </h5>
                  <h5 className="kl-card-subtitle">
                    {this.props.keynoteInterview.topics  ?this.props.keynoteInterview.topics.join(', '): ''}
                  </h5>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}

export default KeynoteInterviewCard;
