import { Box, Button, Card, CardActionArea, Grid } from "@material-ui/core";
import React from "react";
import config from "../../config";
import { hasFilteredTopics } from "../../js/auxFunctions";

function getMonth(date) {
  let d = new Date(date);
  return d.toLocaleString("en-US", { month: "short" });
}

function getYear(date) {
  let d = new Date(date);
  return d.getFullYear();
}

function getDay(date) {
  let d = new Date(date);
  return d.getDate();
}

class EventCard extends React.Component {
  render() {
    const {
      dateObject,
      elevation,
      cardClassName,
      borderRadius,
      mainTopics,
      withCalendar,
      event,
      selectedTopics,
    } = this.props;
    const date = dateObject ? Object.keys(dateObject)[0] : null;
    const events = dateObject ? Object.values(dateObject)[0] : null;
    if (withCalendar)
      return (
        <Card
          elevation={elevation}
          className={cardClassName}
          component={Box}
          borderRadius={borderRadius}
          style={{
            minHeight: "150px",
            display:
              events.filter((x) => {
                return hasFilteredTopics(selectedTopics, x.interestAreas);
              }).length <= 0
                ? "none"
                : "block",
          }}
        >
          <div>
            <div className="event-card-calendar-row">
              <img
                src="/calendar.png"
                className="calendar-image"
                alt="Calendar"
              ></img>
              <p className="month">{getMonth(date)}</p>
              <p className="year">{getYear(date)}</p>
              <p
                className={
                  "day" + (getDay(date).toString().length <= 1 ? " day-1" : "")
                }
              >
                {getDay(date)}
              </p>
              <div className="event-card-title-div">
                <h4 className="event-card-title paragraph">
                  <i>
                    <span className="event-card-submissionType">
                      {events[0].submissionType.toUpperCase()}
                    </span>{" "}
                    SUBMISSION DEADLINES FOR THE FOLLOWING CONFERENCES / TOPIC
                    AREAS
                  </i>
                </h4>
                <span className="float-right event-card-helper-text">
                  *click on acronyms to access event websites
                </span>
              </div>
              <p className="event-card-helper-text-2">
                *click on acronyms to access event websites
              </p>
            </div>
            <div id="events" className="event-container">
              <div className="event-content">
                <Grid container spacing={2}>
                  {events
                    .filter((x) => {
                      return hasFilteredTopics(selectedTopics, x.interestAreas);
                    })
                    .map((event, index) => {
                      return (
                        <Grid item xs={12} key={"Event Card #" + index}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} lg={3}>
                              <Button
                                variant="outlined"
                                className="event-card-detail-button h-100"
                                href={config.eventsBasePath + "/" + event._id}
                              >
                                {event.title}
                              </Button>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                              <p
                                style={{
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                                className="paragraph"
                              >
                                {(() => {
                                  let matchingTopics = mainTopics.filter(
                                    (topic) => {
                                      return (
                                        topic.acronym.toUpperCase() ===
                                        event.title.toUpperCase()
                                      );
                                    }
                                  )[0];
                                  return matchingTopics
                                    ? matchingTopics["mainTopics"].join(", ")
                                    : "";
                                })()}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>
          </div>
        </Card>
      );
    else
      return (
        <Card elevation={0} className={cardClassName}>
          <CardActionArea
            href={
              config.eventsBasePath +
              "/" +
              event._id +
              (this.props.refID ? "?refID=" + this.props.refID : "")
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <img
                  className="w-100"
                  src={config.eventsImageBasePath + event.mainPhoto}
                  alt="Banner"
                ></img>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      );
  }
}

export default EventCard;
