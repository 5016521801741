import { Box, Card, CardActionArea, Grid } from "@material-ui/core";
import React from "react";
import config from "../../../config";
import Slider from "../../Slider";

class ResearcherCard extends React.Component {
  render() {
    const { withSlider, researcher } = this.props;
    const fullName =
      researcher.title +
      " " +
      researcher.firstName +
      " " +
      researcher.middleName +
      " " +
      researcher.lastName;
   
    return (
      <Card
        elevation={0}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <CardActionArea
          href={
            config.researchersBasePath +
            "/" +
            this.props.researcher._id +
            (this.props.refID ? "?refID=" + this.props.refID : "")
          }
        >
          <Box p={2}>
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item xs={this.props.imageXS}>
                <img
                  className="center"
                  style={{
                    borderRadius: this.props.borderRadius || "50px",
                    width: this.props.imageWidth,
                    height: this.props.imageHeight,
                    boxShadow: "10px 1px 10px rgba(0, 0, 0, 0.2)",
                  }}
                  src={
                    config.researchersImageBasePath +
                    this.props.researcher.mainPhoto + '?q=' +Math.random()
                  }
                  alt="Researchers"
                ></img>
              </Grid>
              <Grid item xs={12} sm={8}>
                {!withSlider ? (
                  <div>
                    <div
                      style={{ height: this.props.titleHeight }}
                      className={this.props.titleClassName}
                    >
                      <h4 className="researcher-card-title text-align-center">
                        <b>{fullName}</b>
                      </h4>
                      <h5 className="researcher-card-subtitle text-align-center">
                        {this.props.researcher.affiliation} /{" "}
                        {this.props.researcher.location}
                      </h5>
                    </div>
                  </div>
                ) : (
                  <Slider
                    items={[
                      <div className="mb-5 mt-5 researcher-details-first-card">
                        <p className="paragraph">
                          <span className="researcher-slider-title">
                            {fullName}
                          </span>
                          {"\n"}
                          <span className="researcher-slider-subtitle">
                            {this.props.researcher.affiliation} /{" "}
                            {this.props.researcher.location}
                          </span>
                        </p>
                      </div>,
                      <div
                        className="mb-5 mt-5 w-100"
                        style={{
                          height: "100px",
                          overflowY: "scroll",
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                        dangerouslySetInnerHTML={{
                          __html: researcher.highlightedText,
                        }}
                      ></div>,
                    ]}
                    className="details"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </CardActionArea>
      </Card>
    );
  }
}

export default ResearcherCard;
