import React from "react";
import { withStyles } from "@material-ui/styles";
import { Radio } from "@material-ui/core";

export const CustomRadio = withStyles({
  root: {
    color: "#9f9234",
    "&$checked": {
      color: "#9f9234",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
