import { Box, Grid } from "@material-ui/core";
import React from "react";
import EventCard from "./EventCard";
import Moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../config";
import MoreButton from "../Custom Components/MoreButton";
import queryString from "query-string";

class EventDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: {},
      randomEvents: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    const res = await axios.get("/api/events/" + this.props.match.params.id);
    const relatedRes = await axios.get(
      "/api/events/" + this.props.match.params.id + "/related" +
      "?n=3" +
      (params.refID ? "&refID=" + params.refID : "")
    );
    this.setState({
      event: res.data,
      relatedEvents: relatedRes.data,
      isLoading: false,
    });
    document.title = res.data.name;
  }

  render() {
    const { event, relatedEvents, isLoading } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div className="content">
          <Box className="main-box">
            <a href={event.website} target="_blank" rel="noopener noreferrer">
              <img
                src={config.eventsImageBasePath + event.mainPhoto}
                alt="Banner"
                className="w-100"
              />
            </a>
            <br></br>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <a
                  href={event.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="title gold">
                    {event.name}
                    {"   "}
                    <img
                      width="20"
                      height="20"
                      className="mb-1"
                      src="/hyperlink-icon.png"
                      alt="Event Website"
                    />
                  </h3>
                </a>
                <h6 className="yellow-text">{event.title}</h6>
                <h6 className="yellow-text">
                  {Moment(event.startDate).format("DD") +
                    "-" +
                    Moment(event.endDate).format("DD MMMM, YYYY")}
                </h6>
                <br></br>
                <div
                  className="justify-text"
                  dangerouslySetInnerHTML={{
                    __html: event.content,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {relatedEvents.length > 0 && (
                  <div>
                    <h4 className="yellow-text text-align-center">
                      <b>Related Events</b>
                    </h4>
                    <br></br>
                    <br></br>
                    <Grid container spacing={2}>
                      {relatedEvents.map((item, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <EventCard
                              event={item}
                              refID={this.state.event._id}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
                <Grid container justify="center">
                  <MoreButton
                    title="More Deadlines"
                    href="/SubmissionDeadlines"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      );
  }
}

export default EventDetails;
