import { Box, Grid } from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import MoreButton from "../Custom Components/MoreButton";
import config from "../../config";

class PartnerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: {},
      isLoading: true,
    };
  }

  async componentDidMount() {
    const res = await axios.get("/api/partners/" + this.props.match.params.id);
    if (res.data) {
      this.setState({
        partner: res.data,
        isLoading: false,
      });

      const resPartnerType = await axios.get(
        "/api/partners/Types/" + res.data.type
      );
      this.setState((prevState) => ({
        partner: {
          ...prevState.partner,
          type: resPartnerType.data.name,
        },
      }));
      document.title = res.data.name;
    }
  }

  render() {
    const { partner, isLoading } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div className="content">
          <Box className="main-box">
            <Grid container alignItems="center">
              <img
                src={config.partnersImageBasePath + partner.mainPhoto}
                alt="Banner"
                className="pr-2 partner-details-image"
                align="left"
              />
              <div className="paragraph">
                <a
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="title gold">
                    {partner.name}
                    {"   "}
                    <img
                      width="15"
                      height="15"
                      className="mb-3"
                      src="/hyperlink-icon.png"
                      alt="Event Website"
                    />
                  </h3>
                </a>
                <h5 className="partner-subtitle">{partner.type}</h5>
              </div>
            </Grid>
            <div
              className="pt-5 pb-5"
              dangerouslySetInnerHTML={{
                __html: partner.content,
              }}
            />
            <Grid container justify="center">
              <MoreButton
                title="More Partners"
                href={config.partnersBasePath}
              />
            </Grid>
          </Box>
        </div>
      );
  }
}

export default PartnerDetails;
