import { Box } from "@material-ui/core";
import React from "react";
import NavigationBar from "./NavigationBar";
import InnerAccordion from "./Custom Components/InnerAccordion";

class FAQs extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="content">
          <img src="https://insticc.org/images/portal/Banners/Faqs/original.png" alt="Banner" className="w-100"></img>
          <Box className="main-box">
            <h3 className="yellow-text">
              <b>FAQs</b>
            </h3>
            <InnerAccordion
              defaultActiveKey="1"
              isOpen
              eventKey="1"
              title="Q1. What are the advantages of being a member of INSTICC?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}
                  An INSTICC member has access to parts of the portal that
                  others cannot as well as a privileged access to the Digital
                  Library of SCITEPRESS (Science and Technology Publications).
                  Furthermore, INSTICC members benefit of special discounts at
                  INSTICC conferences. More information at{" "}
                  <a
                    href="http://www.insticc.org/portal/Community/Membership.aspx"
                    className="yellow-text dont-break-out"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.insticc.org/portal/Community/Membership.aspx
                  </a>
                </div>
              }
            />
            <InnerAccordion
              eventKey="2"
              title="Q2. Why are INSTICC events better?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}INSTICC has a long-standing commitment to ensuring the
                  high quality of its conferences and of the conference
                  proceedings published in SCITEPRESS proceedings and included
                  in its Digital Library. INSTICC has a dedicated team to
                  support all phases of the conference organization, including
                  submission, review, selection, registration, publication,
                  presentation and indexation, besides making the best efforts
                  to provide a high quality experience to all delegates,
                  including keynote lectures delivered by worldwide renowned
                  researchers, panels, special sessions and workshops focused on
                  specialized themes. Scientific quality is ensured by a program
                  committee that is composed by highly qualified researchers in
                  the conference topic areas. In addition to an inspiring
                  scientific program, the timely and courteous interaction of
                  the secretariat with conference delegates makes the experience
                  of attending an INSTICC conference a rewarding event.
                </div>
              }
            />
            <InnerAccordion
              eventKey="3"
              title="Q3. Can I ask INSTICC to help me organize a
              workshop, symposium or other scientific event?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}INSTICC welcomes satellite events that can take
                  advantage of INSTICC venues and of INSTICC logistics in order
                  to facilitate the organization of workshops, special sessions,
                  symposia, etc. INSTICC has an agreement with SCITEVENTS
                  (Science and Technology Events) for taking care of the
                  logistics and also an agreement with SCITEPRESS (Science and
                  Technology Publications) in order to include all papers
                  presented at an INSTICC conference at the SCITEPRESS Digital
                  Library.
                </div>
              }
            />
            <InnerAccordion
              eventKey="4"
              title="Q4. If I publish at an INSTICC conference, will my
              paper get into a Digital Library?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}INSTICC has an agreement with SCITEPRESS (Science and
                  Technology Publications) in order to include all papers
                  presented at an INSTICC conference at the SCITEPRESS Digital
                  Library, which can be found at{" "}
                  <a
                    href="http://www.scitepress.org/DigitalLibrary/"
                    className="yellow-text dont-break-out"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.scitepress.org/DigitalLibrary/
                  </a>
                </div>
              }
            />
            <InnerAccordion
              eventKey="5"
              title="Q5. Can I apply to an INSTICC grant, if I have a
              paper accepted at an INSTICC conference?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}If you belong to an economically challenged region of
                  the World or if you have financial difficulties that hinder
                  your presence at an INSTICC conference you may contact the
                  conference secretariat to ask for a grant that might partially
                  contribute to alleviate or waive altogether your registration
                  fees.
                </div>
              }
            />
            <InnerAccordion
              eventKey="6"
              title="Q6. What are the advantages of institutional
              academic partners?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}If your research group, department, library or school
                  wishes to provide further visibility to research work done by
                  its collaborators, or if there is a local interest in
                  accessing the resources made available to INSTICC members, the
                  institution can become an INSTICC institutional member and
                  propagate the advantages of being an INSTICC member to all its
                  collaborators (students and faculty). INSTICC personnel will
                  be happy to explain the details.
                </div>
              }
            />
            <InnerAccordion
              eventKey="7"
              title="Q7. How can my research group, department, library
              of school become an academic partner?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}INSTICC welcomes institutional partners, either
                  academic or of other natures. Many of them are listed at{" "}
                  <a
                    href="http://www.insticc.org/portal/partnerships.aspx"
                    className="yellow-text dont-break-out"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.insticc.org/portal/partnerships.aspx
                  </a>
                  . A representative may contact our membership department by
                  email (membership@insticc.org) or by phone – see contacts at
                  the portal.
                </div>
              }
            />
            <InnerAccordion
              eventKey="8"
              title="Q8. Can I send news about my research or my
              group/institution to be advertised at INSTICC
              portal?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"} Yes, INSTICC is always keen to disseminate research
                  results, ideas, projects and news involving the international
                  research community, especially INSTICC members. You will need
                  to have an account. You can go to{" "}
                  <a
                    href="http://www.insticc.org/portal/news"
                    className="yellow-text dont-break-out"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.insticc.org/portal/news
                  </a>{" "}
                  to login or to create an account. Then you will find a form
                  that facilitates the insertion of news, including text, images
                  and even videos, if you want.
                </div>
              }
            />
            <InnerAccordion
              eventKey="9"
              title="Q9. How can I become a member?"
              content={
                <div>
                  <b>Answer:</b>
                  {"\n\n"}You can contact the membership department by email
                  (membership@insticc.org) or you can join online by going to
                  the following webpage, log in using your existing account or
                  create a new one:{" "}
                  <a
                    className="yellow-text dont-break-out"
                    href="http://www.insticc.org/myINSTICC/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.insticc.org/myINSTICC/
                  </a>
                </div>
              }
            />
          </Box>
        </div>
      </div>
    );
  }
}

export default FAQs;
