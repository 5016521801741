import React from "react";
import {
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Slide
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RoundTextField from "./RoundTextField";
import DirectionsIcon from "@material-ui/icons/Directions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MainSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      open: false,
      text: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleClose = () => {
    this.setState({ open: false, isLoading: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.text.length > 2)
      window.location.href = "/Results?text=" + this.state.text;
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13)
      if (this.state.text.length > 2)
        window.location.href = "/Results?text=" + this.state.text;
  };

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ text: value });
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Button
          disableElevation
          variant="contained"
          className="gray-search-button"
          onClick={this.handleClickOpen}
          startIcon={<SearchIcon className="dimgray-icon" />}
        />
        <Dialog
          disableAutoFocus
          className="popup-dialog"
          open={open}
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          onClose={this.handleClose}
          PaperProps={{
            style: { borderRadius: 20 },
          }}
        >
          <form>
            <RoundTextField
              placeholder="Search"
              placeholderColor="#A9ABAD"
              placeholderFontSize="22px"
              height="60px"
              onKeyDown={this.onKeyDown}
              onChange={this.onChange}
              error={this.state.text.length <= 2}
              helperText={
                this.state.text.length <= 2
                  ? "At least 3 characters are required"
                  : ""
              }
              borderRadius="20px"
              baseBorderColor="#ACA563"
              hoverBorderColor="#ACA563"
              focusedBorderColor="#ACA563"
              name="text"
              autoFocus
              startAdornment={
                <InputAdornment
                  position="start"
                  style={{ margin: "0 30px", color: "#A9ABAD" }}
                >
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              }
              endAdornment={
                <IconButton type="submit" onClick={this.onSubmit}>
                  <DirectionsIcon />
                </IconButton>
              }
            />
            <div className="p-3">
              <ul>
                <li>
                  Word sequences are implicitly connected by ‘or’: results
                  include all pages where any of the words appears.
                  <li>
                    If you wish to find an exact word sequence, you must write
                    the sequence within “”
                  </li>
                  <li>
                    To get pages where two words exist, not necessarily in
                    sequence, please connect the words with ‘and’.
                  </li>
                  <li>To exclude a word, you can prepend a ‘-‘ character.</li>
                  <li>For example: </li>
                  <ul>
                    <li>
                      to find all pages containing “tea” and “shop” but not
                      “coffee”, use the following search string: tea and shop
                      -coffee
                    </li>
                    <li>
                      to find all pages containing either “tea” or “shop”, use
                      the following search string: teashop
                    </li>
                    <li>
                      to find all stores containing the exact phrase “tea shop”,
                      use the following search string: “tea shop”
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default MainSearch;
