import { Box, Chip, Grid, MenuItem, Paper, TextField } from "@material-ui/core";
import React from "react";
import config, { fixedRoleOptions, titleOptions } from "../../../config";
import {
  nameTester,
  passwordTester,
  passwordTesterHelperText,
} from "../../../js/auxFunctions";
import { CountryRegionData } from "react-country-region-selector";
import { Autocomplete } from "@material-ui/lab";
import StandardButton from "../../Custom Components/StandardButton";
import { post, postLog, sendEmail } from "../../../js/httpRequests";
import axios from "axios";

export default class UserCreation extends React.Component {
  state = {
    user: {
      idTitle: 2,
      title: "Mr.",
      country: CountryRegionData[0],
      roles: ["User"],
    },
    loggedUser: { roles: [] },
    isAdmin: false,
  };

  async componentDidMount() {
    const user = await (await axios.get("/api/login/user")).data;
    const isAdmin = await (await axios.get("/api/login/isAdmiN")).data;

    this.setState({ loggedUser: user, isAdmin: isAdmin });
  }

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  validate = () => {
    const { user } = this.state;
    let temp = {};
    temp.firstName = !nameTester(user.firstName);
    temp.lastName = !nameTester(user.lastName);
    temp.email = config.emailRegex.test(user.email);
    temp.password = !passwordTester(user.password);
    temp.confirmPassword = user.password === user.confirmPassword;

    return Object.values(temp).every((x) => x === true);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { user, loggedUser } = this.state;

    if (this.validate()) {
      let obj = Object.assign({}, this.state.user);
      if (obj.country) obj.country = obj.country[0];
      obj.idTitle = titleOptions.filter((x) => x.label === obj.title)[0].value;

      post(
        obj,
        "users",
        async () => {
          await sendEmail("Confirm Account", user);
          postLog(
            "Create User",
            "User has been created via backoffice",
            loggedUser.firstName + " " + loggedUser.lastName,
            loggedUser.email
          );
          window.location.href = "/backoffice/entities-management/user";
        },
        "Account created!"
      );
    }
  };

  render() {
    const {
      title,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      country,
      alternativeEmail,
      roles,
    } = this.state.user;
    return (
      <div className="content">
        <Box p={5}>
          <h4 className="yellow-text">Create User</h4>
          <br></br>
          <Paper elevation={3} className="p-5">
            <form onSubmit={this.onSubmit}>
              <h5>
                <b>Basic Information</b>
              </h5>
              <br></br>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <label>Title:</label>
                  <TextField
                    select
                    value={title}
                    name="title"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  >
                    {titleOptions.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <label>First Name:</label>
                  <TextField
                    error={!firstName}
                    helperText={!firstName ? "First name is required" : ""}
                    value={firstName}
                    name="firstName"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <label>Last Name:</label>
                  <TextField
                    error={!lastName}
                    helperText={!lastName ? "Last name is required" : ""}
                    value={lastName}
                    name="lastName"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Email:</label>
                  <TextField
                    error={!config.emailRegex.test(email)}
                    helperText={
                      !config.emailRegex.test(email)
                        ? "Invalid email format"
                        : ""
                    }
                    value={email}
                    name="email"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Password:</label>
                  <TextField
                    type="password"
                    error={passwordTester(password)}
                    helperText={passwordTesterHelperText(password)}
                    value={password}
                    name="password"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Confirm Password:</label>
                  <TextField
                    type="password"
                    error={password !== confirmPassword && password}
                    helperText={
                      password !== confirmPassword && password
                        ? "Passwords dont match"
                        : ""
                    }
                    value={confirmPassword}
                    name="confirmPassword"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label>Country:</label>
                  <TextField
                    variant="outlined"
                    name="country"
                    select
                    fullWidth
                    value={country}
                    onChange={this.onTextFieldChange}
                  >
                    {CountryRegionData.map((option) => (
                      <MenuItem key={option[0]} value={option}>
                        {option[0]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <label>Alternative Email:</label>
                  <TextField
                    error={
                      alternativeEmail &&
                      !config.emailRegex.test(alternativeEmail)
                    }
                    helperText={
                      alternativeEmail &&
                      !config.emailRegex.test(alternativeEmail)
                        ? "Invalid email format"
                        : ""
                    }
                    value={alternativeEmail}
                    name="alternativeEmail"
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                {this.state.isAdmin ? (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <label>Roles:</label>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          multiple
                          getOptionSelected={(option, value) => {
                            return option === value;
                          }}
                          value={roles}
                          getOptionLabel={(option) => option}
                          options={["User", "Admin", "Manager"]}
                          onChange={(e, newValue) =>
                            this.setState((prevState) => ({
                              user: {
                                ...prevState.user,
                                roles: [
                                  ...fixedRoleOptions,
                                  ...newValue.filter(
                                    (option) =>
                                      fixedRoleOptions.indexOf(option) === -1
                                  ),
                                ],
                              },
                            }))
                          }
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={option}
                                {...getTagProps({ index })}
                                disabled={
                                  fixedRoleOptions.indexOf(option) !== -1
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container justify="center" className="mt-5">
                <StandardButton type="submit" variant="outlined">
                  Submit
                </StandardButton>
              </Grid>
            </form>
          </Paper>
        </Box>
      </div>
    );
  }
}
