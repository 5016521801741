import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
  textField: {
    borderRadius: ({ borderRadius }) => borderRadius || `50px`,
    height: ({ height }) => height || "40px",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: ({ baseBorderColor }) => baseBorderColor || "#9f9234",
      },
      "&:hover fieldset": {
        borderColor: ({ hoverBorderColor, error }) => {
          if (error) return "red";
          else return hoverBorderColor || "#756c27";
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: ({ focusedBorderColor, error }) => {
          if (error) return "red";
          else return focusedBorderColor || "#d1c045";
        },
      },
    },
  },
  input: {
    "&::placeholder": {
      color: ({ placeholderColor }) => placeholderColor,
      fontSize: ({ placeholderFontSize }) => placeholderFontSize,
    },
  },
});

const RoundTextField = ({
  name,
  defaultValue,
  disabled,
  value,
  type,
  multiline,
  rows,
  onChange,
  height,
  select,
  required,
  placeholder,
  placeholderFontSize,
  placeholderColor,
  borderRadius,
  startAdornment,
  endAdornment,
  backgroundColor,
  baseBorderColor,
  hoverBorderColor,
  focusedBorderColor,
  children,
  error,
  helperText,
  params,
  label,
  inputProps,
  onKeyDown,
  ref,
  autoFocus
}) => {
  const classes = useStyles({
    height,
    borderRadius,
    placeholderFontSize,
    placeholderColor,
    baseBorderColor,
    hoverBorderColor,
    focusedBorderColor,
    error,
  });
  return (
    <TextField
      {...params}
      ref={ref}
      label={label}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      value={value}
      select={select}
      fullWidth
      autoFocus={autoFocus}
      type={type}
      onKeyDown={onKeyDown}
      required={required}
      multiline={multiline}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      style={{
        backgroundColor: backgroundColor || "white",
        borderRadius: borderRadius || "50px",
        width: "100%",
        height: height || "40px",
      }}
      className={classes.root}
      InputProps={{
        startAdornment: startAdornment,
        endAdornment: endAdornment,
        className: classes.textField,
        classes: { input: classes["input"] },
        inputProps,
      }}
      rows={rows}
      onChange={onChange}
      variant="outlined"
    >
      {children}
    </TextField>
  );
};

export default RoundTextField;
