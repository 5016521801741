export const departments = [{
    title: "Events",
    email: "events@insticc.org",
    telephone: "+351 265 520 184",
    fax: "+351 265 520 186",
    image: "/eventsDepartmentPhoto.png"
}, {
    title: "Communications",
    email: "secretariat@insticc.org",
    telephone: "+351 265 520 185",
    fax: "+351 265 520 186",
    image: "communicationsDepartmentPhoto.png"
}, {
    title: "Membership",
    email: "membership@insticc.org",
    telephone: "+351 265 520 184",
    fax: "+351 265 520 186",
    image: "membershipDepartmentPhoto.png"
}, {
    title: "Institutional Relations",
    email: "partnership@insticc.org",
    telephone: "+351 265 520 185",
    fax: "+351 265 520 186",
    image: "institutionalRelationsDepartmentPhoto.png"
},
]

export default { departments };