import { Box } from "@material-ui/core";
import React from "react";
import config from "../../config";
import MultiSlider from "../MultiSlider";

const items = (() => {
  let aux = [];
  for (let i = 0; i < config.attendAnEventItems; i++) {
    aux.push("AttendAnEvent" + (i + 1) + ".jpg");
  }

  return aux;
})();

class AttendAnEvent extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/AttendAnEvent/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Attend an Event</h3>
          <br></br>
          <p className="body-text paragraph">
            INSTICC events are usually international conferences, including in
            their technical program not only regular paper presentations but
            also satellite events such as special sessions or workshops,
            doctoral consortia, tutorials, keynote lectures, industrial
            symposia, and technical exhibitions.{"\n\n"}
            Attending an event is a rich experience for researchers and
            practitioners. Besides being a vehicle to publish research work and
            promote ideas, it provides learning opportunities, for some, and
            opportunities for developing collaborative projects, for others.
            {"\n\n"}
            Both speakers and non-speakers are welcome at INSTICC events.
            Speakers need to go through a submission and peer-review process,
            that strictly selects only the best papers for presentation at the
            conference.{"\n\n"}
            Providing opportunities for discussing research approaches and for
            promoting collaboration with peers is one of the top priorities at
            INSTICC conferences, not only aiming at creating a technical program
            with interesting and thematically homogeneous presentation sessions
            but also bycreating informal social networking moments.
            {"\n\n"}
            Companies are also welcome to share their applied knowledge with the
            international research community, promoting their image, methods and
            products and amplifying their coverage in a specialized, dynamic and
            vibrant community.
          </p>
        </Box>
        <MultiSlider
          items={items}
          autoPlay
          autoPlaySpeed={2000}
          arrows={false}
          imageBaseRef={config.attendAnEventImageBasePath}
          boxClassName="yellow-bg"
          borderColor="transparent"
        />
      </div>
    );
  }
}

export default AttendAnEvent;
