import React, { Component } from "react";
import { Box } from "@material-ui/core";

class PlagiarismPolicy extends Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PlagiarismPolicy/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Plagiarism Policy</h3>
          <br></br>
          <p className="paragraph body-text">
            INSTICC has a very strict anti-plagiarism policy which embraces both
            plagiarism and self-plagiarism, due to the notion that academic
            research work is supposed to be original and thus should be
            published only once, at least in archival publications of large
            dissemination scope such as INSTICC publications.{"\n"}
            INSTICC is committed to meeting and upholding standards of ethical
            behaviour at all stages of the publication process. We follow
            closely the recommendations of industry associations, such as the
            Committee on Publication Ethics (COPE), that set standards and
            provide guidelines for best practices in order to meet these
            requirements.{"\n\n"}
            <b className="bold">Plagiarism</b>, as defined in the 1995 Random
            House Compact Unabridged Dictionary, is the "use or close imitation
            of the language and thoughts of another author and the
            representation of them as one's own original work" as quoted in
            (Stepchyshyn and Nelson, 2007). Plagiarism is not copyright
            infringement. While both terms may apply to a particular act, they
            are different transgressions. Copyright infringement is a violation
            of the rights of a copyright holder, when material protected by
            copyright is used without consent. Plagiarism on the other hand
            involves false claims of authorship and is concerned with the
            unearned increment to the plagiarizing author's reputation achieved
            through these false claims.{"\n"}
            {"\n"}
            <b className="bold">Duplicate publication or self-plagiarism</b> is
            a mild form of plagiarism that refers to publishing the same
            intellectual material more than once by its author or publisher,
            i.e. the reuse of significant, identical, or nearly identical
            portions of one’s own work without acknowledging that one is doing
            so or without citing the original work. This infringement can be
            either of legal nature, in the case in which copyright of the prior
            work has been transferred to another entity, or merely ethical.
            Typically, self-plagiarism is only considered to be a serious
            ethical issue in settings in which a publication is asserted to
            consist of new material, such as in academic publishing. It does not
            apply (except in the legal sense) to public-interest texts, such as
            social, professional, and cultural opinions usually published in
            newspapers, magazines or even on the web.{"\n"}
            {"\n"}INSTICC explicitly asks the authors to avoid this. It is
            indicated in their submission guidelines that "Authors should submit
            an original paper in English,..." and further specify that "Papers
            that are out of the conference scope or contain any form of
            plagiarism will be rejected...". Two exceptions are tolerated:
            {"\n"}
            {"\n"}
            <ol>
              <li>
                INSTICC allows limited reuse of the authors’ own texts as long
                as the parts of the text that are reused are a small percentage
                of the whole text and as long as these are clearly identified by
                referring to the original work and by emphasizing the reused
                text in italics or using a different font than the one used for
                the regular text.
              </li>
              <br></br>
              <li>
                INSTICC also allows reuse of the authors’ own material as long
                as the material was published earlier solely as a technical
                report in a non-archival publication (i.e. not widely available,
                without ISBN) or in another language than English.
              </li>
            </ol>
            <b className="bold">REFERENCES</b>
            {"\n"}Stepchyshyn, Vera; Robert S. Nelson (2007). Library plagiarism
            policies. Assoc of College & Research Libraries. p. 65. ISBN
            0838984169.{"\n"}
            COPE (Committee on Publication Ethics) 2020, Guidelines, viewed 16
            November 2020, {"<"}
            <a
              className="yellow-link dont-break-out"
              target="_blank"
              rel="noopener noreferrer"
              href="https://publicationethics.org/guidance/Guidelines"
            >
              https://publicationethics.org/guidance/Guidelines
            </a>
            {">"}.
          </p>
        </Box>
      </div>
    );
  }
}

export default PlagiarismPolicy;
