import { Grid } from "@material-ui/core";
import React from "react";

export default class PriorityPartners extends React.Component {
  render() {
    return (
      <Grid container alignItems="center" className="w-100 p-3">
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.scitepress.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="scitepress-logo.png" alt="Scitepress" />
            </a>
          </Grid>
        </Grid>
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="http://www.scitevents.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="scitevents-logo.png" alt="Scitevents" />
            </a>
          </Grid>
        </Grid>
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.insticc.org/Primoris/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="primoris-logo.png" alt="Primoris" />
            </a>
          </Grid>
        </Grid>
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.acm.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="acm-logo.png" alt="ACM" />
            </a>
          </Grid>
        </Grid>
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.ieee.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="ieee-logo.png" alt="IEEE" />
            </a>
          </Grid>
        </Grid>
        {/* <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.ifac-control.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="ifac-logo.png" alt="IFAC" />
            </a>
          </Grid>
        </Grid> */}
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://www.aaai.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="aaai-logo.png" alt="AAAI" />
            </a>
          </Grid>
        </Grid>
        <Grid item xs className="p-3">
          <Grid container justify="center">
            <a
              href="https://iapr.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="iapr-logo.png" alt="IAPR" />
            </a>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
