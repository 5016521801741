import React from "react";
import { CountryDropdown } from "react-country-region-selector";

class CustomCountrySelect extends React.Component {
  render() {
    return (
      <CountryDropdown
        style={{
          borderRadius: "50px",
          padding: "5px 20px",
          backgroundColor: "rgba(158,146,50,0.2)",
          borderColor: "rgba(158,146,50,0.2)",
          height: "40px"
        }}
        className="round-select-arrow"
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export default CustomCountrySelect;
