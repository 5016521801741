import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useAccordionToggle } from "react-bootstrap";
import clsx from "clsx";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";

const useStyles = makeStyles((theme) => ({
  open: {
    transform: "scaleY(1)",
  },
  close: {
    transform: "scaleY(-1)",
  },
}));

export default function InnerAccordionToggleButton({
  children,
  eventKey,
  isOpen,
  onClickEvent,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => null);
  const classes = useStyles();
  const drawerToggle = () => {
    setOpen(!open);
  };
  const [open, setOpen] = React.useState(!isOpen);

  return (
    <IconButton
      aria-label="delete"
      onClick={() => {
        decoratedOnClick();
        drawerToggle();
        if (onClickEvent) onClickEvent();
      }}
    >
      <ArrowDropDownCircleOutlinedIcon
        className={clsx(!open && classes.close, open && classes.open)}
        style={{ fontSize: "40px", color: "#9F9232" }}
      />
      {children}
    </IconButton>
  );
}
