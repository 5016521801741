import React, { Component } from "react";
import { Box } from "@material-ui/core";
import MultiSlider from "../MultiSlider";

const items = [
  "Scopus-logo.png",
  "Google-scholar-logo.png",
  "Dblp-logo.png",
  "Semantic-scholar-logo.png",
  "Microsoft-academic-logo.png",
  "E-logo.png",
  "Clarivate-logo.png",
];

class Indexation extends Component {
  render() {
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/Indexation/original.png" alt="Banner" className="w-100"></img>
        <Box className="main-box">
          <h3 className="title gold">Indexation</h3>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is well aware of the importance of indexation in improving
            the chances of papers being found, read and quoted. Generally,
            INSTICC publications are submitted to and indexed at SCOPUS, EI
            (Elsevier Index), DBLP, Microsoft Academic, Google Scholar, Semantic
            Scholar and Conference Proceedings Citation Index (ISI), for
            indexation, with area-specific indexes being added whenever
            relevant.
          </p>
          <br></br>
          <br></br>
          <MultiSlider
            items={items}
            borderColor="transparent"
            imageBaseRef="/Indexation/"
            autoPlay
            arrows={false}
          />
        </Box>
      </div>
    );
  }
}

export default Indexation;
