import React, { Component } from "react";
import Accordion from "../Custom Components/Accordion";

class PaperPresentation extends Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PaperPresentation/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Accordion
          defaultActiveKey="0"
          eventKey="0"
          headerTitle="Preparing your Presentation"
          headerBody={
            <div>
              To guarantee that you make the best out of the time alloted for
              your presentation, it is important to prepare and to make sure
              that you have found the best way of getting your ideas accross.
              Preparing things well ahead of time is critical to a successful
              presentation, as is practicing it in advance, ideally in front of
              someone who works in the field but is not familiar with your
              research or paper.
              {"\n"}We have put together a couple of suggestions to help you
              prepare your presentation for a scientific event, be it an INSTICC
              conference or congress or any other event similar.
              {"\n"}
              {"\n"}
              <span className="subtitle">Oral Presentations</span>
              {"\n"}
              When preparing an oral presentation, there are three main concerns
              you should have: the Time Frame, the Structure and the
              Technicalities.{"\n"}
              {"\n"}
            </div>
          }
          body={
            <p className="paragraph full-width-accordion-padding body-text">
              <span className="body-text-title">Timing: </span>
              it is very important that you remain within the alloted time.
              Firstly, because not doing so will prejudice your colleagues and
              secondly because a short presentation will work in your favor,
              forcing you to clarify key points and allowing more time for
              discussion afterwards. Currently, the time limit for presentations
              at INSTICC events is 25 min for full papers and 20 min for short
              papers (including discussion) - test your presentation beforehand,
              beware of not using too many slides and consider shortening it to
              two thirds of the time limit, to make time for unexpected
              difficulties, script deviations and questions from the audience.
              {"\n"}
              {"\n"}
              <span className="body-text-title">Structure: </span>
              structuring your presentation is essential to get your message
              across and also helps time keeping. Introduce the topic very
              briefly in just one slide, assuming the audience is familiar with
              your discipline but not your area of expertise. Then, concentrate
              on two or three points - attendees will have access to your paper
              if they want to find out more and go into details regarding your
              research or the calculation of your results, taking them with you
              as you present is more important than being exhaustive. Finally,
              be clear on the results and future research plans.
              {"\n"}
              {"\n"}
              <span className="body-text-title">Technical settings: </span>
              you can either use the equipment present in the session rooms by
              using a USB drive or bring your own computer. The session
              rooms are equipped with a computer running Microsoft Windows with
              Microsoft Powerpoint and Adobe Acrobat Reader. Loudspeakers are
              also available on request, so let us know beforehand if you plan
              to show a video or some sound files.
            </p>
          }
        />
        <Accordion
          defaultActiveKey="1"
          eventKey="1"
          headerTitle="Poster Sessions"
          headerBody={
            <p className="paragraph body-text">
              {"\n"}
              At INSTICC events, posters have dedicated sessions during the
              conference, normally simultaneously with coffee breaks and the
              exhibition if there is one, to guarantee the largest possible
              audience for poster presenters. Here are a few helpful suggestions
              if you are preparing a poster presentation:{"\n"}
              {"\n"}
            </p>
          }
          body={
            <p className="paragraph full-width-accordion-padding body-text">
              <span className="body-text-title">Size limits: </span>
              The poster size must not exceed the A0 (84 cm X 118 cm) portrait
              format and should be brought along with you to the conference.
              Please use an appropriate font size for the posters so that they
              are readable by the participants by approximately 1.5 meter away.
              {"\n"}
              {"\n"}
              <span className="body-text-title">Extras: </span>
              As poster presentations last longer and take place in a larger
              area, poster authors have increased freedom to show their work
              beyond the poster and many authors choose this presentation format
              due to this flexibility. Contact us if you would like to bring
              something along your poster - from robot demonstrations to videos
              and sound effects, we always encourage creativity and will be glad
              to make it happen if it is feasible.{"\n"}
              {"\n"}
              <span className="body-text-title">Timing: </span>
              Please assure the poster is placed on the board before the
              beginning of the poster session. Poster authors must stand next to
              their posters and be available to answer questions during the
              entire session, during which a certificate of attendance will also
              be awarded.
            </p>
          }
        />
      </div>
    );
  }
}

export default PaperPresentation;
