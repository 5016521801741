import { Box } from "@material-ui/core";
import React from "react";
import { departments } from "../AboutUs/departmentsArray";
import HtmlTooltip from "../Custom Components/HtmlTooltip";

class Primoris extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/InformationSystem/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h4 className="title gold">
            The first of the next generation of Event Management Systems
          </h4>
          <br></br>
          <p className="paragraph body-text">
            PRIMORIS is one of the most modern, user-friendly, and complete
            Event Management Systems available, supporting the full process
            facilitating the management of the information flow of any
            scientific event, in any area of research.{"\n\n"} It supports all
            front-office and back-office operations involved in managing an
            event, including: event planning, website generation, paper
            submission, reviewers invitation and registration, review process,
            plagiarism analysis, notification/acceptance letters, delegate
            registration, payment management, technical program setup, book of
            abstracts and proceedings publication, digital library (with DOI),
            welcome desk planning, delegate management at the venue,
            presentation certificates, invoicing and many other features.
            {"\n\n"}
            Although there is a core that is required in any configuration,
            PRIMORIS has a collection of optional modules, for front-office and
            for back-office, that enable different configurations and
            customization alternatives.{"\n\n"} If you are going to organize
            your own event and wish to use PRIMORIS please{" "}
            <HtmlTooltip
              department={departments[0]}
              text="contact our Events Deparment"
            />
            .
          </p>
        </Box>
      </div>
    );
  }
}

export default Primoris;
