import { Box, Grid } from "@material-ui/core";
import { Card, Accordion as RBAccordion } from "react-bootstrap";
import React from "react";
import AccordionToggleButton from "./AccordionToggleButton";

class Accordion extends React.Component {
  render() {
    const {
      defaultActiveKey,
      eventKey,
      headerTitle,
      headerBody,
      body,
      bodyClassName,
    } = this.props;
    return (
      <RBAccordion defaultActiveKey={defaultActiveKey}>
        <Box border={1} borderColor="#9F9232">
          <Card className="full-width-accordion-padding">
            <Card.Header style={{ backgroundColor: "white" }}>
              <div className="accordion-header-card">
                {headerTitle && (
                  <div>
                    <h4 className="title gold">{headerTitle}</h4>
                    <br></br>
                  </div>
                )}
                <p className="body-text paragraph">{headerBody}</p>
              </div>
            </Card.Header>
          </Card>
        </Box>
        <div>
          <Grid container justify="center" className="middle-accordion-grid">
            <AccordionToggleButton eventKey={eventKey} />
          </Grid>
        </div>
        <RBAccordion.Collapse eventKey={eventKey}>
          <div className={bodyClassName || "yellow-bg"}>
            <div className="accordion-content-card">{body}</div>
          </div>
        </RBAccordion.Collapse>
      </RBAccordion>
    );
  }
}

export default Accordion;
