import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDh8L3LI1Lc9TipwbxrwKtGPvDcoaAGaKE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: "100px", width: "100%" }} />,
    containerElement: (
      <div
        style={{
          height: "400px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      />
    ),
    mapElement: (
      <div className="w-100 h-100" />
    ),
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: props.locations[0].lat, lng: props.locations[0].lng }}
  >
    <div>
      {props.locations.map((x, i) => {
        return (
          <Marker
            position={{ lat: x.lat, lng: x.lng }}
            onClick={props.setSelectedCenter(x)}
            key={"Marker #" + i}
          >
            {props.checkIfShow(x.id) && (
              <InfoWindow
                onCloseClick={() => {
                  props.resetSelectedCenter(null);
                }}
                position={{
                  lat: x.lat,
                  lng: x.lng,
                }}
              >
                <div>{x.title}</div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </div>
  </GoogleMap>
));

const locations = [
  {
    id: 1,
    lat: 38.524098,
    lng: -8.905325,
    title: "Office"
  },
  {
    id: 2,
    lat: 38.7039691,
    lng: -9.1850958,
    title: "Headquarters"
  },
];

class MyFancyComponent extends React.Component {
  state = {
    selectedCenter: null,
  };

  resetSelectedCenter = () => {
    this.setState({ selectedCenter: null });
  };

  setSelectedCenter = (obj) => () => {
    this.setState({ selectedCenter: obj });
  };

  checkIfShow = (id) => {
    if (this.state.selectedCenter === null) return false;

    return this.state.selectedCenter.id === id;
  };

  render() {
    return (<div></div>
      // <MyMapComponent
      //   locations={locations}
      //   selectedCenter={this.state.selectedCenter}
      //   setSelectedCenter={this.setSelectedCenter}
      //   resetSelectedCenter={this.resetSelectedCenter}
      //   checkIfShow={this.checkIfShow}
      // />
    );
  }
}

export default MyFancyComponent;

/*
import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker } from "react-google-maps";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 38.524098,
      lng: -8.905325,
    },
    zoom: 11,
  };

  render() {
    return (
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDh8L3LI1Lc9TipwbxrwKtGPvDcoaAGaKE" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={38.524098} lng={-8.905325} text="INSTICC" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;

*/
