import React from "react";
import { withStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  inputRoot: {
    backgroundColor: "#ece9d6",
    borderRadius: "40px",
    height: "40px",
    "&&& $input": {
      padding: "2px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9f9234",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#756c27",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d1c045",
    },
  },
});

class CustomAutoComplete extends React.Component {
  render() {
    const {
      classes,
      open,
      onOpen,
      onClose,
      getOptionSelected,
      getOptionLabel,
      options,
      loading,
      onChange,
      value,
      tfPlaceholder,
      onAutoCompleteChange,
      error,
      helperText
    } = this.props;
    return (
      <Autocomplete
        fullWidth
        classes={classes}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={tfPlaceholder}
            style={{
              backgroundColor: "#ece9d6",
              borderRadius: "50px",
              width: "100%",
              height: "40px",
            }}
            variant="outlined"
            onChange={onAutoCompleteChange}
            onClick={onAutoCompleteChange}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
}

export default withStyles(styles)(CustomAutoComplete);
