import { Box, FormControlLabel, Grid, Tooltip } from "@material-ui/core";
import React from "react";
import Moment from "moment";
import RoundTextField from "../Custom Components/RoundTextField";
import CustomSelect from "../Custom Components/CustomSelect";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import { CountryRegionData } from "react-country-region-selector";
import MenuItem from "@material-ui/core/MenuItem";
import CustomAutoComplete from "../Custom Components/CustomAutoComplete";
import config, { titleOptions } from "../../config";
import { postLog, put2 } from "../../js/httpRequests";
import {
  nameTester,
  nameTesterHelperText,
  resizeImageFile,
} from "../../js/auxFunctions";
import StandardButton from "../Custom Components/StandardButton";
import { CustomRadio } from "../Custom Components/StandardRadio";
import RoundTextareaAutosize from "../Custom Components/RoundTextareaAutosize";
import HelpIcon from "@material-ui/icons/Help";
import Tabs from "../Custom Components/Tabs";
import menuItems from "./MenuItems.json";
import CircularProgress from "@material-ui/core/CircularProgress";

//const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const currentAcademicDegreeOptions = [
  { value: 0, label: "Select an academic degree" },
  { value: 1, label: "PhD" },
  { value: 4, label: "MSc" },
  { value: 6, label: "Eng" },
  { value: 7, label: "Dr" },
  { value: 8, label: "Prof" },
  { value: 9, label: "BSc" },
  { value: 10, label: "Other" },
  { value: 11, label: "None" },
];

function getGraduationOptions() {
  let arr = [];
  for (let i = new Date().getFullYear(); i >= 1959; i--)
    arr.push({ value: i, label: i });

  return arr;
}

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        photo: "",
        organization: "",
        title: "Mr.",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "Male",
        academicDegreeStudent: "",
        academicDegree: "",
        yearGraduated: "",
        supervisor: "",
        university: "",
        studyArea: "",
        currentlyStudent: true,
        supervisorStudent: "",
        universityStudent: "",
        studyAreaStudent: "",
        department: "",
        jobTitle: "",
        address: "",
        zipCode: "",
        city: {},
        mobilePhone: "",
        country: "",
        url: "",
        skypeId: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        biography: "",
        googleScholarUser: "",
        orcidId: "",
        memberships: [],
      },
      membership: {},
      membershipClassification: 15,
      open: false,
      options: [],
      loading: false,
      unsavedChanges: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeLeaving);
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    window.addEventListener("beforeunload", this.beforeLeaving);
    let user = {};
    await axios
      .get("/api/login/user")
      .then((req) => {
        user = req.data;
      })
      .catch((err) => {
        alert("An error as occurred while fetching your information!");
        window.location.replace("/");
      });

    if (user) {
      const membershipReq = await axios.post(
        "/api/memberships/find/", {
          query: {
            idUser: user._id
          }
        }).then(async (response) => {
          if (response.status === 200) {
            let membershipReq = response;

            const membershipClassificationReq = await axios.get(
              "/api/memberships/classifications/" +
              membershipReq.data.idMembershipClassification
            );

            user.memberships = user.memberships === null ? [] : user.memberships;
            this.setState({
              membership: this.getLatestMembership(
                membershipReq.data,
                membershipClassificationReq.data
              ),
            });

          }
        }).catch(err => {

        });


      user.photo = user.photo ? user.photo : "";
      user.title = titleOptions.filter((x) => x.value === user.idTitle)[0];
      user.academicDegreeStudent = !user.academicDegreeStudent
        ? currentAcademicDegreeOptions[0]
        : currentAcademicDegreeOptions.filter(
          (x) => x.label === user.academicDegreeStudent
        )[0];
      user.academicDegree = !user.academicDegree
        ? currentAcademicDegreeOptions[0]
        : currentAcademicDegreeOptions.filter(
          (x) => x.label === user.academicDegree
        )[0];

      const year = new Date().getFullYear();
      user.yearGraduated = !user.yearGraduated
        ? {
          value: year,
          label: year,
        }
        : {
          value: user.yearGraduated,
          label: user.yearGraduated,
        };

      CountryRegionData.forEach((element) => {
        if (element[0] === user.country) user.country = element;
      });

      user.currentlyStudent = user.currentlyStudent
        ? user.currentlyStudent.toString()
        : "false";
      user.memberships = user.memberships === null ? [] : user.memberships;

      user.city = { name: user.city, value: user.city };
      this.setState({
        user: user
      });
    }
    this.setState({
      loading: false,
    });
  }

  checkIfLoading = () => {
    return this.state.open && this.state.options.length === 0;
  };

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      unsavedChanges: true,
    }));
  };

  onSelectChange = (select, event) => {
    const { value, label } = select;
    const { name } = event;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: { value: value, label: label },
      },
      unsavedChanges: true,
    }));
  };

  onSubmit = async () => {
    let user = Object.assign({}, this.state.user);

    user.idTitle = user.title.value;
    user.title = user.title.label;
    user.idAcademicDegree = user.academicDegree.value;
    user.idAcademicDegreeStudent = user.academicDegreeStudent.value;
    user.academicDegree = user.academicDegree.label;
    user.academicDegreeStudent = user.academicDegreeStudent.label;
    user.currentlyStudent = user.currentlyStudent === "true";
    user.yearGraduated = user.yearGraduated.value;
    user.country = user.country[0];
    user.city = user.city.value;
    user.email = this.state.user.email;
    user.photoFile = this.state.photoFile;

    put2("", user, "users", async () => {
      this.setState({ unsavedChanges: false });
      postLog(
        "User's Information updated",
        "User changed his basic information",
        this.state.user.firstName + " " + this.state.user.lastName,
        this.state.user.email
      );

      // Sending changes to PRIMORIS
      // await axios.post(
      //   "https://www.insticc.org/PrimorisWebServices/api/MyAccount/UpdateInfo",
      //   user,
      //   { withCredentials: false }
      // );
      alert("Successfully updated info!");
      window.location.reload();
    });
  };

  onAutoCompleteChange = async (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      this.setState({
        loading: true,
      });
      const req = await axios.post(
        `https://www.insticc.org/venus/api/1.0.0/countries/${this.state.user.country[1]}/cities/find`,
        {
          query: {
            city: value,
          },
        },
        {
          withCredentials: false,
        }
      );
      this.setState({
        options: req.data.map((x) => {
          return {
            name: x["AccentCity"],
            value: x["AccentCity"],
          };
        }),
        loading: false,
      });
    } else
      this.setState({
        loading: false,
        options: [],
      });
  };

  onFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    

    if (file) {
      try {
        const reducedFile = await resizeImageFile(file, 300, 300);
        
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            [name]: reducedFile,
          },
        }));

        this.setState({ photoFile: reducedFile })
        // this.onSubmit();
      } catch (err) {
        alert(err);
      }
     
    }
    

  };

  previewImage = () => {
    const { photo } = this.state.user;

    let url = null;
    if (photo instanceof File) {
      url = URL.revokeObjectURL(photo);
      url = URL.createObjectURL(photo);
    }
    else if (photo instanceof FileList) {
      if (photo.length <= 0) return false;
    } //else if (photo) url = config.usersImagePath + "/" + photo;
    else if (photo) url = photo  + '?q=' + Math.random() ;
    return url;
  };

  checkIfAnyMembershipIsEmpty = () => {
    const { memberships } = this.state.user;

    for (let membership of memberships)
      if (!membership.associationName || !membership.memberNumber) return true;

    if (memberships.length < this.state.nMembershipsRows) return true;

    return false;
  };

  getLatestMembership = (membership, membershipClassification) => {
    if (membership && membership.period) {
      const latest = membership.period[membership.period.length - 1];

      if (latest)
        return {
          memberNumber: membership.membershipNumber,
          memberType: membershipClassification.name,
          validationDate: latest.end,
          status: latest.isActive,
        };
      else return null;
    }

    return null;
  };

  beforeLeaving = (e) => {
    var dialogText = "-";
    if (this.state.unsavedChanges) e.returnValue = dialogText;
    return dialogText;
  };

  render() {
    const {
      title,
      organization,
      firstName,
      middleName,
      lastName,
      academicDegreeStudent,
      academicDegree,
      currentlyStudent,
      yearGraduated,
      department,
      jobTitle,
      address,
      zipCode,
      city,
      mobilePhone,
      country,
      url,
      skypeId,
      twitter,
      facebook,
      linkedin,
      biography,
      supervisor,
      university,
      studyArea,
      supervisorStudent,
      universityStudent,
      studyAreaStudent,
      googleScholarUser,
      isMember,
    } = this.state.user;
    const { open, loading, options, membership } = this.state;
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/MyAccount/original.png" alt="Banner" className="w-100" />
        {loading ? (
          <CircularProgress />
        ) : (
            <Grid container justify="center">
              <Grid item xs={12} lg={4} className="yellow-bg" component={Box} p={5}>
                <h4>
                  <b>My Account</b>
                </h4>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="center">
                      <div className="profile-image-container">
                        <img
                          className="round-image center profile-image"
                          src={
                            this.previewImage() ||
                            config.usersImagePath + "profile-placeholder.png"
                          }
                          alt="Profile"
                        />
                        <div className="profile-image-overlay">
                          <div className="profile-image-placeholder">
                            <label
                              id="profile-image-picker-label"
                              htmlFor="profile-image-picker"
                            >
                              Upload an image
                        </label>
                            <input
                              type="file"
                              name="photo"
                              id="profile-image-picker"
                              accept="image/*"
                              onChange={this.onFileChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Title:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelect
                          backgroundColor="white"
                          options={titleOptions}
                          onChange={this.onSelectChange}
                          defaultValue={titleOptions[0]}
                          value={title}
                          name="title"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>First Name:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <RoundTextField
                          name="firstName"
                          error={nameTester(firstName)}
                          helperText={
                            <p style={{ marginTop: "0 !important" }}>
                              {nameTesterHelperText(firstName)}
                            </p>
                          }
                          value={firstName}
                          onChange={this.onEventChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Middle Name:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <RoundTextField
                          onChange={this.onEventChange}
                          name="middleName"
                          value={middleName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Last Name:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <RoundTextField
                          onChange={this.onEventChange}
                          error={nameTester(lastName)}
                          helperText={nameTesterHelperText(lastName)}
                          name="lastName"
                          value={lastName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Gender:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <CustomRadio
                              checked={this.state.user.gender === "Male"}
                              onChange={this.onEventChange}
                              name="gender"
                              value="Male"
                            />
                          }
                          label="Male"
                        />
                        <FormControlLabel
                          control={
                            <CustomRadio
                              checked={this.state.user.gender === "Female"}
                              onChange={this.onEventChange}
                              name="gender"
                              value="Female"
                            />
                          }
                          label="Female"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Current Academic Degree:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelect
                          backgroundColor="white"
                          options={currentAcademicDegreeOptions}
                          onChange={this.onSelectChange}
                          defaultValue={currentAcademicDegreeOptions[1]}
                          value={academicDegree}
                          name="academicDegree"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse
                      in={academicDegree.value !== 0 && academicDegree.value !== 11}
                    >
                      <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={6} className="text-align-right">
                            <b>Supervisor:</b>
                          </Grid>
                          <Grid item xs={6}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="supervisor"
                              value={supervisor}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={6} className="text-align-right">
                            <b>University:</b>
                          </Grid>
                          <Grid item xs={6}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="university"
                              value={university}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={6} className="text-align-right">
                            <b>Study Area:</b>
                          </Grid>
                          <Grid item xs={6}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="studyArea"
                              value={studyArea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={6} className="text-align-right">
                            <b>Degree obtained on</b>
                          </Grid>
                          <Grid item xs={6}>
                            <CustomSelect
                              backgroundColor="white"
                              options={getGraduationOptions()}
                              defaultValue={new Date().getFullYear()}
                              onChange={this.onSelectChange}
                              value={yearGraduated}
                              name="yearGraduated"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6} className="text-align-right">
                        <b>Currently a student:</b>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <CustomRadio
                              checked={currentlyStudent === "true"}
                              onChange={this.onEventChange}
                              name="currentlyStudent"
                              value={true}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <CustomRadio
                              checked={currentlyStudent !== "true"}
                              onChange={this.onEventChange}
                              name="currentlyStudent"
                              value={false}
                            />
                          }
                          label="No"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={currentlyStudent === "true"}>
                      <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={6} className="text-align-right">
                            <b>Degree:</b>
                          </Grid>
                          <Grid item xs={6}>
                            <CustomSelect
                              backgroundColor="white"
                              options={currentAcademicDegreeOptions}
                              onChange={this.onSelectChange}
                              defaultValue={currentAcademicDegreeOptions[1]}
                              value={academicDegreeStudent}
                              name="academicDegreeStudent"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Collapse
                          in={
                            academicDegreeStudent.value !== 0 &&
                            academicDegreeStudent.value !== 11
                          }
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ paddingTop: "15px" }}
                          >
                            <Grid item xs={12}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={6} className="text-align-right">
                                  <b>Supervisor:</b>
                                </Grid>
                                <Grid item xs={6}>
                                  <RoundTextField
                                    onChange={this.onEventChange}
                                    name="supervisorStudent"
                                    value={supervisorStudent}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={6} className="text-align-right">
                                  <b>University:</b>
                                </Grid>
                                <Grid item xs={6}>
                                  <RoundTextField
                                    onChange={this.onEventChange}
                                    name="universityStudent"
                                    value={universityStudent}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={6} className="text-align-right">
                                  <b>Study Area:</b>
                                </Grid>
                                <Grid item xs={6}>
                                  <RoundTextField
                                    onChange={this.onEventChange}
                                    name="studyAreaStudent"
                                    value={studyAreaStudent}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    </Collapse>
                  </Grid>
                  {isMember ? (
                    <Grid item xs={12}>
                      <hr />
                      <h4>INSTICC Membership</h4>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} className="text-align-right">
                              <b>Member Number:</b>
                            </Grid>
                            <Grid item xs={6}>
                              <b>{membership ? membership.memberNumber : ""}</b>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} className="text-align-right">
                              <b>Member Type:</b>
                            </Grid>
                            <Grid item xs={6}>
                              <b>{membership ? membership.memberType : ""}</b>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} className="text-align-right">
                              <b>Registration Date:</b>
                            </Grid>
                            <Grid item xs={6}>
                              <b>
                                {membership
                                  ? Moment(membership.startDate).format("MM/YYYY")
                                  : ""}
                              </b>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} className="text-align-right">
                              <b>Validity Date:</b>
                            </Grid>
                            <Grid item xs={6}>
                              <b>
                                {membership
                                  ? Moment(membership.validationDate).format(
                                    "MM/YYYY"
                                  )
                                  : ""}
                              </b>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} className="text-align-right">
                              <b>Status:</b>
                            </Grid>
                            <Grid item xs={6}>
                              <b>
                                {membership
                                  ? membership.status &&
                                    Date.now() < new Date(membership.validationDate)
                                    ? "Active"
                                    : "Inactive"
                                  : ""}
                              </b>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Box style={{ borderBottom: "3px solid #ece9d6" }}>
                  <Box pt={5} pb={5} pl={10} pr={10}>
                    <Tabs
                      items={menuItems}
                      selected="General Information"
                      fullWidthItems
                      spacing={2}
                      className="pb-5"
                    />
                    <h4>
                      <b>Address Details and Contacts</b>
                    </h4>
                    <br></br>
                    <br></br>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>
                                Organization:
                            <Tooltip
                                  title={
                                    <p className="pt-3">
                                      Organization needs to have at least 3
                                      characters.
                                  {"\n"}
                                      If you do not have an Organization, please add
                                      'Independent Researcher'.
                                </p>
                                  }
                                  className="mb-3"
                                >
                                  <HelpIcon style={{ fontSize: 15 }} />
                                </Tooltip>
                              </b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="organization"
                              error={organization.length < 3}
                              helperText={
                                organization.length < 3 ? "Invalid format" : ""
                              }
                              backgroundColor="#ece9d6"
                              value={organization}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>Department:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="department"
                              backgroundColor="#ece9d6"
                              value={department}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>Job Title:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="jobTitle"
                              backgroundColor="#ece9d6"
                              value={jobTitle}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>Address:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="address"
                              backgroundColor="#ece9d6"
                              value={address}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={4}>
                                <h6>
                                  <b>Zip Code:</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <RoundTextField
                                  onChange={this.onEventChange}
                                  name="zipCode"
                                  backgroundColor="#ece9d6"
                                  value={zipCode}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={2}>
                                <h6 className="pr-4">
                                  <b>City:</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={10}>
                                <CustomAutoComplete
                                  fullWidth
                                  open={open}
                                  onOpen={() => {
                                    this.setState({
                                      open: true,
                                    });
                                  }}
                                  onClose={() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }}
                                  getOptionSelected={(option, value) =>
                                    option.name === value.name
                                  }
                                  getOptionLabel={(option) => option.name}
                                  onAutoCompleteChange={this.onAutoCompleteChange}
                                  options={options}
                                  loading={loading}
                                  onChange={(e, value) => {
                                    this.setState((prevState) => ({
                                      user: {
                                        ...prevState.user,
                                        city: value,
                                      },
                                    }));
                                  }}
                                  value={city}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={4}>
                                <h6>
                                  <b>Mobile phone (sms):</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <RoundTextField
                                  onChange={this.onEventChange}
                                  name="mobilePhone"
                                  backgroundColor="#ece9d6"
                                  value={mobilePhone}
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={3}>
                                <h6 className="pr-4">
                                  <b>Country:(*)</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                <RoundTextField
                                  name="country"
                                  value={country}
                                  backgroundColor="#ece9d6"
                                  select
                                  onChange={(event) => {
                                    this.onEventChange(event);
                                    this.setState((prevState) => ({
                                      user: {
                                        ...prevState.user,
                                        city: {},
                                      },
                                      options: [],
                                    }));
                                  }}
                                >
                                  {CountryRegionData.map((option, index) => (
                                    <MenuItem key={option[0]} value={option}>
                                      {option[0]}
                                    </MenuItem>
                                  ))}
                                </RoundTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>URL / Homepage:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="url"
                              backgroundColor="#ece9d6"
                              value={url}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  pt={5}
                  pb={5}
                  pl={10}
                  pr={10}
                  style={{ borderBottom: "3px solid #ece9d6" }}
                >
                  <h4 className="pb-5">
                    <b>Social Nets IDs</b>
                  </h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={3}>
                              <h6>
                                <b>Skype ID:</b>
                              </h6>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <RoundTextField
                                onChange={this.onEventChange}
                                name="skypeId"
                                backgroundColor="#ece9d6"
                                value={skypeId}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={3}>
                              <h6>
                                <b>LinkedIn:</b>
                              </h6>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <RoundTextField
                                onChange={this.onEventChange}
                                name="linkedin"
                                backgroundColor="#ece9d6"
                                value={linkedin}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={3}>
                              <h6>
                                <b>Twitter:</b>
                              </h6>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <RoundTextField
                                onChange={this.onEventChange}
                                name="twitter"
                                backgroundColor="#ece9d6"
                                value={twitter}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={3}>
                              <h6>
                                <b>Facebook:</b>
                              </h6>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <RoundTextField
                                onChange={this.onEventChange}
                                name="facebook"
                                backgroundColor="#ece9d6"
                                value={facebook}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={5} pb={5} pl={10} pr={10}>
                  <h4 className="pb-5">
                    <b>Index Links</b>
                  </h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <h6>
                            <b>SCOPUS Author ID or URL:</b>
                          </h6>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <RoundTextField
                            onChange={this.onEventChange}
                            name="scopusAuthor"
                            backgroundColor="#ece9d6"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <h6>
                            <b>ORCID ID or URL:</b>
                          </h6>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <RoundTextField
                            onChange={this.onEventChange}
                            name="orcidId"
                            backgroundColor="#ece9d6"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <h6>
                            <b>Google Scholar User or URL:</b>
                          </h6>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <RoundTextField
                            onChange={this.onEventChange}
                            name="googleScholarUser"
                            backgroundColor="#ece9d6"
                            value={googleScholarUser}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} component={Box} pt={10} pl={10} pb={5} pr={10}>
                <h4>
                  <b>Biography</b>
                </h4>
                <RoundTextareaAutosize
                  value={biography}
                  name="biography"
                  backgroundColor="#ece9d6"
                  minHeight="250px"
                  onChange={this.onEventChange}
                />
              </Grid>
              <Grid item xs={12} component={Box} pb={5}>
                <Grid container justify="center">
                  <StandardButton
                    type="submit"
                    variant="outlined"
                    onClick={this.onSubmit}
                  >
                    Save my Account
              </StandardButton>
                </Grid>
              </Grid>
            </Grid>
          )}
      </div>
    );
  }
}

export default MyAccount;
