import { Box } from "@material-ui/core";
import React from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PrivacyPolicy/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="yellow-text">
            <b>Privacy Policy</b>
          </h3>
          <p className="paragraph pt-3 pb-3">
            We have updated our privacy policy and have it explained in simple
            terms, with transparency, avoiding complex legal language.{"\n\n"}
            At INSTICC, we respect your privacy. We want to ensure that you get
            the information, content, and experiences that matter most to you.
            INSTICC is committed to protecting the privacy of its members,
            customers, reviewers, authors, speakers, and other contacts. We are
            working to earn your trust every day by focusing on four key privacy
            principles:{"\n\n"}
            <ul>
              <li>
                Control: We will put you in control of your privacy with
                easy-to-use tools and clear choices.
              </li>
              <li>
                Transparency: We will be transparent about data collection and
                use so you can make informed decisions.
              </li>
              <li>
                Security: We will protect the data you entrust to us through
                strong security and encryption.
              </li>
              <li>
                Benefits to you: When we do collect data, we will use it to
                benefit you and to make your experiences better.
              </li>
              <li>
                Our Privacy Policy is intended to describe to you how and what
                data we collect, and how and why we use your personal data. It
                also describes options we provide for you to access, update or
                otherwise take control of your personal data that we process.
              </li>
            </ul>
          </p>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="What information do we collect?"
            content={
              <div>
                We collect information so that we can provide the best possible
                experience when you utilize our Services. This information is
                collected for example when you create an account at PRIMORIS,
                myINSTICC, or other INSTICC websites, or when you submit a paper
                to a conference, meeting or other event organized, sponsored or
                supported by INSTICC. We may collect the following personal data
                in line with the use purposes explained in a subsequent section:
                {"\n\n"}
                <ul>
                  <li>Your name and contact details</li>
                  <li>Date of Birth</li>
                  <li>Online profile data/usage</li>
                  <li>Emergency contact information</li>
                  <li>Social media profile information</li>
                  <li>Copies of identification documents</li>
                  <li>Education and professional information</li>
                  <li>
                    Communication information including INSTICC Online Support
                    and Contact Center communications
                  </li>
                  <li>Purchasing and payment information</li>
                  <li>
                    Registration and participation in INSTICC events and
                    activities
                  </li>
                  <li>Subscription preferences</li>
                  <li>Information about the device(s) you use</li>
                  <li>Information about service usage</li>
                  <li>Cookies</li>
                  <li>Authentication data</li>
                  <li>Location information</li>
                  <li>Author and peer review information</li>
                  <li>Other information you upload or provide to us</li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="2"
            title="How do we use your information?"
            content={
              <div>
                INSTICC uses (and, where specified, shares) your personal
                information for the following purposes:{"\n\n"}
                <ul>
                  <li>
                    <b>
                      To provide information based on your needs and respond to
                      your requests.
                    </b>{" "}
                    INSTICC may use your personal information to provide you
                    with notices of new product releases and service
                    developments which we believe are in line with your
                    interests.
                  </li>
                  {"\n"}
                  <li>
                    <b>To provide support or other services.</b> INSTICC may use
                    your personal information to provide support or other
                    services that you have ordered or requested. INSTICC may
                    also use your personal information to respond directly to
                    your requests for information, including registrations for
                    newsletters, booklets, flyers, posters, or other specific
                    requests, or pass your contact information to the
                    appropriate INSTICC distributor or reseller for further
                    follow-up related to your interests.
                  </li>
                  {"\n"}
                  <li>
                    <b>To provide online forums and social networks.</b> Some
                    services available on the websites permit you to participate
                    in interactive discussions, post comments, or otherwise
                    engage in networking activities. INSTICC does not control
                    the content that users post to these forums or social
                    networks. You should carefully consider whether you wish to
                    submit personal information to these forums or social
                    networks.
                  </li>
                  {"\n"}
                  <li>
                    <b>To process transactions.</b> INSTICC may use personal
                    information such as name, physical address, telephone
                    number, email address, and company/institution to interact
                    with you, including contacting you about orders,
                    subscriptions, event participation, or membership. We use
                    financial and payment information to process your orders and
                    may need to share some of this information with delivery
                    services, financial corporations, and other parties to
                    complete transactions.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To administer orders, membership, other services or
                      products.
                    </b>{" "}
                    INSTICC may contact you if you purchase products or
                    services, to help solving problems or confirm information
                    about your orders. We may also use this information to
                    confirm compliance with licensing and other terms of use and
                    may share it with your company/institution.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To select content, improve quality, and facilitate use of
                      the websites.
                    </b>{" "}
                    INSTICC may use your personal information, including the
                    information gathered as a result of site navigation and
                    electronic protocols and cookies (including third-party
                    cookies), improve website quality, evaluate page response
                    rates, conduct usability testing, and facilitate your use of
                    the websites (for example, to facilitate navigation and the
                    login process, avoid duplicate data entry, enhance security,
                    and preserve information between sessions).
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To communicate with you about a meeting, conference, or
                      event.
                    </b>{" "}
                    We may communicate with you about a meeting, conference, or
                    event hosted or co-sponsored by INSTICC or one of our
                    business associates. This may include information about the
                    event's content, event logistics, payment, updates, and
                    additional information related to the event. Information you
                    provide when registering for or participating in a
                    conference managed or co-sponsored by parties, other than or
                    in addition to INSTICC may be shared with those parties, and
                    the treatment of such information is further subject to the
                    privacy policies of those parties. INSTICC and its
                    conference co-sponsors may contact you after the event about
                    the event, subsequent iterations of the event and related
                    events. Although INSTICC does not share your data with
                    sponsors or event participants, you are well advised to
                    control the data that you share with them directly at the
                    venue.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To consider you for a higher member grade or an INSTICC
                      award.
                    </b>{" "}
                    INSTICC may use personal information such as your name,
                    education, work history, and volunteer activities to
                    consider you for a higher member grade or for an INSTICC
                    award. INSTICC may need to share this information with your
                    nominators, references, judges, and others parties involved
                    in evaluating your eligibility.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To assist in your participation in INSTICC activities.
                    </b>{" "}
                    INSTICC will communicate with you, if you are participating
                    in certain INSTICC activities such as INSTICC conferences,
                    authoring or reviewing an INSTICC periodical article, or
                    other INSTICC technical or scientific activities. INSTICC
                    may send you information such as newsletters related to
                    those activities.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To update you on relevant INSTICC benefits, programs, and
                      opportunities.
                    </b>{" "}
                    INSTICC may communicate with you regarding relevant INSTICC
                    benefits, programs, and opportunities available to you,
                    through your membership(s) with INSTICC.
                  </li>
                  {"\n"}
                  <li>
                    <b>
                      To protect INSTICC content and services. We may use your
                      information to prevent potentially illegal activities and
                      to enforce our terms and conditions.
                    </b>{" "}
                    We also use a variety of technological systems to detect and
                    address anomalous activity and to screen content to prevent
                    abuse, such as spam. These efforts may, on occasion, result
                    in a temporary or permanent suspension or termination of
                    some functions for some users.
                  </li>
                  {"\n"}
                  <li>
                    <b>To get feedback or input from you.</b> In order to
                    deliver products and services of most interest to our
                    customers, from time to time, we may ask members, customers,
                    volunteers, and website visitors to provide us input and
                    feedback (for example through surveys, usability studies,
                    focus groups).
                  </li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="3"
            title="How can you control your information?"
            content={
              <div>
                You can control the information we have about you and how we use
                it in several ways.{"\n\n"}
                <ul>
                  <li>
                    If you are a registered user, you can review, revise,
                    correct and even delete the personal data that you have
                    provided to INSTICC via one of our information systems,
                    including the myINSTICC or PRIMORIS systems.
                  </li>
                  {"\n"}
                  <li>
                    Use the unsubscribe method provided in our various
                    communications. Ask us to give you access to all your
                    information in a portable format.
                  </li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="4"
            title="Logging practices"
            content={
              <div>
                INSTICC may automatically record web analytics, using third
                party or internally developed software, such as the Internet
                Protocol (IP) addresses of visitors. As well as recording the IP
                addresses of users, INSTICC may also keep track of sites that
                users visited immediately prior to visiting INSTICC's website
                and the search terms they used to find it as well as the pages
                visited on INSTICC's website, the amount of time spent on those
                pages and the types of searches done on them. Your searches
                remain confidential and anonymous. INSTICC uses this information
                only for statistical purposes to find out which pages users find
                most useful and to improve the website. INSTICC also captures
                and stores information about the devices used to access INSTICC
                websites and related data, such as:
                {"\n\n"}
                <ul>
                  <li>Browser/Device type/version</li>
                  {"\n"}
                  <li>Operating system used</li>
                  {"\n"}
                  <li>Media Access Control (MAC) address</li>
                  {"\n"}
                  <li>Date and time of the server request</li>
                  {"\n"}
                  <li>Volume of data transferred</li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="5"
            title="External links behavior"
            content={
              <div>
                Some of INSTICC's websites link to other sites created and
                maintained by other public- and/or private-sector organizations.
                INSTICC provides these links solely for your information and
                convenience. When you transfer to an outside website, you are
                leaving the INSTICC domain, and INSTICC's information management
                policies no longer apply. INSTICC encourages you to read the
                privacy statement of each external website that you visit before
                you provide any personal data.
              </div>
            }
          />
          <InnerAccordion
            eventKey="6"
            title="Cookies and similar technologies"
            content={
              <div>
                Cookies are electronic placeholders that are placed on your
                device to track your behavior on that website over time. INSTICC
                uses both session-based cookies (which last only for the
                duration of the user's session) and persistent cookies (which
                remain on your device and provides information about the session
                you are in and waits for the next time you use that site again).
                {"\n\n"}
                These cookies provide useful information to INSTICC, enabling us
                to recognize repeat users, facilitate the user's access to and
                use of our sites, allows us to track usage behavior, and to
                balance the usage of our websites on all INSTICC web servers. We
                may also collect your location (IP address) so that we can
                personalize our Services.{"\n\n"}
                Tracking cookies, third-party cookies, and other technologies
                such as web beacons may be used to process additional
                information, enable non-core functionalities on the INSTICC
                website and enable third-party functions (such as a social media
                "share" link). We may also include similar technologies in
                promotional email messages to determine whether the messages
                have been opened.
              </div>
            }
          />
          <InnerAccordion
            eventKey="7"
            title="Do Not Track (DNT)"
            content={
              <div>
                The online advertising industry has self-regulatory initiatives
                designed to provide consumers a choice in the types of ads they
                may see online and to conveniently opt-out from online
                behavioral ads served by some or all of the companies
                participating in these programs. Our websites do not respond to
                DNT consumer browser settings.
              </div>
            }
          />
          <InnerAccordion
            eventKey="7"
            title="Age restrictions"
            content={
              <div>
                Our Services are available for purchase only for those over the
                age of 18. Our Services are not targeted to, intended to be
                consumed by or designed to entice individuals under the age of
                18. If you know of or have reason to believe anyone under the
                age of 18 has provided us with any personal data, please contact
                us.
              </div>
            }
          />
          <InnerAccordion
            eventKey="8"
            title="Do Not Track (DNT)"
            content={
              <div>
                The online advertising industry has self-regulatory initiatives
                designed to provide consumers a choice in the types of ads they
                may see online and to conveniently opt-out from online
                behavioral ads served by some or all of the companies
                participating in these programs. Our websites do not respond to
                DNT consumer browser settings.
              </div>
            }
          />
          <InnerAccordion
            eventKey="9"
            title="Changes in our Privacy Policy"
            content={
              <div>
                We reserve the right to modify this Privacy Policy at any time.
                If we decide to change our Privacy Policy, we will post those
                changes to this Privacy Policy and any other places we deem
                appropriate, so that you are aware of what information we
                collect, how we use it, and under what circumstances, if any, we
                disclose it. If we make material changes to this Privacy Policy,
                we will notify you here, by email, or by means of a notice on
                our home page, at least thirty (30) days prior to the
                implementation of the changes.
              </div>
            }
          />
          <InnerAccordion
            eventKey="10"
            title="Collaboration with authorities"
            content={
              <div>
                INSTICC has appointed and mandated a data privacy officer who
                represents the regulatory authorities inside the INSTICC
                organization, and in return represents the INSTICC organization
                to regulatory authorities.{"\n\n"}
                The INSTICC privacy officer will ensure proper communication
                with the relevant regulatory authority for privacy. The privacy
                officer will lead investigative action, complaint handling and
                data breach notification. The privacy officer will also monitor
                regulatory changes and consult the regulatory authority where
                implementation of a regulatory or technological change leads to
                doubt.
              </div>
            }
          />
          <InnerAccordion
            eventKey="11"
            title="Your European Union privacy rights"
            content={
              <div>
                Under the General Data Protection Regulation (Regulation EU
                2016/679) (also known as GDPR), if you are an individual
                protected by the GDPR you may have certain rights as a data
                subject. To request information about or avail yourself of those
                rights, please send an email to privacy@INSTICC.org with "GDPR
                Request" in the subject line. In the email please describe, with
                specificity, the GDPR right you are requesting assistance with.
                Please note additional information may be requested prior to
                initiation of a request and that INSTICC reserves the right to
                charge a fee with respect to certain requests. Upon INSTICC’s
                completion of its review you will be notified if your request
                has been granted, denied, or exemptions apply.
              </div>
            }
          />
          <InnerAccordion
            eventKey="12"
            title="How we secure, store and retain your data?"
            content={
              <div>
                We follow generally accepted standards to store and protect the
                personal data we collect, both during transmission and once
                received and stored, including utilization of encryption where
                appropriate.{"\n\n"}
                We retain personal data only for as long as necessary to provide
                the Services you have requested and thereafter for a variety of
                legitimate legal or business purposes. These might include
                retention periods:{"\n\n"}
                <ul>
                  <li>
                    mandated by law, contract or similar obligations applicable
                    to our business operations;
                  </li>
                  {"\n"}
                  <li>
                    for preserving, resolving, defending or enforcing our
                    legal/contractual rights; or
                  </li>
                  {"\n"}
                  <li>
                    needed to maintain adequate and accurate business and
                    financial records. If you have any questions about the
                    security or retention of your personal data, you can contact
                    our data protection officer at{" "}
                    <a href="mailto:privacy@insticc.org">privacy@insticc.org</a>
                    .
                  </li>
                </ul>
              </div>
            }
          />
          <InnerAccordion
            eventKey="13"
            title="How do I contact you if there is an issue?"
            content={
              <div>
                If you have any questions or concerns about this Privacy Policy
                or about the use of your personal information, please feel free
                to contact us by email at{" "}
                <a href="mailto:privacy@insticc.org">privacy@insticc.org</a>.
                {"\n\n"}
                Attn: Office of the Data Protection Officer, Rua dos Lusiadas,
                n.118 - 3.Frente, 1300-376 Lisboa, Portugal.
                {"\n\n"}
                <b>
                  Date: 23 May 2018{"\n"}
                  Version 1.0
                </b>
              </div>
            }
          />
        </Box>
      </div>
    );
  }
}

export default PrivacyPolicy;
