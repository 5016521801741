import React, { Component } from "react";
import MaterialTable from "material-table";
import { Tooltip } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { getTopicName } from "../../../js/auxFunctions";
import NavigationBar from "../../NavigationBar";

const columns = [
  {
    title: "Photo",
    field: "photo",
    render: (rowData) => {
      return (
        <img
          src={config.newsImageBasePath + rowData.photo}
          alt="News"
          className="list-image"
          key={Date.now()}
        />
      );
    },
  },
  {
    title: "Title",
    field: "title",
    render: (rowData) => {
      return (
        <Tooltip title={rowData.title}>
          <span className="line-clamp">{rowData.title}</span>
        </Tooltip>
      );
    },
  },
  { title: "Author", field: "author" },
  {
    title: "Interest Areas",
    field: "interestAreas",
    render: (rowData) => {
      return (
        <Tooltip title={rowData.interestAreas}>
          <span className="line-clamp">{rowData.interestAreas}</span>
        </Tooltip>
      );
    },
  },
  { title: "Status", field: "status" },
];

class NewsManagementList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: [],
    };
  }

  async componentDidMount() {
    const isUserAdminReq = await axios.get("/Api/Login/IsAdmin");
    const isUserAdmin = isUserAdminReq.data;

    const res = await axios.post(`/api/news/find`, {
      query: {
        status: { $ne: isUserAdmin ? "" : "Deleted" },
      },
      sort: {
        "publishedDate.date": -1,
      },
    });
    const topics = (
      await axios.post("/api/topics/find", {
        query: {
          isFilter: true,
        },
        sort: {
          name: 1,
        },
      })
    ).data;

    var customData = [];
    res.data.map((news, index) => {
      customData.push({
        id: news._id,
        photo: news.mainPhoto + "?q=" + Date.now(),
        title: news.title,
        author: news.firstName
          ? news.firstName + " " + news.middleName + " " + news.lastName
          : "",
        interestAreas: news.interestAreas
          .map((id) => {
            return getTopicName(id, topics);
          })
          .join(", "),
        status: news.status,
      });
      return true;
    });
    this.setState({ data: customData, isLoading: false });
  }

  render() {
    return (
      <div>
        <NavigationBar isBackoffice />
        <div className="content">
          <div className="p-5">
            <MaterialTable
              isLoading={this.state.isLoading}
              title="News Management"
              columns={columns}
              data={this.state.data}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add News",
                  isFreeAction: true,
                  onClick: (event) =>
                    this.props.history.push("/Backoffice/News/Add"),
                },
                {
                  icon: "settingsapplications",
                  tooltip: "Manage News",
                  onClick: (event, rowData) =>
                    this.props.history.push("/Backoffice/News/" + rowData.id),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50, 100],
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewsManagementList);
