import { Box, Grid } from "@material-ui/core";
import React from "react";
import MultiSlider from "../MultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import MoreButton from "../Custom Components/MoreButton";
import config from "../../config";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import { departments } from "../AboutUs/departmentsArray";

class InstitutionalPartners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      partners: [],
    };
  }

  async componentDidMount() {
    const res = await axios.get("/api/partners/byType/Institutional Partner");
    this.setState({
      partners: res.data,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, partners } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/InstitutionalPartners/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Institutional Partners</h3>
          <br></br>
          <br></br>
          <h4 className="subtitle">
            Partnership Opportunities for Institutional Partners
          </h4>
          <br></br>
          <p className="paragraph body-text">
            Aware of the importance of international cooperation for the
            advancement of science, INSTICC works closely together with several
            other national and international associations and is proud to
            welcome them as partners in its activities or to arrange initiatives
            in cooperation with them.{"\n\n"} While several forms of partnership
            are welcome, INSTICC places special value in "Technical
            Co-Sponsorships", by means of which both associations support each
            other from a scientific perspective for their mutual benefit.
            Additionally, in this case, members of partner organizations benefit
            from the same registration conditions as INSTICC members.
          </p>
          <br></br>
          <h4 className="subtitle">INSTICC Events</h4>
          <br></br>
          <p className="paragraph body-text">
            Associations, Federations and Special Interest Groups can benefit
            from INSTICC events by presenting their research and scientific
            results, be it by presenting a paper or hosting a tutorial or
            instructional course, by contributing towards panels and discussions
            in the event's field of interest or by presenting their
            organization, be it by setting up an exhibition booth, by profiling
            themselves in the event's web presence or printed materials or by
            suggesting keynote speakers or even new events that can be organized
            cooperatively.
          </p>
          <br></br>
          <h4 className="subtitle">Contact</h4>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is open to partnership possibilities beyond the examples
            mentioned here and welcomes suggestions and cooperation proposals.
            Those interested in cooperating with INSTICC should contact our{" "}
            <HtmlTooltip
              department={departments[3]}
              text="Institutional Relations Office"
            />
            .
          </p>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <MultiSlider
              items={partners}
              autoPlay
              borderColor="transparent"
              arrows={false}
              baseRef={config.partnersBasePath}
              imageBaseRef={config.partnersImageBasePath}
            />
          )}
          <Grid container justify="center" className="pt-5">
            <MoreButton
              title="More Institutional Partners"
              href={config.partnersBasePath + "?type=Institutional Partner"}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default InstitutionalPartners;
