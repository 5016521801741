import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import swal from "sweetalert";
import { postLog } from "../../js/httpRequests";
import CustomCountrySelect from "../Custom Components/CustomCountrySelect";
import RoundTextField from "../Custom Components/RoundTextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class RepresentativeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      form: {},
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  onCountryChange = (value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        country: value,
      },
    }));
  };

  checkForErrors = (form) => {
    if (
      !form.name ||
      !form.email ||
      !form.affiliation ||
      !form.city ||
      !form.country ||
      !form.biography
    ) {
      swal({
        title: "Error",
        text: "Please ensure you filled the required fields",
        icon: "error",
      });

      return true;
    }

    return false;
  };

  onSubmitClick = async () => {
    let form = this.state.form;
    let error = this.checkForErrors(form);

    if (error) return;
    else {
      const res = await (await axios.post("/api/representativeForms", form))
        .data;
      if (res.status !== 200) {
        const loggedUser = await (await axios.get("/api/login/user")).data;
        postLog(
          "Representative form submitted",
          "User submitted a representative form",
          loggedUser.firstName + " " + loggedUser.lastName,
          loggedUser.email
        );
        swal(res, {
          icon: "success",
        }).then(() => {
          this.handleClose();
        });
      } else {
        swal({
          title: "Error",
          text: res,
          icon: "error",
        });
      }
    }
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Button
          variant="outlined"
          className="community-form-button"
          onClick={this.handleClickOpen}
          fullWidth
        >
          Representative Application
        </Button>
        <Dialog
          className="popup-dialog"
          open={open}
          maxWidth="md"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          PaperProps={{
            style: { borderRadius: 25 },
          }}
        >
          <Box className="yellow-border" borderRadius={25}>
            <DialogContent className="p-5">
              <Grid container spacing={2}>
                <Grid item xs={12} key={"Name row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Name: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        onChange={this.onTextFieldChange}
                        name="name"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"Email row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Email: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        onChange={this.onTextFieldChange}
                        name="email"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"Telephone row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Telephone: </b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        type="number"
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        onChange={this.onTextFieldChange}
                        name="telephone"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"Affiliation row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Affiliation: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        onChange={this.onTextFieldChange}
                        name="affiliation"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"City row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>City: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        onChange={this.onTextFieldChange}
                        name="city"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"Country row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Country: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <CustomCountrySelect
                        onChange={this.onCountryChange}
                        value={this.state.form.country}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} key={"Biography row"}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <span className="community-popup-form-label">
                        <b>Biography: (*)</b>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <RoundTextField
                        baseBorderColor={"rgba(158,146,50,0.2)"}
                        hoverBorderColor={"rgba(158,146,50,0.2)"}
                        focusedBorderColor={"rgba(158,146,50,0.2)"}
                        backgroundColor={"rgba(158,146,50,0.2)"}
                        borderRadius="20px"
                        rows={4}
                        height="110px"
                        multiline
                        onChange={this.onTextFieldChange}
                        name="biography"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br></br>
              <Grid container justify="center">
                <Button
                  onClick={this.onSubmitClick}
                  className="community-popup-form-submit"
                >
                  Submit
                </Button>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default RepresentativeForm;
