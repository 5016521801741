import React from "react";
import queryString from "query-string";
import axios from "axios";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import ResultItem from "./Custom Components/ResultItem";
import moment from "moment";
import config from "../config";
import { postLog } from "../js/httpRequests";
const { extractContent } = require("../js/auxFunctions");

let latestType = "news";

export default class Results extends React.Component {
  state = {
    isLoading: true,
    text: "",
    news: [],
    researchers: [],
    events: [],
    partners: [],
    keynotesInterviews: [],
    partnerTypes: [],
    all: [],
    total: 0,
  };

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    document.title = "Results for " + params["text"];

    let resultsReq = await axios.post("/api/search?limit=100", {
      query: {
        $text: { $search: params["text"], $caseSensitive: false },
      },
    });
    let results = resultsReq.data.results;
    results["events"] = results["events"].map((obj) => ({
      ...obj,
      entityType: "Events",
    }));
    results["news"] = results["news"].map((obj) => ({
      ...obj,
      entityType: "News",
    }));
    results["partners"] = results["partners"].map((obj) => ({
      ...obj,
      entityType: "Partners",
    }));
    results["keynotesInterviews"] = results["keynotesInterviews"].map(
      (obj) => ({
        ...obj,
        entityType: "Keynotes/Interviews",
      })
    );
    results["researchers"] = results["researchers"].map((obj) => ({
      ...obj,
      entityType: "Researchers",
    }));

    this.logSearch(params["text"]);

    this.setState({
      news: results["news"],
      events: results["events"],
      partners: results["partners"],
      keynotesInterviews: results["keynotesInterviews"],
      researchers: results["researchers"],
      all: results["events"].concat(
        results["news"],
        results["partners"],
        results["keynotesInterviews"],
        results["researchers"]
      ),
      text: params["text"],
      total: resultsReq.data.total,
      isLoading: false,
    });

    let partnerTypesReq = await axios.post("/api/partners/types/find");
    this.setState({ partnerTypes: partnerTypesReq.data });
  }

  componentDidUpdate() {
    document.title = "Results for " + this.state.text;
  }

  logSearch = async (text) => {
    let userReq = await axios.get("/api/login/user");
    let user = userReq.data;
    postLog(
      "Search",
      "User searched for '" + text + "'",
      user.firstName + " " + user.lastName,
      user.email
    );
  };

  renderItem = (x) => {
    switch (x.entityType) {
      case "News":
        return (
          <Grid item xs={12}>
            <ResultItem
              type={x.entityType}
              href={config.newsBasePath + "/" + x._id}
              photo={config.newsImageBasePath + x.mainPhoto}
              title={x.title}
              subtitle={
                x.publishedDate["date"]
                  ? "Posted on " +
                    moment(x.publishedDate["date"]).format("DD/MM/YYYY")
                  : ""
              }
              description={
                <div className="result-description">
                  {!extractContent(x.highlightedText).endsWith(".") &&
                  extractContent(x.highlightedText) !== ""
                    ? extractContent(x.highlightedText) + "(...)"
                    : extractContent(x.highlightedText)}
                </div>
              }
            />
          </Grid>
        );
      case "Events":
        return (
          <Grid item xs={12}>
            <ResultItem
              type={x.entityType}
              href={config.eventsBasePath + "/" + x._id}
              photo={config.eventsImageBasePath + x.mainPhoto}
              title={x.name}
              subtitle={
                x.title +
                " - " +
                moment(x.startDate).format("DD") +
                "-" +
                moment(x.endDate).format("DD MMMM, YYYY")
              }
              description={
                <div className="result-description">
                  {!extractContent(x.highlightedText).endsWith(".") &&
                  extractContent(x.highlightedText) !== ""
                    ? extractContent(x.highlightedText) + "(...)"
                    : extractContent(x.highlightedText)}
                </div>
              }
            />
          </Grid>
        );
      case "Researchers":
        return (
          <Grid item xs={12}>
            <ResultItem
              width="40%"
              maxHeight="150px"
              type={x.entityType}
              href={config.researchersBasePath + "/" + x._id}
              photo={config.researchersImageBasePath + x.mainPhoto}
              title={
                x.title +
                " " +
                x.firstName +
                " " +
                x.middleName +
                " " +
                x.lastName
              }
              subtitle={x.affiliation}
              description={
                <div className="result-description">
                  {!extractContent(x.highlightedText).endsWith(".") &&
                  extractContent(x.highlightedText) !== ""
                    ? extractContent(x.highlightedText) + "(...)"
                    : extractContent(x.highlightedText)}
                </div>
              }
            />
          </Grid>
        );
      case "Keynotes/Interviews":
        return (
          <Grid item xs={12}>
            <ResultItem
              type={x.entityType}
              href={config.keynotesInterviewsBasePath + "/" + x._id}
              video={x.mainVideo}
              title={
                (x.type === "Keynotes" || x.type === ""
                  ? "Keynote by "
                  : "Interview with ") +
                x.personTitle +
                " " +
                x.firstName +
                " " +
                x.middleName +
                " " +
                x.lastName
              }
              subtitle={x.title}
              description={
                <div className="result-description">
                  {!extractContent(x.highlightedText).endsWith(".") &&
                  extractContent(x.highlightedText) !== ""
                    ? extractContent(x.highlightedText) + "(...)"
                    : extractContent(x.highlightedText)}
                </div>
              }
            />
          </Grid>
        );
      case "Partners":
        return (
          <Grid item xs={12}>
            <ResultItem
              width="40%"
              maxHeight="100px"
              type={x.entityType}
              href={config.partnersBasePath + "/" + x._id}
              photo={config.partnersImageBasePath + x.mainPhoto}
              title={x.name}
              subtitle={
                this.state.partnerTypes.length > 0
                  ? this.state.partnerTypes.filter(
                      (partnerType) => partnerType._id === x.type
                    )[0]["name"]
                  : ""
              }
              description={
                <div className="result-description">
                  {!extractContent(x.highlightedText).endsWith(".") &&
                  extractContent(x.highlightedText) !== ""
                    ? extractContent(x.highlightedText) + "(...)"
                    : extractContent(x.highlightedText)}
                </div>
              }
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  renderItems = () => {
    return this.state.all.map((x) => {
      if (latestType !== x.entityType) {
        latestType = x.entityType;
        return (
          <Grid item className="pt-5">
            <h2>{x.entityType}</h2>
            <hr></hr>
            {this.renderItem(x)}
          </Grid>
        );
      } else return this.renderItem(x);
    });
  };

  render() {
    const { isLoading, all, text, total } = this.state;
    return (
      <div className="content">
        {isLoading ? (
          <div style={{ width: "100%", height: "500px" }}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <CircularProgress />
            </Grid>
          </div>
        ) : (
          <Box p={5}>
            <h1>
              {total} results for "{text}". Presenting the first 100.
            </h1>
            <br></br>
            <Grid container direction="column" spacing={1}>
              {this.renderItems(all)}
            </Grid>
          </Box>
        )}
      </div>
    );
  }
}
