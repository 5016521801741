import { Box, Grid, InputAdornment } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import PartnerCard from "./PartnerCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import queryString from "query-string";
import SearchIcon from "@material-ui/icons/Search";
import RoundTextField from "../Custom Components/RoundTextField";

const itemsPerPage = 60;

class PartnersArchive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
      partners: [],
      isLoading: true,
      title: "",
      searchInput: "",
    };
  }

  handlePaginationChange = (event, value) => {
    const diff = value - this.state.page;
    this.setState({
      page: value,
      startIndex: this.state.startIndex + diff * itemsPerPage,
      endIndex: this.state.endIndex + diff * itemsPerPage,
    });
  };

  resetNavigation = () => {
    this.setState({
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.toLowerCase(),
    });
    this.resetNavigation();
  };

  async componentDidMount() {
    let params = this.props.location
      ? queryString.parse(this.props.location.search)
      : "";
    const res = params.type
      ? await axios.get("/api/partners/byType/" + params.type)
      : await axios.post("/api/partners/find", {
          query: {
            status: "Published",
          },
          sort: {
            title: 1,
          },
        });

    this.setState({
      partners: res.data || [],
      isLoading: false,
      title: params.type ? params.type + "s" : "",
    });
  }

  render() {
    const { partners, isLoading } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PartnersArchive/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        />
        <Box className="main-box">
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <h3 className="title gold paragraph">{this.state.title}</h3>
              <div className="pt-3 pb-5">
                <RoundTextField
                  placeholder="Search"
                  placeholderColor="#A9ABAD"
                  placeholderFontSize="22px"
                  height="60px"
                  borderRadius="20px"
                  baseBorderColor="#ACA563"
                  hoverBorderColor="#ACA563"
                  focusedBorderColor="#ACA563"
                  onChange={this.onTextFieldChange}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      style={{ margin: "0 30px", color: "#A9ABAD" }}
                    >
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  }
                  name="searchInput"
                />
              </div>
              <Grid container component={Box} spacing={5}>
                {partners
                  .filter((item) => {
                    return item.name
                      .toLowerCase()
                      .includes(this.state.searchInput);
                  })
                  .map((partner, index) => {
                    if (
                      index >= this.state.startIndex &&
                      index <= this.state.endIndex
                    )
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <PartnerCard
                            partner={partner}
                            showDescription={true}
                            titleHeight="50px"
                          />
                        </Grid>
                      );
                    return null;
                  })}
                {partners.length <= 0 && (
                  <h3 className="text-align-center">No partners avaialable</h3>
                )}
              </Grid>
            </div>
          )}
          <Grid container justify="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              style={{ padding: "50px" }}
              count={Math.ceil(
                partners.filter((item) => {
                  return item.name
                    .toLowerCase()
                    .includes(this.state.searchInput);
                }).length / itemsPerPage
              )}
              page={this.state.page}
              onChange={this.handlePaginationChange}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default PartnersArchive;
