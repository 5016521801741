import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";
import { LinearProgress } from "@material-ui/core";

const columns = [
  {
    title: "First Name",
    field: "firstName",
    searchable: false
  },
  { title: "Last Name", field: "lastName", searchable: false },
  { title: "Email", field: "email", searchable: false },
  { title: "Country", field: "country", searchable: false },
  {
    title: "Start Date",
    field: "startDate",
    searchable: false,
    render(rowData) {
      return rowData.startDate !== "loading" ? (
        rowData.startDate
      ) : (
        <LinearProgress />
      );
    },
  },
  {
    title: "End Date",
    field: "endDate",
    searchable: false,
    render(rowData) {
      return rowData.endDate !== "loading" ? (
        rowData.endDate
      ) : (
        <LinearProgress />
      );
    },
  },
  { title: "Type", field: "membershipType", searchable: false },
  {
    title: "Status",
    field: "status",
    searchable: false,
    render(rowData) {
      return rowData.status !== "loading" ? (
        rowData.status ? (
          "Active"
        ) : (
          "Inactive"
        )
      ) : (
        <LinearProgress />
      );
    },
  },
];

const limit = 100;

async function getUsersMemberships(users, queryString) {
  const memberships = await (
    await axios.post("/api/memberships/find", {
      query: {
        idUser: { $in: users.map((x) => x._id) },
      },
    })
  ).data;

  //if (queryString) await getMemberships(users, memberships, queryString);

  const membershipClassifications = await (
    await axios.get("/api/memberships/classifications")
  ).data;
  let customData = [];
  users.map((user) => {
    let userMembership = memberships.filter((x) => x.idUser === user._id)[0];
    customData.push({
      id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      country: user.country,
      startDate: userMembership
        ? moment(userMembership.startDate).format("MM/YYYY")
        : "",
      endDate: userMembership
        ? moment(userMembership.endDate).format("MM/YYYY")
        : "",
      membershipType: userMembership
        ? membershipClassifications.filter(
          (x) => x.ID === userMembership.idMembershipClassification
        )[0].name
        : "",
      status: user.isActive,
    });
    return true;
  });

  return customData;
}

async function getUsers(limit, page, queryString) {
  let searchObj = {
    sort: {
      latestSession: -1,
    },
  };
  if (queryString)
    searchObj["query"] = {
      $text: {
        $search: queryString,
      },
    };
  const usersObj = await (
    await axios.post(
      `/api/users/find?fields=firstName,lastName,email,country,idPerson,isActive&limit=${limit}&offset=${limit * page
      }`,
      searchObj
    )
  ).data;

  var users = usersObj.users;
  var customData = [];
  users.map((user) => {
    customData.push({
      id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      country: user.country,
    });
    return true;
  });

  return { users, customData, total: usersObj.count };
}

class UsersManagementList extends Component {
  tableRef = React.createRef();

  state = {
    data: [],
    queryString: "",
    isLoading: true,
    page: 0,
    total: 0,
  };

  onSearchChange = async (value, page) => {
    this.setState({ queryString: value });
    let customData = [...this.state.data];
    let total = customData.length;
    if (value.length >= 3 || value.length === 0) {
      this.setState({ isLoading: true });
      const usersData = await getUsers(limit, page, value);
      let users = usersData.users;
      total = usersData.total;
      customData = await getUsersMemberships(users, value);
    }
    this.setState({ isLoading: false });
    return { data: customData, page: page, total: total };
  };

  render() {
    return (
      <div className="content">
        <div className="p-5">
          <MaterialTable
            isLoading={this.state.isLoading}
            title="Users Management"
            tableRef={this.tableRef}
            columns={columns}
            data={(query) =>
              new Promise(async (resolve, reject) => {
                if (query.search !== this.state.queryString) {
                  this.setState({ page: 0 });
                  query.page = 0;
                }
                let newData = await this.onSearchChange(
                  query.search,
                  query.page
                );
                resolve({
                  data: newData.data,
                  totalCount: newData.total,
                  page: newData.page,
                });
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add User",
                isFreeAction: true,
                onClick: (event) =>
                  (window.location.href = "/Backoffice/Users/Add"),
              },
              {
                icon: "settingsapplications",
                tooltip: "Manage User",
                onClick: (event, rowData) =>
                  (window.location.href = "/Backoffice/Users/" + rowData.id),
              },
            ]}
            onSearchChange={() => this.tableRef.current.onQueryChange}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              pageSizeOptions: [100],
              pageSize: 100
            }}
          />
        </div>
      </div>
    );
  }
}

export default UsersManagementList;
