
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const GrayCheckBox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: "gray",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GrayCheckBox;
