import React, { Component } from "react";
import { Box } from "@material-ui/core";

class PaperSubmission extends Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PaperSubmission/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Paper Submission</h3>
          <br></br>
          <p className="paragraph body-text">
            When preparing a paper for submission to an INSTICC event or an
            INSTICC publication, there are a few rules and guidelines that
            increase the chance of your publication and should therefore be
            followed closely:{"\n\n"}
            <ul>
              <li>
                Authors should submit an original paper in English, carefully
                checked for correct grammar and spelling, using the on-line
                submission procedure and respecting the limits demanded by the
                publication or event, otherwise the paper can be automatically
                rejected;
              </li>
              {"\n"}
              <li>
                A clear structure is valued by reviewers and increases the
                chances of your work being considered for publication. Each
                paper should clearly indicate the nature of its
                technical/scientific contribution, and the problems, domains or
                environments to which it is applicable;
              </li>
              {"\n"}
              <li>
                The guidelines for paper formatting provided at the conference's
                or publication's website should be used for all submitted
                papers. The preferred submission format is the camera-ready
                format. Please check and carefully follow the instructions and
                templates provided. LaTeX/PS/PDF/DOC/DOCX/RTF format are
                accepted;
              </li>
              {"\n"}
              <li>
                Papers that contain any form of plagiarism will be rejected
                without reviews. Please read INSTICC's ethical norms regarding
                plagiarism and self-plagiarism;
              </li>
              {"\n"}
              <li>
                To facilitate the "double-blind" paper evaluation method, the
                authors are kindly requested to produce and provide the paper,
                WITHOUT any reference to any of the authors. This means that it
                is necessary to remove the authors personal details, the
                acknowledgements section and any other reference that may
                disclose the authors identity.
              </li>
              {"\n"}
            </ul>
            To find out more about the types of papers accepted by each event or
            publication, read each individual Call for Papers attentively and
            visit the event's or publication's "Templates and Guidelines"
            section. You can also browse through the{" "}
            <a className="yellow-link" href="/Faqs" target="_blank">
              Frequently Asked Questions
            </a>
            .
          </p>
        </Box>
      </div>
    );
  }
}

export default PaperSubmission;
