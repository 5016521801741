import { Box, Grid } from "@material-ui/core";
import React from "react";
import KeynoteInterviewCard from "./KeynoteInterviewCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import MoreButton from "../../Custom Components/MoreButton";
import config from "../../../config";
import { getTopicName } from "../../../js/auxFunctions";
import queryString from "query-string";

class KeynoteInterviewDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      keynoteInterview: [],
      relatedKeynotesInterviews: [],
      topics: [],
    };
  }

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    const res = await axios.get(
      "/api/keynotesInterviews/" + this.props.match.params.id
    );
    const relatedRes = await axios.get(
      "/api/keynotesInterviews/" +
      this.props.match.params.id +
      "/related" +
      "?n=3" +
      (params.refID ? "&refID=" + params.refID : "")
    );
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    if(relatedRes.data){
      relatedRes.data.forEach((e) => {
      let topicsName = [];
      e.interestAreas.forEach((e) => {
        let foundTopics = topicsReq.data.find((t) => t.ID === e);
        if (foundTopics) {
          topicsName.push(foundTopics.name);
        }

      });
      e.topics = topicsName;
    });}

    this.setState({
      keynoteInterview: res.data,
      relatedKeynotesInterviews: relatedRes.data,
      topics: topicsReq.data,
      isLoading: false,
    });
    document.title = res.data.title;
  }

  render() {
    const {
      keynoteInterview,
      relatedKeynotesInterviews,
      isLoading,
      topics,
    } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div className="content">
          <Box className="main-box">
            <Grid container justify="center">
              <Grid item xs={12} md={10}>
                <iframe
                  frameBorder={0}
                  className="w-100"
                  src={keynoteInterview.mainVideo}
                  title="Keynote/Interview"
                  height="600"
                />
              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {keynoteInterview.firstName && (
                  <h3 className="title gold">
                    {keynoteInterview.type === "Keynotes" ||
                      keynoteInterview.type === ""
                      ? "Keynote by "
                      : "Interview with "}
                    {keynoteInterview.personTitle} {keynoteInterview.firstName}{" "}
                    {keynoteInterview.middleName} {keynoteInterview.lastName}
                  </h3>
                )}
                <br></br>
                <h5 className="kl-details-title">{keynoteInterview.title}</h5>
                {keynoteInterview.event.acronym && (
                  <h5 className="kl-details-location">
                    <i>
                      Presented at: {keynoteInterview.event.acronym}{" "}
                      {keynoteInterview.event.year} -{" "}
                      {keynoteInterview.event.name}.{" "}
                    </i>
                    {keynoteInterview.event.location.toLowerCase().includes("online streaming") ? "Online Streaming" : keynoteInterview.event.location}
                  
                  </h5>
                )}
                <br></br>
                <br></br>
                <div
                  dangerouslySetInnerHTML={{
                    __html: keynoteInterview.content,
                  }}
                />
                <br></br>
                <p className="kl-details-location">
                  <span className="kl-details-event">Topics:</span>{" "}
                  {keynoteInterview.interestAreas
                    .map((id) => {
                      return getTopicName(id, topics);
                    })
                    .join(", ")}
                </p>
              </Grid>
              <Grid item xs={12}>
                <h4 className="subtitle fw-bold text-align-center">
                  Related Keynotes & Interviews
                </h4>
                <br></br>
                <br></br>
                {isLoading ? (
                  <div className="center-box" style={{ height: "50vh" }}>
                    <CircularProgress />
                  </div>
                ) : (
                    <Grid container spacing={2}>
                      {relatedKeynotesInterviews.map((item, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <KeynoteInterviewCard
                              keynoteInterview={item}
                              showDescription={false}
                              refID={this.state.keynoteInterview._id}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                <Grid container justify="center">
                  <MoreButton
                    title="More Keynotes & Interviews"
                    href={config.keynotesInterviewsBasePath}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      );
  }
}

export default KeynoteInterviewDetails;
