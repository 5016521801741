import React, { Component } from "react";
import { Box } from "@material-ui/core";

class DigitalLibrary extends Component {
  state = {
    numberOfProceedings: 0,
    numberOfPapers: 0,
    numberOfAuthors: 0,
  };

  async componentDidMount() {
    await fetch("https://www.scitepress.org//api/proceedings/Counts")
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          numberOfProceedings: data.numberOfProceedings,
          numberOfPapers: data.numberOfPaper,
          numberOfAuthors: data.numberOfAuthors,
        })
      );
  }

  render() {
    const { numberOfProceedings, numberOfPapers, numberOfAuthors } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/DigitalLibrary/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Digital Library</h3>
          <br></br>
          <p className="body-text">
            SCITEPRESS Digital Library (Science and Technology Publications,
            Lda) is a privileged partner of INSTICC, who publishes all INSTICC
            conference proceedings. SCITEPRESS includes{" "}
            {numberOfProceedings
              ? numberOfProceedings
              : "«number of proceedings»"}{" "}
            proceedings,{" "}
            {numberOfPapers ? numberOfPapers : "«number of papers»"} papers and{" "}
            {numberOfAuthors ? numberOfAuthors : "«number of authors»"} authors.
            You can access SCITEPRESS Digital Library{" "}
            <a
              href="https://www.scitepress.org"
              target="_blank"
              rel="noopener noreferrer"
              className="yellow-text"
            >
              here
            </a>
            .
          </p>
          <br></br>
          <h4 className="subtitle">Benefits for Users</h4>
          <br></br>
          <p className="paragraph body-text">
            Access to the SCITEPRESS Digital Library is free for INSTICC members
            and allows visitors to browse through papers, conference proceedings
            and books. Here are some of the benefits of using the SCITEPRESS
            Digital Library:{"\n"}
          </p>
          <ul>
            <li>Over 41900 full text papers;</li>
            <li>Seamless search function;</li>
            <li>Keyword Indexing to ease navigation;</li>
            <li>Registered Users can add papers to custom made lists;</li>
            <li>
              Free of charge access to full text papers, for INSTICC members;
            </li>
            <li>User friendly interface.</li>
          </ul>
          <br></br>
          <h4 className="subtitle">Benefits for Authors</h4>
          <br></br>
          <p className="paragraph body-text">
            As the web growingly becomes the main repository of academic
            research, being found online is essential for researchers. In order
            to make sure their work is found, read and quoted, the SCITEPRESS
            Digital Library offers several advantages to authors:
            {"\n"}
          </p>
          <ul>
            <li>
              Keyword powered search and search engine optimization for every
              paper;
            </li>
            <li>
              Indexation of the papers in the Digital Library by several
              abstracting and indexing independent organizations;
            </li>
            <li>
              Possibility of adding links to the author’s other publications on
              the INSTICC authors' profile.
            </li>
          </ul>
          <br></br>
        </Box>
      </div>
    );
  }
}

export default DigitalLibrary;
