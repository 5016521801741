import React, { Component } from "react";
import NavigationBar from "../NavigationBar";
import Footer from "../Footer";
import { withRouter } from "react-router-dom";
import Slider from "../Slider";
import { Box, Grid, Grow } from "@material-ui/core";
import Information from "../Information";
import MultiSlider from "../MultiSlider";
import EventCard from "../Events/EventCard";
import axios from "axios";
import NewsCard from "../Community/News/NewsCard";
import ResearcherCard from "../Community/Researchers/ResearcherCard";
import KeynoteInterviewCard from "../Community/KeynotesInterviews/KeynoteInterviewCard";
import MoreButton from "../Custom Components/MoreButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";
import { isEntityInArray } from "../../js/auxFunctions";
import PriorityPartners from "../Custom Components/PriorityPartners";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const refreshTime = 6000;
const newsItems = 5;
const researcherItems = 8;
const KLItems = 8;

const customBPs = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1700 },
  },
});

function getNextIndex(type, n, arrLength) {
  if (type === "News") {
    switch (n) {
      case 1:
        return 4;
      case 4:
        return 0;
      case 0:
        return 3;
      case 3:
        return 2;
      default:
        return 1;
    }
  } else if (type === "KL") return n < arrLength - 1 && n < KLItems ? n + 1 : 0;
  else if (type === "Researcher") {
    return n < arrLength - 1 && n < researcherItems ? n + 1 : 0;
  }
}

let timer;

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      events: [],
      mainTopics: [],
      researchers: [],
      keyViews: [],
      partners: [],
      newsIsLoading: true,
      eventsIsLoading: true,
      researchersIsLoading: true,
      keyViewsIsLoading: true,
      partnersIsLoading: true,
      toggle: false,
      newsChangedIndex: -1,
      klChangedIndex: -1,
      resChangedIndex: -1,
      isLoginOpen: false,
    };
  }

  onLoginOpenClose = (value) => {
    this.setState({ isLoginOpen: value });
  };

  async componentDidMount() {
    const resEvents = await axios.get("/api/events/bySubmissionDate/2");
    let mainTopics = [];
    await fetch(
      "https://www.insticc.org/PrimorisWebServices/api/event/MainTopics"
    )
      .then((response) => response.json())
      .then((apiMainTopics) => (mainTopics = apiMainTopics))
      .catch(() => console.error("Failed to fetch API."));
    this.setState({
      events: resEvents.data || [],
      eventsIsLoading: false,
      mainTopics: mainTopics, //resMainTopics.data || [],
    });

    const resNews = await axios.get("/api/news/Random/" + newsItems * 2);
    this.setState({ news: resNews.data || [], newsIsLoading: false });

    const resResearchers = await axios.get(
      "/api/researchers/Random/" + researcherItems * 2
    );
    this.setState({
      researchers: resResearchers.data || [],
      researchersIsLoading: false,
    });

    const resKeyViews = await axios.get(
      "/api/keynotesInterviews/Random/" + KLItems * 2
    );
    this.setState({
      keyViews: resKeyViews.data || [],
      keyViewsIsLoading: false,
    });

    const resPartners = await axios.get("/api/partners/Random/10");
    this.setState({
      partners: resPartners.data || [],
      partnersIsLoading: false,
    });

    this.startTimer();
  }

  getUniqueNews = async (index) => {
    let newsObj = await (await axios.get("/api/news/Random/1")).data[0];

    while (isEntityInArray(newsObj, this.state.news))
      newsObj = await (await axios.get("/api/news/Random/1")).data[0];

    let newArray = [...this.state.news];
    newArray.push(newsObj);
    newArray[index] = newArray[newsItems];
    newArray.splice(newsItems, 1);

    return newArray;
  };

  getUniqueKL = async (index) => {
    let klObj = await (await axios.get("/api/keynotesInterviews/Random/1"))
      .data[0];

    while (isEntityInArray(klObj, this.state.keyViews))
      klObj = await (await axios.get("/api/keynotesInterviews/Random/1")).data[0];

    let newArray = [...this.state.keyViews];
    newArray.push(klObj);
    newArray[index] = newArray[KLItems];
    newArray.splice(KLItems, 1);

    return newArray;
  };

  getUniqueResearcher = async (index) => {
    let resObj = await (await axios.get("/api/researchers/Random/1")).data[0];

    while (isEntityInArray(resObj, this.state.researchers))
      resObj = await (await axios.get("/api/researchers/Random/1")).data[0];

    let newArray = [...this.state.researchers];
    newArray.push(resObj);
    newArray[index] = newArray[researcherItems];
    newArray.splice(researcherItems, 1);

    return newArray;
  };

  startTimer = () => {
    timer = setInterval(async () => {
      if (!this.state.isLoginOpen) {
        const newsIndex = getNextIndex("News", this.state.newsChangedIndex);
        // const klIndex = getNextIndex(
        //   "KL",
        //   this.state.klChangedIndex,
        //   this.state.keyViews.length
        // );
        const resIndex = getNextIndex(
          "Researcher",
          this.state.resChangedIndex,
          this.state.researchers.length
        );
        const newNewsArray = await this.getUniqueNews(newsIndex);
        //const newKeyViewsArray = await this.getUniqueKL(klIndex);
        const newResearchersArray = await this.getUniqueResearcher(resIndex);

        this.setState({
          newsChangedIndex: newsIndex,
          //klChangedIndex: klIndex,
          resChangedIndex: resIndex,
          toggle: true,
        });
        setTimeout(
          function () {
            this.setState({
              news: newNewsArray,
              //keyViews: newKeyViewsArray,
              researchers: newResearchersArray,
              toggle: false,
            });
          }.bind(this),
          500
        );
      }
    }, refreshTime);
  };

  stopTimer = () => {
    clearInterval(timer);
  };

  checkIfGrow = (index, stateIndex) => {
    const { toggle } = this.state;
    return stateIndex === index && toggle;
  };

  render() {
    const {
      events,
      news,
      researchers,
      keyViews,
      partners,
      eventsIsLoading,
      newsIsLoading,
      researchersIsLoading,
      keyViewsIsLoading,
      partnersIsLoading,
      mainTopics,
      newsChangedIndex,
      klChangedIndex,
      resChangedIndex,
    } = this.state;
    return (
      <div>
        <NavigationBar
          onOpen={this.stopTimer}
          onClose={this.startTimer}
          onLogOut={this.stopTimer}
        />
        <div className="content">
          <Slider boxShadow={5} withArrows withControls />
          <Box className="main-box" boxShadow={5}>
            <h2 className="homepage-title mb-5">Next Submission Deadlines</h2>
            {eventsIsLoading ? (
              <div className="center-box" style={{ height: "50vh" }}>
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={5}>
                {events.map((date, index) => (
                  <Grid key={"Event #" + index} item xs={12} md={6}>
                    <Grid container justify="center">
                      <EventCard
                        dateObject={date}
                        mainTopics={mainTopics}
                        elevation={3}
                        borderRadius={30}
                        cardClassName="homepage-event-card"
                        withCalendar
                      />
                    </Grid>
                  </Grid>
                ))}
                {events.length <= 0 && (
                  <h3 className="text-align-center">
                    No submission deadlines available
                  </h3>
                )}
              </Grid>
            )}
            <Grid container justify="center" component={Box} pt={5}>
              <MoreButton title="More Deadlines" href={config.eventsBasePath} />
            </Grid>
          </Box>
          <Box className="yellow-bg homepage-news-title-box">
            <h2 className="homepage-title">News</h2>
          </Box>
          {newsIsLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="yellow-bg">
              <div className="yellow-bg homepage-news-box">
                <MuiThemeProvider theme={customBPs}>
                  <Grid container spacing={3}>
                    <Grow in={!this.checkIfGrow(0, newsChangedIndex)}>
                      <Grid item xs={12} xl={6}>
                        <NewsCard showDescription={true} news={news[0]} textOnTop textHeight="25%" />
                      </Grid>
                    </Grow>
                    <Grid item xs={12} xl={6}>
                      <Grid container spacing={3}>
                        {news
                          .filter((item, index) => index !== 0)
                          .map((item, index) => {
                            if (index < newsItems - 1)
                              return (
                                <Grow
                                  key={"News Grow #" + index}
                                  in={
                                    !this.checkIfGrow(
                                      index + 1,
                                      newsChangedIndex
                                    )
                                  }
                                >
                                  <Grid
                                    key={"News #" + index}
                                    item
                                    xs={12}
                                    md={6}
                                  >
                                    <NewsCard showDescription={true} news={item} textOnTop />
                                  </Grid>
                                </Grow>
                              );
                            else {
                              return (
                                <Grid
                                  key={"News #" + index}
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{ display: "none" }}
                                >
                                  <NewsCard showDescription={true} news={item} textOnTop />
                                </Grid>
                              );
                            }
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </MuiThemeProvider>
                {news.length <= 0 && (
                  <h3 className="text-align-center">No news avaialable</h3>
                )}
              </div>
            </div>
          )}
          <Box className="yellow-bg pb-5">
            <Grid container justify="center" component={Box} pt={5}>
              <MoreButton title="More News" href={config.newsBasePath} />
            </Grid>
          </Box>
          <Box className="main-box" boxShadow={5}>
            <h2 className="homepage-title mb-5">Keynotes and Interviews</h2>
            {keyViewsIsLoading ? (
              <div className="center-box" style={{ height: "50vh" }}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  {keyViews.map((item, index) => {
                    if (index < KLItems)
                      return (
                        <Grow in={!this.checkIfGrow(index, klChangedIndex)}>
                          <Grid
                            key={"Keynote/Interview #" + index}
                            item
                            xs={12}
                            sm={6}
                            md={3}
                          >
                            <KeynoteInterviewCard
                              keynoteInterview={item}
                              showDescription={true}
                              minTitleHeight="50px"
                            />
                          </Grid>
                        </Grow>
                      );
                    else
                      return (
                        <Grid
                          key={"Keynote/Interview #" + index}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          style={{ display: "none" }}
                        >
                          <KeynoteInterviewCard
                            keynoteInterview={item}
                            showDescription={true}
                          />
                        </Grid>
                      );
                  })}
                  {keyViews.length <= 0 && (
                    <h3 className="text-align-center">
                      No keynotes/interviews avaialable
                    </h3>
                  )}
                </Grid>
              </div>
            )}
            <Grid container justify="center" component={Box} pt={3}>
              <MoreButton
                title="More Keynotes & Interviews"
                href={config.keynotesInterviewsBasePath}
              />
            </Grid>
          </Box>
          <Box className="main-box lightgray-bg" boxShadow={3}>
            <h2 className="homepage-title mb-5">Distinguished Researchers</h2>
            {researchersIsLoading ? (
              <div className="center-box" style={{ height: "50vh" }}>
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={4}>
                {researchers.map((item, index) => {
                  if (index < researcherItems)
                    return (
                      <Grow
                        key={"Researchers Grow #" + index}
                        in={!this.checkIfGrow(index, resChangedIndex)}
                      >
                        <Grid
                          key={"Researcher #" + index}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                        >
                          <ResearcherCard
                            researcher={item}
                            showDescription={true}
                            imageXS={12}
                            titleHeight="50px"
                            titleClassName="mt-3"
                            imageHeight="200px"
                            imageWidth="200px"
                            borderRadius="100px"
                          />
                        </Grid>
                      </Grow>
                    );
                  else
                    return (
                      <Grid
                        key={"Researcher #" + index}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ display: "none" }}
                      >
                        <ResearcherCard
                          researcher={item}
                          showDescription={true}
                        />
                      </Grid>
                    );
                })}
                {researchers.length <= 0 && (
                  <h3 className="text-align-center">
                    No researchers avaialable
                  </h3>
                )}
              </Grid>
            )}
            <Grid container justify="center" component={Box} pt={5}>
              <MoreButton
                title="More Researchers"
                href={config.researchersBasePath}
              />
            </Grid>
          </Box>
          <Box className="main-box">
            <h2 className="homepage-title">Partners</h2>
            <PriorityPartners />
            {partnersIsLoading ? (
              <div className="center-box" style={{ height: "50vh" }}>
                <CircularProgress />
              </div>
            ) : (
              <MultiSlider
                items={partners}
                autoPlay
                arrows={false}
                borderColor="transparent"
                imageBaseRef={config.partnersImageBasePath}
                baseRef={config.partnersBasePath}
              />
            )}
          </Box>
          <Information />
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Homepage);
