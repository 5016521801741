import { Button } from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import React from "react";

class MoreButton extends React.Component {
  render() {
    return (
      <Button
        variant="contained"
        size="large"
        disableElevation
        style={{ backgroundColor: "transparent" }}
        href={this.props.href}
        className="more-button"
        startIcon={
          <AddCircleOutlinedIcon style={{ fontSize: 30, color: "silver" }} />
        }
      >
        <span style={{ color: "#626366" }}>{this.props.title}</span>
      </Button>
    );
  }
}

export default MoreButton;
