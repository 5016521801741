import { Box } from "@material-ui/core";
import React from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";

class AsNonSpeaker extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/AsNonSpeaker/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">As a Non-Speaker</h3>
          <br></br>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="Benefits of Attending an INSTICC Event as a
              Non-Speaker:"
            content={
              <div>
                <ul>
                  <li>
                    Access to all event sessions and keynote lectures,
                    discussion panels and paper presentations providing an
                    overview of the state of the art of the event's areas of
                    interest and of all major research questions in the field;
                  </li>
                  <br></br>
                  <li>
                    All attendees receive event materials, including the Book of
                    Abstracts and a digital copy of the Proceedings;
                  </li>
                  <br></br>
                  <li>
                    Possibility of active participation in the sessions,
                    engaging in discussion and debate, about future research
                    ventures and work progression with colleagues and of
                    exchanging ideas with high-profile researchers and
                    scientists;
                  </li>
                  <br></br>
                  <li>
                    Becoming familiar with the work of other universities and
                    research groups, their projects and possible career or
                    cooperation opportunities;
                  </li>
                  <br></br>
                  <li>
                    Ample networking opportunities through a wide range of
                    social occasions;
                  </li>
                  <br></br>
                  <li>
                    Ph.D. and M.Sc. Students benefit from substantial discounts
                    on the event registration fee;
                  </li>
                  <br></br>
                  <li>
                    Researchers coming from regions of the world that have
                    stringent financial constraints can apply to obtain grants
                    that facilitate their access to the conference.
                  </li>
                </ul>
              </div>
            }
          />
        </Box>
      </div>
    );
  }
}

export default AsNonSpeaker;
