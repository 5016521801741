import {
  Box,
  Card,
  Collapse,
  FormControlLabel,
  Grid,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import GrayCheckBox from "../Custom Components/GrayCheckBox";
import InnerAccordionToggleButton from "../Custom Components/InnerAccordionToggleButton";

class IndividualInterestArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areChildrenVisible: false,
      childCheckboxClass: "hide-box",
    };
  }

  boxClick = () => {
    if (this.state.childCheckboxClass === "hide-box") {
      this.setState({
        childCheckboxClass: "show-box",
      });
    } else {
      this.setState({
        childCheckboxClass: "hide-box",
      });
    }
    this.setState({ areChildrenVisible: !this.state.areChildrenVisible });
  };

  render() {
    const { interestArea } = this.props;
    return (
      <div>
        <Card component={Box} borderRadius={50}>
          <Box
            border={1}
            borderRadius={50}
            pt={1}
            pl={5}
            className="lightgray-bg"
            borderColor="darkgray"
          >
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <FormControlLabel
                  control={
                    <GrayCheckBox
                      checked={this.props.selectedAreas.includes(
                        interestArea["ID"].toString()
                      )}
                      onMouseDown={(event) => event.stopPropagation()}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={this.props.onCheckBoxChange}
                      name={interestArea["ID"]}
                    />
                  }
                  label={interestArea["name"]}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid container justify="flex-end">
                  <Tooltip title="Expand/Collapse Subtopics">
                    <InnerAccordionToggleButton onClickEvent={this.boxClick} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Collapse in={this.state.areChildrenVisible}>
          <Box pl={10} pt={3}>
            <Grid container>
              {interestArea.children.map((child, index) => {
                return (
                  <Grid item xs={12} key={child["ID"]}>
                    <FormControlLabel
                      control={
                        <GrayCheckBox
                          component={Box}
                          pb={1}
                          checked={this.props.selectedAreas.includes(
                            child["ID"].toString()
                          )}
                          onChange={this.props.onCheckBoxChange}
                          name={child["ID"]}
                        />
                      }
                      label={child["name"]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Collapse>
      </div>
    );
  }
}

export default IndividualInterestArea;
