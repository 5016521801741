import { Grid } from "@material-ui/core";
import React from "react";

class DepartmentCard extends React.Component {
  render() {
    return (
      <Grid
        container
        style={{ backgroundColor: "white", color: "black" }}
        className="pt-1"
        spacing={1}
      >
        {!this.props.hideImage && (
          <Grid item xs={12}>
            <img
              src={this.props.department.image}
              className="round-image mb-3 ml-5"
              alt={this.props.department.title}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <p className="title gold">{this.props.department.title}</p>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1} className="mr-3">
              <img src="/email-icon.png" className="d-block" alt="Email" />
            </Grid>
            <Grid item xs>
              <span className="body-text">{this.props.department.email}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1} className="mr-3">
              <img
                src="/telefone-icon.png"
                className="d-block"
                alt="Telephone"
              />
            </Grid>
            <Grid item xs>
              <span className="body-text">
                {this.props.department.telephone}
              </span>
              <br/>
              <span style={{fontSize: '12px'}}>(National fixed network call)</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default DepartmentCard;
