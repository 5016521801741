import { Grid } from "@material-ui/core";
import { Card, Accordion } from "react-bootstrap";
import React from "react";
import InnerAccordionToggleButton from "./InnerAccordionToggleButton";

class InnerAccordion extends React.Component {
  render() {
    const { defaultActiveKey, title, eventKey, isOpen, content } = this.props;
    return (
      <Accordion
        className="inner-accordion"
        defaultActiveKey={defaultActiveKey}
      >
        <Card>
          <Card.Header
            className="inner-accordion-title-background pt-2 pr-0"
            style={{ height: "85px" }}
          >
            <div>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className="pr-1"
              >
                <Grid item xs={11}>
                  <Grid container alignItems="center">
                    <h4 className="inner-accordion-title pr-4">
                      {title}
                    </h4>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify="flex-end">
                    <InnerAccordionToggleButton
                      eventKey={eventKey}
                      isOpen={isOpen}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Card.Header>
        </Card>
        <Accordion.Collapse eventKey={eventKey}>
          <div className="inner-accordion-content-card">
            <p className="paragraph body-text">{content}</p>
          </div>
        </Accordion.Collapse>
      </Accordion>
    );
  }
}

export default InnerAccordion;
