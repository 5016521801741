import React from "react";
import { Box, Grid } from "@material-ui/core";
import Map from "./Custom Components/Map";


class Information extends React.Component {
  render() {
    return (
      <Box className="footer-contacts-bg main-box" boxShadow={3}>
        <Grid container spacing={5} alignItems="center" justify="space-evenly">
          <Grid item xs={12} md={5} className="homepage-information-map1">
            <Map />
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} component={Box}>
                <Grid container>
                  <Grid item xs={11} className="information-grid-item">
                    <div className="information-box">
                      <h3 className="information-title">
                        <b>ADDRESS</b>
                      </h3>
                      <br></br>
                      <div className="information-contents">
                        <p className="paragraph body-text">
                          Headquarters{"\n"}
                          Rua dos Lusíadas, 118 - 3.Frt, 1300-376 Lisboa -
                          Portugal.{"\n\n"}
                          Office{"\n"}
                          Av. S. Francisco Xavier, Lt 7- Cv. C, 2900-616 Setúbal
                          - Portugal.
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <hr className="gray-vertical-hr" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} component={Box} pl={3}>
                <Grid container>
                  <Grid item xs={11} className="information-grid-item">
                    <div className="information-box">
                      <h3 className="information-title">
                        <b>CONTACTS</b>
                      </h3>
                      <br></br>
                      <div className="information-contents">
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <img
                                  src="email-icon.png"
                                  className="d-block"
                                  alt="Email"
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <span className="body-text">
                                  secretariat@insticc.org
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <img
                                  src="telefone-icon.png"
                                  className="d-block"
                                  alt="Telephone"
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <span className="body-text">
                                  +351 265 520 184/5  <span style={{fontSize: '12px'}}>(National fixed network call)</span>
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <img
                                  src="fax-icon.png"
                                  className="d-block"
                                  alt="Fax"
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <span className="body-text">
                                  +351 265 520 186
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="homepage-information-map2">
            <Map />
          </Grid>
        </Grid>
       
      </Box>
      
    );
  }
}

export default Information;
