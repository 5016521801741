import { Box } from "@material-ui/core";
import React from "react";

class MissionActivities extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/MissionAndActivities/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h4 className="yellow-text">
            <b>Mission and Activites</b>
          </h4>
          <br></br>
          <p className="paragraph">
            INSTICC, the Institute for Systems and Technologies of Information,
            Control and Communication, is a non-profit association for the
            development and dissemination of technical and scientific knowledge
            in the areas of information systems and technologies, control and
            communications.{"\n"}
            INSTICC was incorporated in 2003 and since then it has been involved
            in many projects, including:{"\n\n"}
            <ul>
              <li>The development of its membership base;</li>
              <li>
                The sponsorship and organization of conferences, workshops,
                tutorials and other scientific events;
              </li>
              <li>
                The promotion of mobility of renowned researchers, usually as
                invited speakers to INSTICC conferences;
              </li>
              <li>
                The support of many young researchers from all over the world,
                providing them grants to enable their presence in INSTICC
                conferences;
              </li>
              <li>
                The publication of proceedings, books of selected papers and
                journals - some of them in cooperation with international
                publishers;
              </li>
              <li>
                The sponsorship of research projects, proposed by Universities
                and R&D institutes, related to INSTICC main interest areas;
              </li>
              <li>
                The collaboration with International Associations and Companies
                involved in R&D or the supporting of the International Academic
                Community.
              </li>
            </ul>
            In addition to organizing conferences and workshops, INSTICC has
            developed several information systems using internal know-how on
            many software engineering areas, including database management
            systems, web programming, cloud systems, information visualization
            and project management, with examples available on the web such as
            the INSTICC portal itself (www.insticc.org), or PRIMORIS – a event
            management system that is used for the logistics support of all
            INSTICC conferences, congresses and workshops
            (www.insticc.org/primoris), as well as portals developed for partner
            companies such as SCITEPRESS
            (http://scitepress.org/DigitalLibrary/HomePage.aspx) who publishes
            online all INSTICC conference proceedings, and SCITEVENTS
            (http://www.scitevents.com/) who is involved in the logistics of
            INSTICC events.{"\n"}
            INSTICC has an international network of researchers, of more than
            one hundred thousand researchers, worldwide, involved in
            conferences, congresses and workshops, either as delegates, as
            authors, as reviewers, or as invited speakers.
          </p>
        </Box>
      </div>
    );
  }
}

export default MissionActivities;
