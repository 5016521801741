import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBNavLink,
  MDBNavbarBrand,
  MDBNavbar,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBNavbarToggler,
  MDBIcon,
} from "mdbreact";
import MainSearch from "./Custom Components/MainSearch";
import LoginSignUp from "./Account/LoginSignUp";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Axios from "axios";
import {
  buildTree,
  isLoggedIn,
  tree2NavigationJSON,
  getItemChildren,
} from "../js/auxFunctions";
import config from "../config";
import axios from "axios";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const increment = 15;

function renderNavbarColumn(item) {
  let children = [];
  getItemChildren(item, 0, increment, children);
  children.shift();

  let childrenDiv = [];

  children.forEach((x) => {
    childrenDiv.push(
      <MDBDropdownItem
        href={x.link}
        style={{ paddingLeft: x.variable + "px" }}
        key={x.name}
      >
        <b>{x.name}</b>
      </MDBDropdownItem>
    );
  });

  let mainDiv = !item.value.link ? (
    <MDBNavItem
      className="mr-2"
      style={{ width: "max-content" }}
      key={item.value.name}
    >
      <MDBDropdown>
        <MDBDropdownToggle nav className="black-text">
          <h6>
            <b>{item.value.name}</b>
          </h6>
        </MDBDropdownToggle>
        <MDBDropdownMenu>{childrenDiv}</MDBDropdownMenu>
      </MDBDropdown>
    </MDBNavItem>
  ) : (
    <MDBNavItem
      className="mr-4"
      style={{ width: "max-content" }}
      key={item.value.name}
    >
      <a href={item.value.link} className="navigation-bar">
        <h5>
          <b>{item.value.name}</b>
        </h5>
      </a>
    </MDBNavItem>
  );

  return mainDiv;
}

class NavigationBar extends Component {
  state = {
    isOpen: false,
    isLogged: localStorage.getItem("isLoggedIn"),
    user: { roles: [] },
    jsonTree: [],
    completed: false,
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  async componentDidMount() {
    let isLoggedLS = localStorage.getItem("isLoggedIn");
    if (isLoggedLS) {
      this.setState({ isLogged: true });
      let isLogged = await isLoggedIn();

      if (isLogged) {
        let user = await (await axios.get("/api/login/user")).data;
        this.setState({ user: user, isLogged: true });
      } else this.setState({ isLogged: false });
    }

    let jsonTree;
    if (!this.props.jsonTree) {
      const navigationData = await (
        await Axios.post("/api/navigations/find", {
          query: {
            type: !this.props.isBackoffice ? "frontoffice" : "backoffice",
          },
          sort: {
            ID: 1,
          },
        })
      ).data;
      const navigationItems = navigationData ? navigationData : [];

      let tree = buildTree(navigationItems);
      jsonTree = tree2NavigationJSON(tree);
    } else jsonTree = this.props.jsonTree;
    this.setState({ jsonTree: jsonTree });
  }

  componentDidUpdate() {
    if (this.props.jsonTree)
      if (this.props.jsonTree.length > 0 && !this.state.completed)
        this.setState({ jsonTree: this.props.jsonTree, completed: true });
  }

  logOut = async () => {
    if (this.props.onLogOut) this.props.onLogOut();
    await Axios.delete("/api/login");
    localStorage.setItem("isLoggedIn", false);
    window.location.href = "/";
  };

  render() {
    const { jsonTree, isLogged, user } = this.state;
    return (
      <div>
        <MDBNavbar
          color="white"
          expand="xl"
          fixed="top"
          className="navigation-bar"
        >
          <MDBNavbarBrand className="mb-2">
            <MDBNavLink to="/">
              <img alt="Logo" src={"/insticc.png"} width="150" height="40" />
            </MDBNavLink>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            image="https://mdbootstrap.com/img/svg/hamburger3.svg?color=000000"
            onClick={this.toggleCollapse}
            style={{ color: "black" }}
          />
          <MDBNavbarNav right className="right-navbar-collapse">
            <Grid container justify="flex-end">
              {!isLogged ? (
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item>
                    <LoginSignUp
                      onLoginOpenClose={this.props.onLoginOpenClose}
                      noText
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Create an Account">
                      <Button
                        disableElevation
                        variant="contained"
                        size="small"
                        className="gray-login-button"
                        onClick={() => window.location.replace("/SignUp")}
                        startIcon={<PersonAddIcon className="dimgray-icon" />}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item className="pr-2">
                    <MainSearch />
                  </Grid>
                  <Grid item>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret className="yellow-text">
                        {user.photo ? (
                          <img
                            src={user.photo}
                            className="navbar-profile"
                            alt="Profile"
                          />
                        ) : (
                          <MDBIcon icon="user" className="yellow-text" />
                        )}
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default">
                        <div>{user.email}</div>
                        <MDBDropdownItem href="/MyAccount">
                          My Account
                        </MDBDropdownItem>
                        {user.roles.some((r) =>
                          ["Admin", "Manager"].includes(r)
                        ) ? (
                          <MDBDropdownItem href="/Backoffice">
                            Backoffice
                          </MDBDropdownItem>
                        ) : null}
                        <MDBDropdownItem href="#!" onClick={this.logOut}>
                          Log out
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </MDBNavbarNav>
          <MDBCollapse id="navbarCollapse1" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav
              left
              style={{ paddingRight: "10px" }}
              className="pt-2"
            >
              {jsonTree.map((x) => {
                return renderNavbarColumn(x);
              })}
            </MDBNavbarNav>
          </MDBCollapse>
          <Grid container justify="flex-end" className="right-navbar">
            {!isLogged ? (
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <LoginSignUp
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Create an Account">
                    <Button
                      disableElevation
                      variant="contained"
                      size="small"
                      className="gray-login-button"
                      onClick={() => window.location.replace("/SignUp")}
                      startIcon={<PersonAddIcon className="dimgray-icon" />}
                    >
                      <span className="dimgray-label">Sign Up</span>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <Grid container justify="flex-end" alignItems="center">
                <Grid item>
                  <div className="">
                    <MainSearch />
                  </div>
                </Grid>
                <Grid item>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className="yellow-text">
                      {user.photo ? (
                        <img
                          src={user.photo +  "?q=" + Date.now()}
                          className="navbar-profile"
                          alt="Profile"
                        />
                      ) : (
                        <MDBIcon icon="user" className="yellow-text" />
                      )}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-default">
                      <MDBDropdownItem disabled>{user.email}</MDBDropdownItem>
                      <MDBDropdownItem href="/MyAccount">
                        My account
                      </MDBDropdownItem>
                      {user.roles.some((r) =>
                        ["Admin", "Manager"].includes(r)
                      ) ? (
                        <MDBDropdownItem href="/Backoffice">
                          Backoffice
                        </MDBDropdownItem>
                      ) : null}
                      <MDBDropdownItem href="#!" onClick={this.logOut}>
                        Log out
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </Grid>
              </Grid>
            )}
          </Grid>
        </MDBNavbar>
      </div>
    );
  }
}

export default withRouter(NavigationBar);
