import { Box, Grid } from "@material-ui/core";
import React from "react";
import MultiSlider from "../MultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../config";
import MoreButton from "../Custom Components/MoreButton";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import { departments } from "../AboutUs/departmentsArray";

class IndustrialPartners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      partners: [],
    };
  }

  async componentDidMount() {
    const res = await axios.get("/api/partners/byType/Industrial Partner");
    this.setState({
      partners: res.data,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, partners } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/IndustrialPartners/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Industrial Partners</h3>
          <br></br>
          <br></br>
          <h4 className="subtitle">
            Partnership Opportunities for the Industry
          </h4>
          <br></br>
          <p className="paragraph body-text">
            Bringing together industry expertise and scientific research has
            always been key in the advancement of information and communication
            technologies. Through INSTICC, companies and corporations can
            contribute towards the advancement of scientific knowledge by
            sharing its innovations with international scientists, researchers
            and graduate students, thus gaining visibility in the scientific
            community and presenting itself as a possible partner for research
            ventures, a source of innovation or even a prospective employer.
          </p>
          <br></br>
          <h4 className="subtitle">INSTICC Events</h4>
          <br></br>
          <p className="paragraph body-text">
            Companies can benefit from INSTICC events by presenting their
            research and scientific results, be it by presenting a paper,
            hosting a tutorial or instructional course or demonstrating its
            products in demo sessions, by contributing towards panels and
            discussions in the event's field of interest or by presenting their
            organization, be it by setting up an exhibition booth, by
            advertising in the event's web presence or printed materials or by
            sponsoring keynote speakers, specific sessions or additional social
            events.
          </p>
          <br></br>
          <h4 className="subtitle">Contact</h4>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is open to partnership possibilities beyond the examples
            mentioned here and welcomes suggestions and cooperation proposals.
            Those interested in cooperating with INSTICC should contact our{" "}
            <HtmlTooltip
              department={departments[3]}
              text="Institutional Relations Office"
            />
            .
          </p>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <MultiSlider
              items={partners}
              autoPlay
              borderColor="transparent"
              arrows={false}
              baseRef={config.partnersBasePath}
              imageBaseRef={config.partnersImageBasePath}
            />
          )}
          <Grid container justify="center" className="pt-5">
            <MoreButton
              title="More Industrial Partners"
              href={config.partnersBasePath + "?type=Industrial Partner"}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default IndustrialPartners;
