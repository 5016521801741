import { Box, Button, Grid } from "@material-ui/core";
import React from "react";
import Map from "../Custom Components/Map";
import RoomIcon from "@material-ui/icons/Room";
import RepresentativeForm from "./RepresentativeForm";

//import Axios from "axios";

class ResearchCommunity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfCountries: "",
      numberOfAuthors: "",
    };
  }

  async componentDidMount() {
    await fetch(
      "https://www.insticc.org/PrimorisWebServicesDev/api/insticcportal/NumberOfAuthors"
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          numberOfAuthors: data.numberOfAuthors,
          numberOfCountries: data.numberOfCountries,
        })
      );
  }

  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/ResearchCommunity/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box" style={{ paddingBottom: "2%" }}>
          <h3 className="title gold">Research Community</h3>
          <br></br>
          <p className="text-align-justify body-text">
            INSTICC defines its mission as "to serve the international academic
            community and to promote the interaction of the academy with the
            active society, at a global scale, in fields related to engineering
            and technology, especially if involving informatics, control or
            communication". Knowledge dissemination is one of its main
            activities, through technical/scientific conferences and congresses,
            as well as publications. Many internationally distinguished
            researchers contribute either as authors, keynote speakers or
            reviewers at INSTICC technical/scientific events, and many
            universities, innovative companies and international associations
            maintain collaboration relationships with INSTICC. They are the
            INSTICC community.
          </p>
        </Box>
        <div className="community-map-bg" style={{ display: "none" }}>
          <Box className="main-box">
            <Grid container justify="center" alignItems="center" spacing={10}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="center">
                      <Box className="community-box-border" p={5}>
                        <p className="body-text">
                          Since the inception of INSTICC, in 2003, we had more
                          than {this.state.numberOfAuthors} authors, coming from{" "}
                          {this.state.numberOfCountries} countries, contributing
                          with high quality papers published and presented at
                          INSTICC conferences and congresses.
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="center">
                      <Button
                        size="large"
                        startIcon={
                          <RoomIcon className="community-authors-icon" />
                        }
                      >
                        <span className="dimgray-label">Authors</span>
                      </Button>
                      <Button
                        size="large"
                        startIcon={
                          <RoomIcon className="community-reviewers-icon" />
                        }
                      >
                        <span className="blue-label">Reviewers</span>
                      </Button>
                      <Button
                        size="large"
                        startIcon={
                          <RoomIcon className="community-keylecturers-icon" />
                        }
                      >
                        <span className="yellow-label">Keynote Lecturers</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Map />
              </Grid>
            </Grid>
          </Box>
        </div>
        <Box className="main-box" style={{ paddingTop: 0 }}>
          <Grid container justify="center" alignItems="center" spacing={10}>
            <Grid item xs={12} lg={6}>
              <p className="title gold">Country Chapters</p>
              <p className="text-align-justify body-text">
                INSTICC welcomes the proactive contribution of country
                representatives, who may be interested in promoting R&D
                activities in collaboration with INSTICC in their countries, in
                research areas related to INSTICC main areas of knowledge. Such
                roles may change on a yearly basis. Activities may involve
                organizing meetings with researchers, students and other
                academic professionals, helping dissemination of information
                about INSTICC activities, promoting R&D projects with the help
                of INSTICC, supporting students with grants for mobility, or
                developing the base of INSTICC associates in their respective
                country. INSTICC looks forward to reinforce collaboration links
                with the academic community in every country in the World.
              </p>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className="community-form" p={5}>
                <p className="community-form-text">
                  <b>
                    If you wish to apply for the role of country representative
                    please fill out the form. Country representatives are
                    entitled to various forms of INSTICC support for improving
                    R&D in their countries.
                  </b>
                </p>
                <br></br>
                <RepresentativeForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      
      </div>
    );
  }
}

export default ResearchCommunity;
