import { Box, Collapse, Grid } from "@material-ui/core";
import axios from "axios";
import React from "react";
import swal from "sweetalert";
import {
  passwordTester,
  passwordTesterHelperText,
} from "../../js/auxFunctions";
import { postLog, put, sendEmail } from "../../js/httpRequests";
import RoundTextField from "../Custom Components/RoundTextField";
import StandardButton from "../Custom Components/StandardButton";
import { CustomRadio } from "../Custom Components/StandardRadio";
import Tabs from "../Custom Components/Tabs";
import menuItems from "./MenuItems.json";

export default class Privacy extends React.Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    reason: "",
    uri: window.location.href.split("?")[0],
    isForgetMeOpen: false,
    loggedUser: {},
  };

  async componentDidMount() {
    let user = await (await axios.get("/api/login/user")).data;
    this.setState({ loggedUser: user });
  }

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  validate = () => {
    let temp = {};
    temp.oldPassword = this.state.oldPassword !== "";
    temp.newPassword = !passwordTester(this.state.newPassword);
    temp.confirmPassword =
      this.state.newPassword === this.state.confirmPassword;
    temp.areEqual = this.state.oldPassword !== this.state.newPassword;

    return Object.values(temp).every((x) => x === true);
  };

  onPasswordSubmit = async (event) => {
    event.preventDefault();
    // const doPasswordsMatch = await (
    //   await axios.post("/api/users/doPasswordsMatch", {
    //     oldPassword: this.state.oldPassword,
    //   })
    // ).data;
    // if (doPasswordsMatch) {
    put("", this.state, "users/password", () =>
      window.location.reload()
    );
    // } else {
    //   swal(
    //     "Password Mismatch",
    //     "The provided password doesn't match your current password",
    //     "error"
    //   );
    // }
  };

  onTerminateSubmit = async (event) => {
    swal({
      title: "Are you sure?",
      text:
        "You're about to terminate your account.\n\nThis implies that all personal data, such as name, email and preferences, will be deleted from the database. You will no longer be able to login using this account.\n\nNote that this is an irreversible operation. Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios.post("/api/users/endAccount", this.state).then(async () => {
          const { loggedUser } = this.state;
          //await sendEmail("Account Terminated", loggedUser);
          postLog(
            "Account Termination",
            "User has terminated his account",
            loggedUser.firstName + " " + loggedUser.lastName,
            loggedUser.email
          );
          localStorage.setItem("isLoggedIn", false);
          swal(
            "You have successfully terminated your account.\n\nYou'll be redirected to the homepage."
          ).then(() => (window.location.href = "/"));
        });
      }
    });
  };


  onSubmit = async (event) => {
    const userReq = await axios.get("/api/login/user");
    //let user = { UserID: userReq.data.idPerson };

 
    window.open('https://www.insticc.org/Primoris/api/person/GetDownloadDataExport?UserID=' + userReq.data.idPerson)

  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      reason,
      isForgetMeOpen,
      loggedUser,
    } = this.state;
    return (
      <div className="content">
        <Tabs
          items={menuItems}
          selected="Privacy & Password"
          className="pt-5 pl-5 pr-5"
        />
        <Box className="p-5 ml-5 mr-5">
          <h3 className="title gold">Change Password</h3>
          <p className="text-align-justify body-text">
            We encourage you to keep your data private. Please define a strong
            password and change it often.
          </p>
          <form>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={2}>
                <b className="body-text">Old Password:</b>
              </Grid>
              <Grid item xs={12} sm={10}>
                <RoundTextField
                  name="oldPassword"
                  backgroundColor="#ece9d6"
                  type="password"
                  error={!oldPassword}
                  helperText={!oldPassword ? "Required" : ""}
                  onChange={this.onEventChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <b className="body-text">New Password:</b>
              </Grid>
              <Grid item xs={12} sm={10}>
                <RoundTextField
                  name="newPassword"
                  backgroundColor="#ece9d6"
                  type="password"
                  error={
                    passwordTester(newPassword) || newPassword === oldPassword
                  }
                  helperText={
                    passwordTesterHelperText(newPassword) === ""
                      ? newPassword === oldPassword
                        ? "Passwords mustn't be equal"
                        : ""
                      : passwordTesterHelperText(newPassword)
                  }
                  onChange={this.onEventChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <b className="body-text">Confirm Password:</b>
              </Grid>
              <Grid item xs={12} sm={10}>
                <RoundTextField
                  type="password"
                  name="confirmPassword"
                  backgroundColor="#ece9d6"
                  error={newPassword !== confirmPassword && newPassword}
                  helperText={
                    newPassword !== confirmPassword && newPassword
                      ? "Passwords dont match"
                      : ""
                  }
                  onChange={this.onEventChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <StandardButton
                    type="submit"
                    variant="outlined"
                    disabled={!this.validate()}
                    onClick={this.onPasswordSubmit}
                  >
                    Save
                  </StandardButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <div className="pt-5">
            <h3 className="title gold">GPDR</h3>
            <p className="text-align-justify body-text">
              We abide by GDPR (see details{" "}
              <a
                href="/PrivacyPolicy"
                className="yellow-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              )
            </p>
            <br></br>
            <h3 className="title gold">Data Portability</h3>
            <p className="text-align-justify body-text">
              To ensure the portability of your information we provide all
              personal information we have about you in a pdf file. To obtain
              this file press the button below.
            </p>
            <Grid container justify="center" className="pt-2 pb-5">
              <StandardButton
                variant="outlined"
                onClick={this.onSubmit}
                cssClasses="mr-3"
              >
                Export
              </StandardButton>
            </Grid>
            <h3 className="title gold" style={{ color: "red" }}>
              Forget Me
            </h3>
            <p className="text-align-justify body-text">
              According to GDPR we offer all INSTICC users the possibility of
              asking to be forgotten. In that case, all information concerning
              the current user will be deleted from INSTICC database.
            </p>
            <Grid container justify="center" className="pt-2 pb-5">
              <StandardButton
                variant="outlined"
                onClick={() =>
                  this.setState({ isForgetMeOpen: !isForgetMeOpen })
                }
              >
                Forget Me
              </StandardButton>
            </Grid>
            <Collapse in={isForgetMeOpen}>
              <p className="text-align-justify body-text paragraph">
                Dear {loggedUser.firstName} {loggedUser.middleName}{" "}
                {loggedUser.lastName}
                {"\n\n"}We understand that you want this PRIMORIS account to be
                forgotten.{"\n\n"}If you continue, this account will be
                deactivated and all information that is currently accessible
                through this account will be lost, including papers, reviews,
                registrations, vouchers, proceedings and other data that you may
                have collected related to the events that you have participated
                or are currently involved in via PRIMORIS.{"\n\n"}For security
                reasons, to complete the process of full deactivation, an email
                will be sent to your current email address, asking you to
                confirm this irreversible action by clicking on a link to go to
                a page.{"\n\n"} Please, kindly indicate below the reason for
                your request to be forgotten:
              </p>
              <Grid container direction="column">
                <Grid item xs>
                  <Grid container alignItems="center">
                    <CustomRadio
                      checked={reason === "I do not work anymore in this area."}
                      onChange={this.onEventChange}
                      name="reason"
                      value="I do not work anymore in this area."
                    />
                    <b className="body-text">
                      I do not work anymore in this area.
                    </b>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container alignItems="center">
                    <CustomRadio
                      checked={reason === "Unpleased with the service."}
                      onChange={this.onEventChange}
                      name="reason"
                      value="Unpleased with the service."
                    />
                    <b className="body-text">Unpleased with the service.</b>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container alignItems="center">
                    <CustomRadio
                      checked={reason === "Other"}
                      onChange={this.onEventChange}
                      name="reason"
                      value="Other"
                    />
                    <b className="body-text">Other</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="center" className="pt-2 pb-5">
                <StandardButton
                  variant="outlined"
                  onClick={this.onTerminateSubmit}
                  disabled={reason === ""}
                >
                  Continue
                </StandardButton>
              </Grid>
            </Collapse>
          </div>
        </Box>
      </div>
    );
  }
}
