import { Button } from "@material-ui/core";
import React from "react";

export default function StandardButton(props) {
  return (
    <Button
      type={props.type}
      variant={props.variant}
      disabled={props.disabled}
      className={"standard-button " + props.cssClasses}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      {...props}
    >
      {props.children}
    </Button>
  );
}
