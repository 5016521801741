import { Box, Grid, InputAdornment } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import ResearcherCard from "./ResearcherCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ChipMultiSlider from "../../Custom Components/ChipMultiSlider";
import SearchIcon from "@material-ui/icons/Search";
import { hasFilteredTopics } from "../../../js/auxFunctions";
import RoundTextField from "../../Custom Components/RoundTextField";

const itemsPerPage = 100;

function nameContainsSearchInput(searchInput, researcher) {
  const { title, firstName, middleName, lastName } = researcher;
  const fullName =
    (title ? title + " " : "") +
    (firstName ? firstName + " " : "") +
    (middleName ? middleName + " " : "") +
    lastName;

  return fullName.toLowerCase().includes(searchInput.toLowerCase());
}

class ResearchersArquive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
      isLoading: true,
      researchers: [],
      filterOptions: [],
      selectedTopics: [],
      searchInput: "",
    };
  }

  handlePaginationChange = (event, value) => {
    const diff = value - this.state.page;
    this.setState({
      page: value,
      startIndex: this.state.startIndex + diff * itemsPerPage,
      endIndex: this.state.endIndex + diff * itemsPerPage,
    });
  };

  resetNavigation = () => {
    this.setState({
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  onChipClick = (value) => {
    let newSelected = [...this.state.selectedTopics];
    if (newSelected.includes(value)) newSelected = [];
    else {
      newSelected = [];
      newSelected.push(value);
    }

    this.setState({
      selectedTopics: newSelected,
    });

    this.resetNavigation();
  };

  onTextFieldChange = (event) => {

    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.resetNavigation();
  };

  async componentDidMount() {
    const researchersReq = await axios.post("/api/researchers/find", {
      query: {
        status: "Published",
      },
      sort: {
        hIndex: -1,
      },
    });
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    this.setState({
      researchers: researchersReq.data || [],
      filterOptions: topicsReq.data || [],
      isLoading: false,
    });
  }

  render() {
    const {
      researchers,
      isLoading,
      filterOptions,
      selectedTopics,
      searchInput,
    } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/Researchers/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        {!isLoading && (
          <div className="pt-5 pb-5">
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <RoundTextField
                placeholder="Search"
                placeholderColor="#A9ABAD"
                placeholderFontSize="22px"
                height="60px"
                borderRadius="20px"
                baseBorderColor="#ACA563"
                hoverBorderColor="#ACA563"
                focusedBorderColor="#ACA563"
                onChange={this.onTextFieldChange}
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ margin: "0 30px", color: "#A9ABAD" }}
                  >
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                }
                name="searchInput"
              />
            </div>
            <div className="pt-3">
              <ChipMultiSlider
                filterOptions={filterOptions}
                onChipClick={this.onChipClick}
              />
            </div>
          </div>
        )}
        <Box className="main-box" style={{ paddingTop: 0 }}>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Grid container component={Box} spacing={5}>
                {researchers
                  .filter(function (item) {
                    return (
                      hasFilteredTopics(selectedTopics, item.interestAreas) &&
                      nameContainsSearchInput(searchInput, item)
                    );
                  })
                  .map((researcher, index) => {
                    if (
                      index >= this.state.startIndex &&
                      index <= this.state.endIndex
                    )
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={3}
                          key={"Researcher #" + index}
                        >
                          <ResearcherCard
                            imageXS={12}
                            researcher={researcher}
                            showDescription={true}
                            imageWidth="100px"
                            imageHeight="100px"
                          />
                        </Grid>
                      );
                    return null;
                  })}
                {researchers.length <= 0 && (
                  <h3 className="text-align-center">
                    No researchers avaialable
                  </h3>
                )}
              </Grid>
            </div>
          )}
          <Grid container justify="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              style={{ padding: "50px" }}
              count={Math.ceil(
                researchers.filter((x) => {
                  return (
                    hasFilteredTopics(selectedTopics, x.interestAreas) &&
                    nameContainsSearchInput(searchInput, x)
                  );
                }).length / itemsPerPage
              )}
              page={this.state.page}
              onChange={this.handlePaginationChange}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default ResearchersArquive;
