import React from "react";
import { Box, Grid } from "@material-ui/core";
import RoundTextField from "../Custom Components/RoundTextField";
import StandardButton from "../Custom Components/StandardButton";
import { post, sendEmail } from "../../js/httpRequests";
import axios from "axios";

export default class ConfirmAccount extends React.Component {
  state = {
    isLoading: false,
    success: false,
    time: 5,
    text: "",
    pin: "",
    uri: window.location.href.split("?")[0],
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    post(
      this.state,
      "users/confirmAccount",
      () => {
        window.location.replace("/");
      },
      "",
      false,
      "warning",
      async (err) => {
        if (err.includes("Token Expired")) {
          let pinObj = await (
            await axios.get(`/api/user/pin/${this.state.pin}`)
          ).data;
          let user = await (await axios.get(`/api/users/${pinObj.idPerson}`)).data;
          let status = await sendEmail("Confirm Account", user);
          if (!status) alert("An error occurred while sending you an email");
        }
      }
    );
  };

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { pin } = this.state;
    return (
      <div className="content">
        <Box p={5}>
          <form className="p-5">
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item xs={1} className="text-align-right">
                <b>PIN</b>
              </Grid>
              <Grid item xs>
                <RoundTextField
                  type="number"
                  name="pin"
                  backgroundColor="#ece9d6"
                  error={pin === ""}
                  helperText={pin === "" ? "Required" : ""}
                  onChange={this.onEventChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" className="pt-5">
              <StandardButton
                type="submit"
                variant="outlined"
                disabled={pin === ""}
                value={pin}
                onClick={this.onSubmit}
              >
                Confirm Account
              </StandardButton>
            </Grid>
          </form>
        </Box>
      </div>
    );
  }
}
