import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./styles.scss";

// Page routes
import Homepage from "./components/Homepage/Homepage";
import ReasonsToPublish from "./components/Publications/ReasonsToPublish";
import AuthorResources from "./components/Publications/AuthorResources";
import PaperSubmission from "./components/Publications/PaperSubmission";
import PlagiarismPolicy from "./components/Publications/PlagiarismPolicy";
import Copyright from "./components/Publications/Copyright";
import Indexation from "./components/Publications/Indexation";
import PaperPresentation from "./components/Publications/PaperPresentation";
import EventStructure from "./components/Events/EventStructure";
import SubmissionDeadlines from "./components/Events/SubmissionDeadlines";
import AttendAnEvent from "./components/Events/AttendAnEvent";
import AsASpeaker from "./components/Events/AsASpeaker";
import AsNonSpeaker from "./components/Events/AsNonSpeaker";
import AsAnExhibitor from "./components/Events/AsAnExhibitor";
import ProposeAnEvent from "./components/Events/ProposeAnEvent";
import PartnershipOpportunities from "./components/Partnerships/PartnershipOpportunities";
import AcademicPartners from "./components/Partnerships/AcademicPartners";
import IndustrialPartners from "./components/Partnerships/IndustrialPartners";
import InstitutionalPartners from "./components/Partnerships/InstitutionalPartners";
import PublicationPartners from "./components/Partnerships/PublicationPartners";
import PartnersArchive from "./components/Partnerships/PartnersArchive";
import NewsManagement from "./components/Backoffice/News/NewsManagement";
import DigitalLibrary from "./components/Publications/DigitalLibrary";
import PartnerDetails from "./components/Partnerships/PartnerDetails";
import Primoris from "./components/Events/Primoris";
import MissionActivities from "./components/AboutUs/MissionActivities";
import Contacts from "./components/AboutUs/Contacts";
import ResearchCommunity from "./components/Community/ResearchCommunity";
import Membership from "./components/Community/Membership";
import NewsArchive from "./components/Community/News/NewsArchive";
import ResearchersArquive from "./components/Community/Researchers/ResearchersArquive";
import KeynotesInterviewsArchive from "./components/Community/KeynotesInterviews/KeynotesInterviewsArchive";
import MyAccount from "./components/Account/MyAccount";
import NewsDetails from "./components/Community/News/NewsDetails";
import ResearcherDetails from "./components/Community/Researchers/ResearcherDetails";
import KeynoteInterviewDetails from "./components/Community/KeynotesInterviews/KeynoteInterviewDetails";
import EventDetails from "./components/Events/EventDetails";
import PrivacyPolicy from "./components/AboutUs/PrivacyPolicy";
import SignUp from "./components/Account/SignUp";
import FAQs from "./components/FAQs";
import Page from "./components/Page";
import NewsPreview from "./components/Backoffice/News/NewsPreview";
import InterestAreas from "./components/Account/InterestAreas.jsx";
import NewsManagementList from "./components/Backoffice/News/NewsManagementList";
import MyMembership from "./components/Account/MyMembership";
import Results from "./components/Results";
import ConfirmAccount from "./components/Account/ConfirmAccount";
import SetNewPassword from "./components/Account/SetNewPassword";
import UsersManagementList from "./components/Backoffice/Users/UsersManagementList";
import UserCreation from "./components/Backoffice/Users/UserCreation";
import UserManagement from "./components/Backoffice/Users/UserManagement";
import BecomeHonorary from "./components/Account/BecomeHonorary";
import Privacy from "./components/Account/Privacy";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-47133194-8"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);


axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    //alert(error.response.data.message || error);

    if (error.response && error.response.status === 403) {
      alert(error.response.data || error);
      window.location.replace("/");
    }

    return error;
    //if (error.response.status === 403) window.location.replace("/");
  }
);
var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Page
        path="/ResearchCommunity"
        component={ResearchCommunity}
        title="Research Community"
      />
      <Page path="/Membership" component={Membership} title="Membership" />
      <Page
        path="/NewsArchive/:id"
        component={NewsDetails}
        title="News Details"
      />
      <Page path="/NewsArchive" component={NewsArchive} title="News Archive" />
      <Page
        path="/ResearchersArchive/:id"
        component={ResearcherDetails}
        title="Researcher Details"
      />
      <Page
        path="/ResearchersArchive"
        component={ResearchersArquive}
        title="Researchers Archive"
      />
      <Page
        path="/KeynotesInterviewsArchive/:id"
        component={KeynoteInterviewDetails}
        title="Keynote/Interview Details"
      />
      <Page
        path="/KeynotesInterviewsArchive"
        component={KeynotesInterviewsArchive}
        title="Keynotes/Interviews Archive"
      />
      <Page
        path="/ReasonsToPublish"
        component={ReasonsToPublish}
        title="Reasons to Publish"
      />
      <Page
        path="/DigitalLibrary"
        component={DigitalLibrary}
        title="Digital Library"
      />
      <Page
        path="/AuthorResources/PaperSubmission/"
        component={PaperSubmission}
        title="Author Resources - Paper Submission"
      />
      <Page
        path="/AuthorResources/PlagiarismPolicy/"
        component={PlagiarismPolicy}
        title="Author Resources - Plagiarism Policy"
      />
      <Page
        path="/AuthorResources/Copyright/"
        component={Copyright}
        title="Author Resources - Copyright"
      />
      <Page
        path="/AuthorResources/Indexation/"
        component={Indexation}
        title="Author Resources - Indexation"
      />
      <Page
        path="/AuthorResources/PaperPresentation/"
        component={PaperPresentation}
        title="Author Resources - Paper Presentation"
      />
      <Page
        path="/AuthorResources/"
        component={AuthorResources}
        title="Author Resources"
      />
      <Page
        path="/SubmissionDeadlines/:id"
        component={EventDetails}
        title="Event Details"
      />
      <Page
        path="/SubmissionDeadlines"
        component={SubmissionDeadlines}
        title="Submission Deadlines"
      />
      <Page
        path="/EventStructure/"
        component={EventStructure}
        title="Event Structure"
      />
      <Page
        path="/AttendAnEvent/AsASpeaker"
        component={AsASpeaker}
        title="Attend av Event - As a Speaker"
      />
      <Page
        path="/AttendAnEvent/AsNonSpeaker"
        component={AsNonSpeaker}
        title="Attend an Event - As a Non-Speaker"
      />
      <Page
        path="/AttendAnEvent/AsAnExhibitor"
        component={AsAnExhibitor}
        title="Attend an Event - As an Exhibitor"
      />
      <Page
        path="/AttendAnEvent/"
        component={AttendAnEvent}
        title="Attend an Event"
      />
      <Page
        path="/ProposeAnEvent"
        component={ProposeAnEvent}
        title="Propose an Event"
      />
      <Page
        path="/InformationSystem"
        component={Primoris}
        title="Information System"
      />
      <Page
        path="/PartnershipOpportunities"
        component={PartnershipOpportunities}
        title="Partnership Opportunities"
      />
      <Page
        path="/AcademicPartners"
        component={AcademicPartners}
        title="Academic Partners"
      />
      <Page
        path="/IndustrialPartners"
        component={IndustrialPartners}
        title="Industrial Partners"
      />
      <Page
        path="/InstitutionalPartners"
        component={InstitutionalPartners}
        title="Institutional Partners"
      />
      <Page
        path="/PublicationPartners"
        component={PublicationPartners}
        title="Publication Partners"
      />
      <Page
        path="/PartnersArchive/:id"
        component={PartnerDetails}
        title="Partner Details"
      />
      <Page
        path="/PartnersArchive"
        component={PartnersArchive}
        title="Partners Archive"
      />
      <Page
        path="/MissionAndActivities"
        component={MissionActivities}
        title="Mission and Activities"
      />
      <Page path="/Contacts" component={Contacts} title="Contacts" />
      <Page
        path="/PrivacyPolicy"
        component={PrivacyPolicy}
        title="Privacy Policy"
      />
      <Route path="/Backoffice/News/Preview" component={NewsPreview} />
      <Route path="/Backoffice/News/:id" component={NewsManagement} />
      <Route path="/Backoffice/News" component={NewsManagementList} />
      {/* <Page
        path="/Backoffice/Users/Add"
        component={UserCreation}
        isBackoffice
        noFooter
        title="Create User"
      />
      <Page
        path="/Backoffice/Users/:id"
        component={UserManagement}
        isBackoffice
        noFooter
        title="Manger User"
      /> */}
      {/* <Page
        path="/Backoffice/Users"
        component={UsersManagementList}
        isBackoffice
        noFooter
        title="Users Management"
      /> */}
      <Route path="/Backoffice" component={NewsManagementList} />
      <Route path="/SignUp" component={SignUp} />
      <Page path="/MyAccount/Privacy" component={Privacy} title="Privacy" />
      <Page
        path="/MyAccount/Membership"
        component={MyMembership}
        title="My Membership"
      />
      <Page
        path="/MyAccount/InterestAreas"
        component={InterestAreas}
        title="Interest Areas"
      />
      <Page path="/MyAccount" component={MyAccount} title="My Account" />
      <Page path="/Faqs" component={FAQs} title="FAQs" />{" "}
      <Page path="/Results" component={Results} title="Results" />
      <Page
        path="/ConfirmAccount"
        component={ConfirmAccount}
        title="Confirm Account"
      />
      <Page
        path="/BecomeHonorary"
        component={BecomeHonorary}
        title="Become Honorary"
      />
      <Page
        path="/NewPassword"
        component={SetNewPassword}
        title="Set new password"
      />
      <Route path="/" component={Homepage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
