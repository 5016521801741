const TreeNode = require("./TreeNode");

//root type Node
export default function TreeLinked(root) {
    this.root = new TreeNode(root, null);

    this.size = () => {
        return auxSize(this.root);
    }

    var auxSize = function (treeRoot) {
        if (treeRoot == null) return 0;

        let count = 1;
        for (let childTreeRoot of treeRoot.children) {
            count += auxSize(childTreeRoot);
        }

        return count;
    }

    this.isEmpty = () => {
        return this.root == null;
    }

    this.parent = (node) => {
        return node.parent;
    }

    this.children = (node) => {
        return node.children;
    }

    this.insert = (parentNode, elem) => {
        let node = new TreeNode(elem, parentNode);
        parentNode.children.push(node);

        return node;
    }

    this.remove = (node) => {
        node.parent.children = node.parent.children.filter((childNode) => {
            return childNode.value.compareByID(node.value.ID);
        })
    }

    // User BFS algorithm
    this.getNode = (ID) => {
        var queue = [];

        queue.push(this.root);

        let currentTree = queue.shift();

        while (currentTree) {
            for (var i = 0; i < currentTree.children.length; i++) {
                queue.push(currentTree.children[i]);
            }

            if (currentTree.value.compareByID(ID))
                return currentTree;

            currentTree = queue.shift();
        }

        return null;
    }

    var printLevel = function (level) {
        let str = "";
        for (let i = 0; i < level; i++) {
            str += " ";
        }

        return str + "-";
    }

    var toStringPreOrderLevels = function (node, level) {
        let str = node.value.name;
        for (let child of node.children) {
            str += "\n" + printLevel(level) + toStringPreOrderLevels(child, level + 1);
        }

        return str;
    }

    this.toString = () => {
        let str = "";
        if (!this.isEmpty()) {
            str = toStringPreOrderLevels(this.root, 1);
        }

        return str;
    }

    this.traverseBF = (callback) => {
        var queue = [];

        queue.push(this.root);

        let currentTree = queue.shift();

        while (currentTree) {
            for (var i = 0; i < currentTree.children.length; i++) {
                queue.push(currentTree.children[i]);
            }

            callback(currentTree);

            currentTree = queue.shift();
        }

        return null;
    }
}