import axios from "axios";
import React from "react";
import { Route } from "react-router-dom";
import { buildTree, tree2NavigationJSON } from "../js/auxFunctions";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

class Page extends Route {
  state = {
    jsonTree: [],
  };

  async componentDidMount() {
    document.title = this.props.title;

    const navigationItems = await (
      await axios.post("/api/navigations/find", {
        query: {
          type: this.props.isBackoffice ? "backoffice" : "frontoffice",
        },
        sort: {
          ID: 1,
        },
      })
    ).data;

    let tree = buildTree(navigationItems);
    let jsonTree = tree2NavigationJSON(tree);

    this.setState({ jsonTree: jsonTree });
  }

  componentDidUpdate() {
    document.title = this.props.title;
  }

  render() {
    const { title, noFooter, ...rest } = this.props;
    return (
     
      <div>
        <NavigationBar jsonTree={this.state.jsonTree} />
        <Route {...rest} />
        {noFooter ? null : <Footer jsonTree={this.state.jsonTree} />}
      
      </div>
    );
  }
}

export default Page;
