import { Box } from "@material-ui/core";
import axios from "axios";
import React from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";

class Membership extends React.Component {
  state = {
    isLoggedIn: localStorage.getItem("isLoggedIn"),
  };

  async componentDidMount() {
    let isLoggedIn = await (await axios.get("/api/login/isLoggedIn")).data;
    this.setState({ isLoggedIn: isLoggedIn });
  }

  render() {
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/Membership/original.png" alt="Banner" className="w-100"></img>
        <Box className="main-box">
          {!this.state.isLoggedIn ? (
            <div>
              <h3 className="title gold">How to become an INSTICC Member</h3>
              <p className="text-align-justify body-text paragraph">
                First, you need to get an account by signing up. Please use the
                button on the top right. If you already have an account, then
                just log in, please.{"\n"}
                Then, you can go to your user account (my account) and select
                the "Membership" tab and click on "join". For the moment it's
                free!
              </p>
              <br></br>
            </div>
          ) : null}
          <h3 className="title gold">Reasons to Join</h3>
          <p className="text-align-justify body-text">
            INSTICC Membership is a way to remain connected with the R&D
            community and to keep aware of new ideas, research projects and
            opportunities. INSTICC membership is open to all the community
            ranging from individuals, institutions, universities, libraries,
            research groups, companies and other organizations who contribute to
            knowledge advancement in the INSTICC fields of pursuit.
          </p>
          <br></br>
          <h4 className="subtitle">Improved Access to Publications</h4>
          <ul className="body-text">
            <li>
              INSTICC Members have free access to the{" "}
              <a
                href="http://www.insticc.org/portal/Publications/DigitalLibrary.aspx"
                className="yellow-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                SCITEPRESS Digital Library
              </a>{" "}
              including over 41900 papers by more than 89800 authors;
            </li>
            <li>
              INSTICC Members are welcome to contribute to INSTICC newsletter.
            </li>
          </ul>
          <br></br>
          <h4 className="subtitle">
            <b>Networking Opportunities</b>
          </h4>
          <div className="body-text">
            <ul>
              <li>
                INSTICC Members are encouraged to publish and present their
                research work in a variety of forms, from events such as
                conferences, congresses, special sessions, symposia, workshops
                and tutorials to publications such as the INSTICC newsletter and
                other media;
              </li>
              <li>
                INSTICC Members Benefit from special discounts at INSTICC
                sponsored events, including reduced registration fees;
              </li>
              <li>
                INSTICC has limited number of grants to offer to members who
                have economic difficulties to attend sponsored events. Every
                year, more than 100 grants are offered;
              </li>
              <li>
                INSTICC members furthermore stand to benefit from the variety of
                social networks in which INSTICC is represented, and which can
                be used to network informally with other members of the INSTICC
                community and the broader academic community.
              </li>
            </ul>
          </div>
          <br></br>
          <h3 className="subtitle">
            <b>Membership Categories</b>
          </h3>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="Regular Membership"
            content={
              <div className="body-text">
                For researchers, engineers, practitioners and academics, as well
                as industrial professionals. This membership type is the most
                common one.
              </div>
            }
          />
          <InnerAccordion
            eventKey="2"
            title="Student Membership"
            content={
              <div className="body-text">
                For individuals currently enrolled full-time in accredited
                undergraduate and graduate programs.
              </div>
            }
          />
          <InnerAccordion
            eventKey="3"
            title="Honorary Membership"
            content={
              <div className="body-text">
                This is conferred to INSTICC members who are given special
                recognition for their contribution to INSTICC, and/or who have
                distinguished themselves in the process of serving or dignifying
                INSTICC and the academic or professional community.
              </div>
            }
          />
          <InnerAccordion
            eventKey="4"
            title="Institutional Membership"
            content={
              <div className="body-text">
                For universities, research groups, and companies, as well as
                libraries and other organizations. Institutional membership
                enables the institution to become referred and advertised as an
                institutional partner of INSTICC, its library benefits from
                privileged access to all INSTICC publications, and a number of
                selected personnel and students are offered INSTICC member
                status.
              </div>
            }
          />
          <InnerAccordion
            eventKey="5"
            title="Senior Membership"
            content={
              <div className="body-text">
                Attributed to researchers or professional who have been active
                for at least 10 years and have given significant contributions
                to the advancement of knowledge or to improving professional
                practices in one or more of the INSTICC fields of interest.
              </div>
            }
          />
        </Box>
      </div>
    );
  }
}

export default Membership;
