import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import swal from "sweetalert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post, postLog, put } from "../../../js/httpRequests.js";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import config from "../../../config";
import MultiSlider from "../../MultiSlider";
import NavigationBar from "../../NavigationBar";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import { resizeImageFile } from "../../../js/auxFunctions.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal } from "react-bootstrap";
import { getTopicName } from "../../../js/auxFunctions";
import Moment from "moment";
import "../../../styles.scss";

var imageRepositoryUrl = "https://www.insticc.org/imagerepository";


function editorToHtml(text) {
  return draftToHtml(convertToRaw(text.getCurrentContent()));
}

class NewsManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      news: {
        title: "",
        mainPhoto: "",
        gallery: [],
        type: "News",
        firstName: "",
        middleName: "",
        lastName: "",
        insertedDate: {
          personID: "",
          date: new Date(),
        },
        publishedDate: {
          personID: "",
          date: null,
        },
        content: EditorState.createEmpty(),
        interestAreas: [],
        highlightedText: EditorState.createEmpty(),
        status: "Proposed",
      },
      topics: [],
      preview: false,
      bannerItems: 5,
      galleryKey: 0,
      lastStatus: "",
      user: {},
      onOpenModalNewPreview: false,
      newsToPreview: {
        title: "",
        mainPhoto: "",
        gallery: [],
        type: "News",
        firstName: "",
        middleName: "",
        lastName: "",
        insertedDate: {
          personID: "",
          date: new Date(),
        },
        publishedDate: {
          personID: "",
          date: null,
        },
        content: EditorState.createEmpty(),
        interestAreas: [],
        highlightedText: EditorState.createEmpty(),
        status: "Proposed",
      },
    };
  }

  convertHtmlsToDraft = (object) => {
    const contentBlock = htmlToDraft(object.content);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    object.content = editorState;

    const highlightedNewsBlock = htmlToDraft(object.highlightedText);
    const highlightedNewsState = ContentState.createFromBlockArray(
      highlightedNewsBlock.contentBlocks
    );
    const highlightedTextEditorState = EditorState.createWithContent(
      highlightedNewsState
    );
    object.highlightedText = highlightedTextEditorState;

    return object;
  };

  async componentDidMount() {
    const user = await (await Axios.get("/api/login/user")).data;
    const topics = (
      await Axios.post("/api/topics/find", {
        query: {
          isFilter: true,
        },
        sort: {
          name: 1,
        },
      })
    ).data;

    try {
      let resNews = await (
        await Axios.get("/api/news/" + this.props.match.params.id)
      ).data;

      resNews = this.convertHtmlsToDraft(resNews);

      this.setState({
        news: resNews,
        isUpdate: true,
        topics: topics,
        lastStatus: resNews["status"],
        user: user,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        topics: topics,
        user: user,
        isLoading: false,
      });
    }
  }

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      news: {
        ...prevState.news,
        [name]: value,
      },
    }));
  };

  onContentChange = (editorState) => {
    this.setState((prevState) => ({
      news: {
        ...prevState.news,
        content: editorState,
      },
    }));
  };

  onHighlightedTextChange = (editorState) => {
    this.setState((prevState) => ({
      news: {
        ...prevState.news,
        highlightedText: editorState,
      },
    }));
  };

  onFileChange = async (e) => {
    const { name } = e.target;
    const files = e.target.files;
    if (files) {
      try {
        let reducedFiles = [];
        for (let file of files) {
          let reducedFile = await resizeImageFile(file, 1500, 500);
          reducedFiles.push(reducedFile);
        }

        this.setState((prevState) => ({
          news: {
            ...prevState.news,
            [name]: reducedFiles,
          },
        }));
      } catch (err) {
        alert(err);
      }
    }
  };

  onAutocompleteChange = (event, newValue) => {
    this.setState((prevState) => ({
      news: {
        ...prevState.news,
        interestAreas: newValue.map((x) => {
          return x.ID;
        }),
      },
    }));
  };

  checkForErrors = () => {
    if (
      !this.state.news.title ||
      !this.state.news.content.getCurrentContent().hasText() ||
      this.state.news.mainPhoto.length <= 0
    ) {
      swal({
        title: "Error",
        text: "Please ensure you filled the required fields",
        icon: "error",
      });
      return true;
    } else return false;
  };

  onSubmit = async (isUpdate) => {
    this.setState({
      isLoading: true, onOpenModalNewPreview: false
    });
    if (!this.checkForErrors()) {
      let object = Object.assign({}, this.state.news);
      object.content = editorToHtml(this.state.news.content);
      object.highlightedText = editorToHtml(this.state.news.highlightedText);

      if (!isUpdate) {
        object["insertedDate"]["personID"] = this.state.user._id;


        let objectID = await Axios.post("/api/news", {
          body: object,
        })

        if (document.getElementById("fileBanner").files.length > 0 && objectID) {
          var file = document.getElementById("fileBanner").files[0];
          var formData = new FormData();
          formData.append("file", file);
          fetch(imageRepositoryUrl + `/api/Materials/613f5d386b364a4e38e1dd00/${objectID.data}/PortalManager`,
            {
              method: "POST",
              body: formData,
            }).then((response) => {
              if (response.status == 200) {
                let pathImage = `https://insticc.org/images/portal/news/${objectID.data}/banner/original.png`;
                object.mainPhoto = pathImage;
                put(objectID.data, object, "news", () => {
                  postLog(
                    "User updated news",
                    "User updated a news information",
                    this.state.user.firstName + " " + this.state.user.lastName,
                    this.state.user.email
                  );
                  this.props.history.push("/Backoffice/News");
                });
              }
              else {
              }
            }).catch((exception) => {
              console.log(exception);
            });
        }
        else {

          swal({
            title: "Sucess",
            text: "Information successfully updated",
            icon: "success",
          });
        }

      } else {
        if (
          this.state.lastStatus !== "Published" &&
          object.status === "Published"
        ) {
          object["publishedDate"]["personID"] = this.state.user._id;
          object["publishedDate"]["date"] = Date.now();
        }
        // put(object._id, object, "news", () => {

        //   postLog(
        //     "User updated news",
        //     "User updated a news information",
        //     this.state.user.firstName + " " + this.state.user.lastName,
        //     this.state.user.email
        //   );
        //   this.props.history.push("/Backoffice/News");

        if (document.getElementById("fileBanner").files.length > 0) {
          object.mainPhoto = "";
        }

        let objectID = await Axios.put("/api/news/" + object._id, {
          body: object,
        })

        if (document.getElementById("fileBanner").files.length > 0 && object._id) {
          var file = document.getElementById("fileBanner").files[0];
          var formData = new FormData();
          formData.append("file", file);
          fetch(imageRepositoryUrl + `/api/Materials/613f5d386b364a4e38e1dd00/${object._id}/PortalManager`,
            {
              method: "POST",
              body: formData,
            }).then((response) => {
              if (response.status == 200) {
                let pathImage = `https://insticc.org/images/portal/news/${object._id}/banner/original.png`;
                object.mainPhoto = pathImage;
                put(object._id, object, "news", () => {
                  postLog(
                    "User updated news",
                    "User updated a news information",
                    this.state.user.firstName + " " + this.state.user.lastName,
                    this.state.user.email
                  );
                  this.props.history.push("/Backoffice/News");
                });
              }
              else {
              }
            }).catch((exception) => {
              console.log(exception);
            });
        }
        else {
          swal({
            title: "Sucess",
            text: "Information successfully updated",
            icon: "success",
          });
        }


        // });

      }
    }
    this.setState({
      isLoading: false,
    });
  };

  adjustObject = () => {
    let news = Object.assign({}, this.state.news);
    news.highlightedText = editorToHtml(news.content);
    news.content = editorToHtml(news.content);

    return news;
  };

  previewObject = () => {
    let news = Object.assign({}, this.state.news);
    news.highlightedText = editorToHtml(news.content);
    news.content = editorToHtml(news.content);

    var mainPhotoPath = this.state.news.mainPhoto;
    if (Array.isArray(this.state.news.mainPhoto)) {
      mainPhotoPath = URL.createObjectURL(this.state.news.mainPhoto[0]);
    } else mainPhotoPath = mainPhotoPath;

    news.mainPhoto = mainPhotoPath;

    var galleryTempList = [];
    for (let i = 0; i < news.gallery.length; i++) {
      var tempGalleryPath;
      if (news.gallery[i] instanceof File)
        tempGalleryPath = URL.createObjectURL(news.gallery[i]);
      else tempGalleryPath = "/News/" + news.gallery[i];

      galleryTempList.push(tempGalleryPath);
    }
    news.gallery = galleryTempList;

    news.publishedDate.date = new Date();

    // if (document.getElementById("fileBanner").files.length > 0) {
    //   var file = document.getElementById("fileBanner").files[0];
    //   console.log(file)
    //   news.BannerFile = file;
    // }
    this.setState({ onOpenModalNewPreview: true, newsToPreview: news });
    return news;
  };

  previewBanner = () => {
    const { mainPhoto } = this.state.news;

    let url = null;
    if (mainPhoto[0] instanceof File) url = URL.createObjectURL(mainPhoto[0]);
    else if (Array.isArray(mainPhoto)) {
      if (mainPhoto.length <= 0) return false;
    } else if (mainPhoto) url = mainPhoto;

    return url;
  };

  isBannerShowing = () => {
    let url = this.previewBanner();

    if (!url) return false;
    else if (url instanceof FileList) {
      if (url.length < 0) return false;
    } else return true;
  };

  previewGallery = () => {
    const { gallery } = this.state.news;

    let items = [];
    for (let image of gallery) {
      items.push(
        image instanceof File
          ? URL.createObjectURL(image)
          : config.newsImageBasePath + "/" + image
      );
    }

    return items;
  };


  onCloseModalNewPreview = () => {
    this.setState({ onOpenModalNewPreview: false });
  };

  getTopicsNames = () => {
    const { interestAreas } = this.state.news;
    const { topics } = this.state;

    return topics.filter((x) => interestAreas.includes(x.ID));
  };

  render() {
    const {
      mainPhoto,
      title,
      firstName,
      middleName,
      lastName,
      content,
      gallery,
      status,
    } = this.state.news;
    return (
      <div>
        {this.state.isLoading ? (
          <div style={{ top: '97px', position: 'relative' }}>
            <CircularProgress />
          </div>
        ) : null}
        <div>
          <Modal show={this.state.onOpenModalNewPreview} onHide={this.onCloseModalNewPreview}>
            <Modal.Header closeButton className="header-modal">
              <Modal.Title>
                Preview
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <div >
                <NavigationBar />
                <div >

                  <div className="details-image-div">
                    <img
                      src={this.state.newsToPreview.mainPhoto + "?q=" + Date.now()}
                      className="details-image"
                      style={this.state.loadedImage ? {} : { display: "none" }}
                      onLoad={() => this.setState({ loadedImage: true })}
                      alt="Banner"

                    />
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={12} md={10}>
                      <Grid container justify="center"></Grid>
                    </Grid>
                  </Grid>
                  <br></br>
                  <br></br>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="title gold">{this.state.newsToPreview.title}</h3>
                      <h4 className="news-details-date paragraph">
                        {this.state.newsToPreview.interestAreas
                          .map((id) => {
                            return getTopicName(id, this.state.topics);
                          })
                          .join(", ")}
                        {"\n\n"}
                        Posted{" "}
                        {this.state.newsToPreview.firstName || this.state.newsToPreview.middleName || this.state.newsToPreview.lastName
                          ? "by " +
                          this.state.newsToPreview.firstName +
                          " " +
                          this.state.newsToPreview.middleName +
                          " " +
                          this.state.newsToPreview.lastName
                          : ""}{" "}
                        on{" "}
                        {Moment(this.state.newsToPreview.publishedDate.date).format(
                          "DD MMM yyyy | h:mm A"
                        )}
                      </h4>
                      <br></br>
                      <br></br>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.newsToPreview.content,
                        }}
                      />
                    </Grid>
                  </Grid>

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: "flex ", justifyContent: "space-around" }}>
                <Button onClick={() => this.onSubmit(this.state.isUpdate)} bsStyle="primary">
                  Save
            </Button>
                <Button
                  type="button"
                  onClick={this.onCloseModalNewPreview}
                  className="light-button"
                >
                  Close
            </Button>
              </div>
            </Modal.Footer>
          </Modal>

          <NavigationBar isBackoffice />
          <div className="content">
            <div className="p-5">
              <Grid container alignItems="center" className="pb-3">
                <Grid item xs={12} sm={10}>
                  <h4 className="yellow-text">Create News</h4>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Grid container justify="center">
                        <Tooltip
                          title={
                            <ul className="paragraph pt-3">
                              <li>
                                Proposed: It has been created, pending approval.
                                Public can't view.
                            </li>
                              {"\n"}
                              <li>
                                Published: It has been created and accepted.
                                Public can view.
                            </li>
                              {"\n"}
                              <li>
                                Highlighted: It has been created, accepted and
                                with high priority. Public can view.
                            </li>
                              {"\n"}
                              <li>
                                Deleted: It has been removed. Public can't view.
                            </li>
                            </ul>
                          }
                        >
                          <HelpIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <Select
                        fullWidth
                        variant="outlined"
                        name="status"
                        value={status}
                        onChange={this.onTextFieldChange}
                      >
                        <MenuItem value={"Proposed"}>Proposed</MenuItem>
                        <MenuItem value={"Published"}>Published</MenuItem>
                        <MenuItem value={"Highlighted"}>Highlighted</MenuItem>
                        <MenuItem value={"Deleted"}>Deleted</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" />
              <div className="pt-4">
                <label component="legend">
                  Title <span className="red-text">*</span>
                </label>
                <TextField
                  error={!title}
                  helperText={!title ? "Title is required" : ""}
                  value={title}
                  name="title"
                  className="mb-5"
                  required
                  fullWidth
                  onChange={this.onTextFieldChange}
                  variant="outlined"
                />
                <Grid container spacing={5}>
                  <Grid item xs={12} md={3}>
                    <label className="pr-2">Banner</label>
                    <input
                      accept="image/*"
                      type="file"
                      id="fileBanner"
                      style={{
                        border: mainPhoto.length <= 0 ? "1px solid red" : "",
                      }}
                      required
                      name="mainPhoto"
                      onChange={this.onFileChange}
                    />
                    <label
                      className="red-text"
                      style={{
                        display: mainPhoto.length <= 0 ? "" : "none",
                      }}
                    >
                      Banner required
                  </label>
                    <br></br>
                    <div
                      className="frame"
                      style={{
                        display: !this.isBannerShowing() ? "none" : "",
                      }}
                    >
                      <span className="img-center-helper"></span>
                      <img
                        src={this.previewBanner()}
                        className="w-75"
                        alt="Selected Banner"
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={9}>
                  <label>
                    Gallery
                    <Tooltip
                      title="Gallery of photos to be displayed on the news page"
                      className="mb-3"
                    >
                      <HelpIcon style={{ fontSize: 15 }} />
                    </Tooltip>
                  </label>
                  <Tooltip title="Clear gallery">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        this.setState((prevState) => ({
                          news: {
                            ...prevState.news,
                            gallery: [],
                          },
                          galleryKey: this.state.galleryKey + 1,
                        }));
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <input
                    id="fileGalery"
                    key={this.state.galleryKey}
                    multiple
                    accept="image/*"
                    type="file"
                    className="pl-2"
                    name="gallery"
                    onChange={this.onFileChange}
                  />
                  <br></br>
                  <div
                    style={{
                      height: gallery.length > 0 ? "300px" : 0,
                    }}
                  >
                    <MultiSlider
                      boxClassName="h-100"
                      items={this.previewGallery()}
                      autoPlaySpeed={2000}
                      desktopItems={Math.floor(
                        this.previewGallery().length / 2
                      )}
                      autoPlay
                      borderColor="transparent"
                      arrows={false}
                      imageBaseRef={""}
                    />
                  </div>
                </Grid> */}
                </Grid>
                <br></br>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <label>
                      First Name <span className="red-text">*</span>
                    </label>
                    <TextField
                      name="firstName"
                      value={firstName || ""}
                      required
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label>Middle Name</label>
                    <TextField
                      name="middleName"
                      value={middleName || ""}
                      required
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label>
                      Last Name <span className="red-text">*</span>
                    </label>
                    <TextField
                      value={lastName || ""}
                      name="lastName"
                      required
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <br></br>
                <label>Interest Areas</label>
                <Autocomplete
                  multiple
                  name="interestAreas"
                  onChange={this.onAutocompleteChange}
                  value={this.getTopicsNames() || []}
                  options={this.state.topics}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Topics"
                      name="interestAreas"
                    />
                  )}
                />
                <br></br>
                <label>
                  Content <span className="red-text">*</span>
                </label>
                <Editor
                  editorState={content}
                  editorClassName={
                    "content-editor " +
                    (!content.getCurrentContent().hasText() ? "red-border" : "")
                  }
                  onEditorStateChange={this.onContentChange}
                />
                <label
                  className="red-text"
                  style={{
                    display: !content.getCurrentContent().hasText() ? "" : "none",
                  }}
                >
                  Content is required
              </label>
                <br></br>
                <label>
                  Highlighted Text
                <Tooltip
                    title="Short text to be dislayed on the news card"
                    className="mb-3"
                  >
                    <HelpIcon style={{ fontSize: 15 }} />
                  </Tooltip>
                </label>
                <Editor
                  editorState={this.state.news.highlightedText}
                  editorClassName="highlightedText-editor"
                  onEditorStateChange={this.onHighlightedTextChange}
                />
                <br></br>
                <Link

                  onClick={(e) => {
                    if (this.checkForErrors()) {
                      e.preventDefault();
                    } else {
                      this.previewObject(this.state.news);
                    }
                  }}
                // to={"/Backoffice/News/Preview"}
                >
                  <Button variant="contained" className="mr-2">
                    Preview
                </Button>
                </Link>
                <Button
                  variant="contained"
                  className="yellow-bg"
                  onClick={() => this.onSubmit(this.state.isUpdate)}
                >
                  {this.state.isUpdate ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(NewsManagement);
