import React from "react";
import Carousel from "react-multi-carousel";
import CustomChip from "./CustomChip";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@material-ui/core";

const defaultXL = 10;
const defaultLG = defaultXL - 2;
const defaultMD = defaultLG - 2;
const defaultSM = defaultMD - 2;
const defaultXS = defaultSM - 2;

class ChipMultiSlider extends React.Component {
  state = {
    selectedValue: "",
    xl: this.props.xl || defaultXL,
    lg: this.props.lg || defaultLG,
    md: this.props.md || defaultMD,
    sm: this.props.sm || defaultSM,
    xs: this.props.xs || defaultXS,
    filterArray: [],
  };

  updateSize = () => {
    let newSize = 0;
    const { xl, lg, md, sm, xs } = this.state;
    const width = window.innerWidth;
    if (width >= 1920) newSize = xl;
    else if (width >= 1280 && width < 1920) newSize = lg;
    else if (width >= 960 && width < 1280) newSize = md;
    else if (width >= 600 && width < 960) newSize = sm;
    else newSize = xs;

    this.setState({ filtersPerPage: newSize });
    this.updateFilterArray(newSize);
  };

  updateFilterArray = (filtersPerPage) => {
    let innerArray = [];
    let count = 0;
    let newFilterArray = [];

    this.props.filterOptions.map((filter, index) => {
      if (
        count < filtersPerPage &&
        index !== this.props.filterOptions.length - 1
      ) {
        count++;
        innerArray.push(filter);
      } else if (index !== this.props.filterOptions.length - 1) {
        newFilterArray.push(innerArray);
        count = 1;
        innerArray = [];
        innerArray.push(filter);
      } else {
        innerArray.push(filter);
        newFilterArray.push(innerArray);
      }
      return filter;
    });

    this.setState({ filterArray: newFilterArray });
  };

  componentDidMount() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize);
  }

  setSelectedValue = (value) => {
    if (value === this.state.selectedValue)
      this.setState({ selectedValue: "" });
    else this.setState({ selectedValue: value });
  };

  render() {
    const { filterArray } = this.state;
    return (
      <div>
        <Carousel
          style={{ height: "100%" }}
          arrows
          draggable
          keyBoardControl
          minimumTouchDrag={80}
          swipeable
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
        >
          {filterArray.map((array, index) => {
            return (
              <ChipSection
                items={array}
                selectedValue={this.state.selectedValue}
                setSelectedValue={this.setSelectedValue}
                key={"Chip Section #" + index}
                onChipClick={this.props.onChipClick}
              />
            );
          })}
        </Carousel>
      </div>
    );
  }
}

class ChipSection extends React.Component {
  render() {
    return (
      <div style={{ margin: "10px 10%" }} className="vertical-center">
        <Grid container spacing={2}>
          {this.props.items.map((item, index) => {
            return (
              <Grid item md key={"Chip #" + index}>
                <CustomChip
                  selectedValue={this.props.selectedValue}
                  setSelectedValue={this.props.setSelectedValue}
                  item={item}
                  onChipClick={this.props.onChipClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

export default ChipMultiSlider;
