function NavigationItem(ID, name, link) {
    this.ID = ID;
    this.name = name;
    this.link = link;

    this.compareByID = (ID) => {
        return this.ID === ID;
    }
}

module.exports = NavigationItem;