import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { CountryRegionData } from "react-country-region-selector";
import RoundTextField from "../Custom Components/RoundTextField";
import axios from "axios";
import CustomAutoComplete from "../Custom Components/CustomAutoComplete";
import moment from "moment";
import { postLog, put, sendEmail } from "../../js/httpRequests";
import StandardButton from "../Custom Components/StandardButton";
import Tabs from "../Custom Components/Tabs";
import menuItems from "./MenuItems.json";
import swal from "sweetalert";

let vatArray = [];

function findCountryVatRegex(country) {
  for (let obj of vatArray) {
    if (obj.name.toLocaleLowerCase() === country.toLocaleLowerCase())
      return new RegExp(obj.vatFormat);
  }
  return /.*/;
}

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

class
  MyMembership extends React.Component {
  state = {
    isOpen: false,
    institutionalData: {
      organization: "",
      address: "",
      zipCode: "",
      country: CountryRegionData[0],
      city: { name: "", value: "" },
      telephone: "",
      VAT: "",
    },
    user: {},
    membership: {},
    membershipObj: {},
    isCityOpen: false,
    citiesLoading: false,
    isLoading: true,
    joinLoading: false,
    options: [],
  };

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      institutionalData: {
        ...prevState.institutionalData,
        [name]: value,
      },
    }));
  };

  getLatestMembership = (membership, membershipClassification) => {
    
    if (membership && membership.period) {
      const latest = membership.period[membership.period.length - 1];

      if (latest)
        return {
          memberNumber: membership.membershipNumber,
          memberType: membershipClassification.name,
          registrationDate: membership.startDate,
          validationDate: latest.end,
          status: latest.isActive,
          isInstitutional: membership.idMembershipClassification === 11 ? true : false,//membership.isInstitutional,
          institutionalData: {},
        };
      else return null;
    }

    return null;
  };

  async componentDidMount() {
    const userReq = await axios.get("/api/login/user");
    const membershipReq = await axios.post("/api/memberships/find/", {
      query: {
        idUser: userReq.data.idPerson,
      },
    }).then(async (response) => {
      if (response.status === 200) {
       
        let membershipReq = response;
       
        const membershipClassificationReq = await axios.get(
          `/api/memberships/classifications/${membershipReq.data.idMembershipClassification}`
        );
        this.setState({
          user: userReq.data,
          membershipObj: membershipReq.data,
          membership: this.getLatestMembership(
            membershipReq.data,
            membershipClassificationReq.data
          ),
          isLoading: false,
        });
      }
    }).catch(err => {
      console.log('teste',err)
      userReq.data.isMember = false;
    })

    // if (membershipReq != undefined) {
    //   const membershipClassificationReq = await axios.get(
    //     `/api/memberships/classifications/${membershipReq.data.idMembershipClassification}`
    //   );
    //   this.setState({
    //     user: userReq.data,
    //     membershipObj: membershipReq.data,
    //     membership: this.getLatestMembership(
    //       membershipReq.data,
    //       membershipClassificationReq.data
    //     ),
    //     isLoading: false,
    //   });

    // }

    
    this.setState({
      user: userReq.data,
      isLoading: false,
    });

    const req = await axios.get(
      "https://www.insticc.org/primoris/api/Proceedings/GetCountriesWithVAT",
      { withCredentials: false }
    );

    if (req.data) vatArray = JSON.parse(req.data);
  }

  onVatChange = (event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      institutionalData: {
        ...prevState.institutionalData,
        VAT: value,
      },
    }));
  };

  onAutoCompleteChange = async (event) => {
    const value = event.target.value;
    if (value && value.length >= 3) {
      this.setState({
        citiesLoading: true,
      });
      const req = await axios.post(
        `https://www.insticc.org/venus/api/1.0.0/countries/${this.state.institutionalData.country[1]}/cities/find`,
        { query: { city: value } },
        { withCredentials: false }
      );
      this.setState({
        options: req.data.map((x) => {
          return {
            name: x["AccentCity"],
            value: x["AccentCity"],
          };
        }),
        citiesLoading: false,
      });
    } else
      this.setState({
        citiesLoading: false,
        options: [],
      });
  };

  findInsticcMembership = (memberships) => {
    if (memberships)
      for (let membership of memberships)
        if (
          membership.associationName.toLocaleLowerCase().includes("insticc")
        ) {
          return membership;
        }

    return null;
  };

  joinInsticc = async () => {
    this.setState({ joinLoading: true });
    let isPopupOpen = true;
    let isFinished = false;
    swal({
      text: "A member record is now being created for you. You can use the member number assigned to you for getting discounts at INSTICC conferences, as well as other benefits.",
      button: "Continue",
    }).then(() => {
      isPopupOpen = false;
      if (isFinished) window.location.reload();
    });
    // let newNumberReq = await axios.get("/api/memberships/newNumber");
    // let regularMembershipReq = await axios.post(
    //   "/api/memberships/classifications/find",
    //   {
    //     query: {
    //       name: "Regular",
    //     },
    //   }
    // );

    let user = Object.assign({}, this.state.user);

    put(
      "/membership",
      user,
      "users",
      async () => {
        postLog(
          "Join INSTICC",
          "User applied for INSTICC Membership, user object updated",
          user.firstName + " " + user.lastName,
          user.email
        );
        await sendEmail("Membership Aquisition", user);
        isFinished = true;
        if (!isPopupOpen) window.location.reload();
      },
      "",
      true
    );
  };

  renewMembership = async () => {
    let membership = Object.assign({}, this.state.membershipObj);
    let user = Object.assign({}, this.state.user);
   //const endDate = new Date(new Date().getFullYear() + 1, 11, 31, 23, 59, 59);
    user.isMember = true;
   // this.findInsticcMembership(user.memberships)["validationDate"] = endDate;
    // membership.endDate = endDate;
    // membership.period.push({
    //   start: Date.now(),
    //   end: endDate,
    //   isActive: true,
    //   idPayment: 0,
    //   idPaymentState: 5,
    //   idMembershipState: 6,
    // });
   // user.membershipToRenew = membership;
    let renewInPortal = true;
    //Call primoris service to also update the date
    // let primorisResponse = await fetch(
    //   "https://www.insticc.org/PrimorisWebServices/api/InsticcPortal/RenewMembership",
    //   {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       email: this.state.user.email,
    //       validationDate: endDate,
    //     }),
    //   }
    // );
    // if (primorisResponse.status !== 200 && primorisResponse.status !== 404)
    //   renewInPortal = false;

    if (renewInPortal)
      put(
        "/renewMembership",
        user,
        "users",
        () => {
          postLog(
            "Renewal of INSTICC Membership",
            "User renewed his INSTICC Membership, user object updated",
            user.firstName + " " + user.lastName,
            user.email
          );
          window.location.reload();

        },
        "",
        true
      );
  };

  validate = () => {
    const { institutionalData } = this.state;
    let temp = {};
    temp.organization = institutionalData.organization !== "";
    temp.address = institutionalData.address !== "";
    temp.zipCode = institutionalData.zipCode !== "";
    temp.telephone = institutionalData.telephone !== "";
    temp.city =
      institutionalData.city !== null && institutionalData.city.value !== "";
    temp.VAT =
      institutionalData.VAT !== "" &&
      findCountryVatRegex(institutionalData.country[0]).test(
        institutionalData.VAT
      );

    return Object.values(temp).every((x) => x === true);
  };

  upgradeToInstitutional = () => {
    const { institutionalData } = this.state;
    institutionalData.city = institutionalData.city.value;
    institutionalData.country = institutionalData.country[0];

    const obj = {
      isInstitutional: true,
      institutionalData: institutionalData,
    };

    put(
      "/upgradeMembership",
      obj,
      "users",
      () => {
        postLog(
          "User upgraded to Institutional Membership",
          "User upgraded his INSTICC Membership to Institutional",
          this.state.user.firstName + " " + this.state.user.lastName,
          this.state.user.email
        );
        window.location.reload();
      },
      "",
      true
    );
  };

  downgradeToIndividual = () => {
    const obj = {
      isInstitutional: false,
      institutionalData: null,
    };

    put("/downgradeMembership", obj, "users", () => {
      postLog(
        "User downgraded to Individual Membership",
        "User downgraded his INSTICC Membership to Individual",
        this.state.user.firstName + " " + this.state.user.lastName,
        this.state.user.email
      );
      window.location.reload();
    });
  };

  render() {
    const { country, city, organization, address, VAT, telephone, zipCode } =
      this.state.institutionalData;
    const { membership, user, isLoading } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/Membership/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        />
        <Tabs items={menuItems} selected="Membership" className="pt-5" />
        {isLoading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            className="pt-5 mt-5"
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <Box className="main-box">
              {membership && user.isMember ? (
                <div>
                  <h1>INSTICC values your membership</h1>
                  <a
                    href="/Membership"
                    className="yellow-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    INSTICC Membership is a way to remain connected with the R&D
                    community and to keep aware of new ideas, research projects
                  and opportunities.{"  "}
                    <img
                      width="20"
                      height="20"
                      className="mb-1"
                      src="/hyperlink-icon.png"
                      alt="Event Website"
                    />
                  </a>{" "}
                  <br></br>
                  <br></br>
                  <h4>Membership Details</h4>
                  <p>Member Nº: {membership.memberNumber}</p>
                  <p>Member Type: {membership.memberType}</p>
                  <p>
                    Registration Date:{" "}
                    {moment(membership.registrationDate).format("MM/YYYY")}
                  </p>
                  <p>
                    Validation Date:{" "}
                    {moment(membership.validationDate).format("MM/YYYY")}
                  </p>
                  <p>
                    Status:{" "}
                    {membership.status &&
                      Date.now() < new Date(membership.validationDate)
                      ? "Active"
                      : "Inactive"}
                  </p>
                </div>
              ) : (
                  <div>
                    <h3 className="title gold">How to become an INSTICC Member</h3>
                    <p className="text-align-justify body-text paragraph">
                      First, you need to get an account by signing up. Please use
                      the button on the top right. If you already have an account,
                  then just log in, please.{"\n"}
                      Then, you can go to your user account (my account) and select
                      the "Mambership" tab and click on "join". For the moment it's
                      free!
                </p>
                    <br></br>
                    <h3 className="title gold">Reasons to Join</h3>
                    <br></br>
                    <p className="paragraph body-text">
                      INSTICC membership is a way to remain connected with the R&D
                      community and to keep aware of new ideas, research projects
                      and opportunities. INSTICC membership is open to all the
                      community ranging from individuals, institutions,
                      universities, libraries, research groups, companies and other
                      organizations who contribute to knowledge advancement in the
                  INSTICC fields of pursuit.{"\n\n"}
                      <span className="bold">Improved Access to Publications</span>
                      {"\n"}
                      <ul>
                        <li>
                          INSTICC members are welcome to contribute to the INSTICC
                          newsletter.
                    </li>
                      </ul>{" "}
                      <span className="bold">Networking Opportunities</span>
                      <ul>
                        <li>
                          INSTICC members are encouraged to publish and present
                          their research work in a variety of forms, from events
                          such as conferences, congresses, special sessions,
                          symposia, workshops and tutorials to publications such as
                          the INSTICC newsletter and other media;
                    </li>{" "}
                        <li>
                          INSTICC members benefit from special discounts at INSTICC
                          sponsored events, including reduced registration fees;
                    </li>{" "}
                        <li>
                          INSTICC has limited number of grants to offer to members
                          who have economic difficulties to attend sponsored events.
                          Every year, more than 100 grants are offered;
                    </li>{" "}
                        <li>
                          INSTICC members furthermore stand to benefit from the
                          variety of social networks in which INSTICC is
                          represented, and which can be used to network informally
                          with other members of the INSTICC community and the
                          broader academic community;
                    </li>
                      </ul>
                    </p>
                    <br></br>
                    <br></br>
                    <h3 className="title gold">Membership Categories</h3>
                    <br></br>
                    <p className="paragraph body-text">
                      INSTICC members can join the INSTICC network in different
                      categories, as described below. There are particular aspects
                      that are specific of each of these categories that may add to
                      the common list of membership benefits. Please contact INSTICC
                  membership services to learn more about it.{"\n\n"}
                      <ul>
                        <li>
                          <span className="bold">Regular Membership</span>
                          {"\n"}For researchers, engineers, practitioners and
                          academics, as well as industrial professionals. This
                      membership type is the most common one.{"\n\n"}
                        </li>{" "}
                        <li>
                          <span className="bold">Student Membership</span>
                          {"\n"} For individuals currently enrolled full-time in
                      accredited undergraduate and graduate programs.{"\n\n"}
                        </li>
                        <li>
                          <span className="bold">Honorary Membership</span>
                          {"\n"} This is conferred to INSTICC members who are given
                          special recognition for their contribution to INSTICC,
                          and/or who have distinguished themselves in the process of
                          serving or dignifying INSTICC and the academic or
                      professional community.{"\n\n"}
                        </li>{" "}
                        <li>
                          <span className="bold">Institutional Membership</span>
                          {"\n"} For universities, research groups, and companies,
                          as well as libraries and other organizations.
                          Institutional membership enables the institution to become
                          referred and advertised as an institutional partner of
                          INSTICC, its library benefits from privileged access to
                          all INSTICC publications, and a number of selected
                          personnel and students are offered INSTICC member status.
                      {"\n\n"}
                        </li>{" "}
                        <li>
                          <span className="bold">Senior Membership</span>
                          {"\n"} Attributed to researchers or professional who have
                          been active for at least 10 years and have given
                          significant contributions to the advancement of knowledge
                          or to improving professional practices in one or more of
                          the INSTICC fields of interest.
                    </li>
                      </ul>
                    </p>
                  </div>
                )}
              <br></br>
              <Grid container spacing={5} alignItems="center">
                {user.isMember && membership.isInstitutional ? null : (
                  <Grid item xs={12} sm={4}>
                    <Tooltip
                      title={
                        user.isMember && !membership.isInstitutional ? (
                          <p style={{ fontSize: "12px" }} className="pt-2">
                            For universities, research groups, and companies, as
                            well as libraries and other organizations.
                            Institutional membership enables the institution to
                            become referred and advertised as an institutional
                            partner of INSTICC, its library benefits from
                            privileged access to all INSTICC publications, and a
                            number of selected personnel and students are offered
                            INSTICC member status.
                        </p>
                        ) : (
                            ""
                          )
                      }
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        disabled={this.state.joinLoading}
                        className="darkgray-outline-button"
                        startIcon={
                          <img
                            alt="INSTICC logo"
                            className="join-insticc-icon"
                            src="/white-insticc-logo.png"
                          />
                        }
                        onClick={() =>
                          user.isMember && !membership.isInstitutional
                            ? this.upgradeToInstitutional()
                            : this.joinInsticc()
                        }
                      >
                        {user.isMember && !membership.isInstitutional
                          ? "Upgrade to Institutional"
                          : "Join INSTICC"}
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                {this.state.joinLoading ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : null}
                {membership && membership.validationDate ? (
                  (monthDiff(new Date(), new Date(membership.validationDate)) <
                    6 ||
                    !membership.status) &&
                    user.isMember ? (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="outlined"
                          fullWidth
                          className="darkgray-outline-button"
                          startIcon={
                            <img
                              alt="INSTICC logo"
                              className="join-insticc-icon"
                              src="/white-insticc-logo.png"
                            />
                          }
                          onClick={this.renewMembership}
                        >
                          Renew Membership
                    </Button>
                      </Grid>
                    ) : null
                ) : null}
              </Grid>
              {user.isMember && membership.isInstitutional ? (
                <div>
                  <br></br>
                  <br></br>
                  {/* <h4>Institutional Membership Data</h4>
                  <p>Organization: {membership.institutionalData.organization}</p>
                  <p>Address: {membership.institutionalData.address}</p>
                  <p>Zip Code: {membership.institutionalData.zipCode}</p>
                  <p>Country: {membership.institutionalData.country}</p>
                  <p>City: {membership.institutionalData.city}</p>
                  <p>Telephone: {membership.institutionalData.telephone}</p>
                  <p>VAT: {membership.institutionalData.VAT}</p> */}
                  <Grid container justify="center">
                    <StandardButton
                      type="submit"
                      variant="outlined"
                      style={{ width: "25%" }}
                      onClick={this.downgradeToIndividual}
                    >
                      Downgrade to Individual Membership
                  </StandardButton>
                  </Grid>
                </div>
              ) : null}
              <Collapse in={this.state.isOpen}>
                <Box pt={5}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>Organization Name:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="organization"
                              backgroundColor="#ece9d6"
                              error={!organization}
                              helperText={!organization ? "Required" : ""}
                              value={organization}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>Address:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onEventChange}
                              name="address"
                              backgroundColor="#ece9d6"
                              error={!address}
                              helperText={!address ? "Required" : ""}
                              value={address}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={4}>
                                <h6>
                                  <b>Zip Code:</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <RoundTextField
                                  onChange={this.onEventChange}
                                  name="zipCode"
                                  backgroundColor="#ece9d6"
                                  error={!zipCode}
                                  helperText={!zipCode ? "Required" : ""}
                                  value={zipCode}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={2}>
                                <h6 className="pr-4">
                                  <b>City:</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={10}>
                                <CustomAutoComplete
                                  fullWidth
                                  open={this.state.isCityOpen}
                                  onOpen={() => {
                                    this.setState({
                                      isCityOpen: true,
                                    });
                                  }}
                                  onClose={() => {
                                    this.setState({
                                      isCityOpen: false,
                                    });
                                  }}
                                  getOptionSelected={(option, value) =>
                                    option.name === value.name
                                  }
                                  getOptionLabel={(option) => option.name}
                                  onAutoCompleteChange={this.onAutoCompleteChange}
                                  options={this.state.options}
                                  loading={this.state.citiesLoading}
                                  onChange={(e, value) => {
                                    this.setState((prevState) => ({
                                      institutionalData: {
                                        ...prevState.institutionalData,
                                        city: value,
                                      },
                                    }));
                                  }}
                                  value={city}
                                  error={!city || !city.value}
                                  helperText={
                                    !city || !city.value ? "Required" : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={2} md={4}>
                                <h6>
                                  <b>Telephone:</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={10} md={8}>
                                <RoundTextField
                                  onChange={this.onEventChange}
                                  name="telephone"
                                  backgroundColor="#ece9d6"
                                  type="number"
                                  value={telephone}
                                  error={!telephone}
                                  helperText={!telephone ? "Required" : ""}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={2}>
                                <h6 className="pr-4">
                                  <b>Country:(*)</b>
                                </h6>
                              </Grid>
                              <Grid item xs={12} sm={10}>
                                <RoundTextField
                                  name="country"
                                  value={country}
                                  backgroundColor="#ece9d6"
                                  select
                                  onChange={this.onEventChange}
                                >
                                  {CountryRegionData.map((option, index) => (
                                    <MenuItem key={option[0]} value={option}>
                                      {option[0]}
                                    </MenuItem>
                                  ))}
                                </RoundTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={2}>
                            <h6>
                              <b>VAT:</b>
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={10}>
                            <RoundTextField
                              onChange={this.onVatChange}
                              name="VAT"
                              error={
                                VAT !== ""
                                  ? vatArray.length > 0
                                    ? !findCountryVatRegex(country[0]).test(VAT)
                                    : false
                                  : true
                              }
                              helperText={
                                VAT !== ""
                                  ? vatArray.length > 0
                                    ? !findCountryVatRegex(country[0]).test(VAT)
                                      ? "Incorrect format"
                                      : ""
                                    : ""
                                  : "Required"
                              }
                              backgroundColor="#ece9d6"
                              value={VAT}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container justify="center">
                      <StandardButton
                        type="submit"
                        variant="outlined"
                        style={{ width: "25%" }}
                        disabled={!this.validate()}
                        onClick={this.upgradeToInstitutional}
                      >
                        Save
                    </StandardButton>
                    </Grid>
                  </form>
                </Box>
              </Collapse>
            </Box>
          )}
      </div>
    );
  }
}

export default MyMembership;
