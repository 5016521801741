import { Box, Grid } from "@material-ui/core";
import React from "react";
import MultiSlider from "../MultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../config";
import MoreButton from "../Custom Components/MoreButton";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import { departments } from "../AboutUs/departmentsArray";

class PublicationPartners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      partners: [],
    };
  }

  async componentDidMount() {
    const res = await axios.get("/api/partners/byType/Publication Partner");
    this.setState({
      partners: res.data,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, partners } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/PublicationPartners/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Publication Partners</h3>
          <br></br>
          <br></br>
          <h4 className="subtitle">
            Partnership Opportunities for Publication Partners
          </h4>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is ready to collaborate with publishers that provide a wide
            and prestigious dissemination of the scientific and technical work
            presented at INSTICC events, including facilitating the presence of
            those publishers at INSTICC events.
          </p>
          <br></br>
          <h4 className="subtitle">Contact</h4>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is open to partnership possibilities beyond the examples
            mentioned here and welcomes suggestions and cooperation proposals.
            Those interested in cooperating with INSTICC should contact our{" "}
            <HtmlTooltip
              department={departments[3]}
              text="Institutional Relations Office"
            />
            .
          </p>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <MultiSlider
              items={partners}
              autoPlay
              borderColor="transparent"
              arrows={false}
              baseRef={config.partnersBasePath}
              imageBaseRef={config.partnersImageBasePath}
            />
          )}
          <Grid container justify="center" className="pt-5">
            <MoreButton
              title="More Publication Partners"
              href={config.partnersBasePath + "?type=Publication Partner"}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default PublicationPartners;
