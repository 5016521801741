import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import swal from "sweetalert";
import { sendEmail } from "../../js/httpRequests";

export default class ForgotPassword extends React.Component {
  state = {
    email: "",
    error: "",
  };
  onTextFieldChange = (event) => {
    const { value } = event.target;
    this.setState({ email: value, error: "" });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    axios
      .post("/api/users/find", { query: { email: this.state.email } })
      .then(async (response) => {
        if (response.status !== 200) throw response.data;
        if (response.data.count <= 0) throw response.data;
        this.setState({ isLoading: true });
        let status = await sendEmail("Forgot Password", response.data[0]);
        if (status) {
          swal(
            `An e-mail has been sent to ${this.state.email} that has all the instructions for resetting your password. If you don't receive an e-mail within the next hour, please submit your address again.`,
            {
              icon: "success",
            }
          ).then(this.props.callback);
        } else {
          
          this.setState({
            error: "An error occurred while sending an email.",
          });
        }

        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({
          error:
            err.count !== undefined
              ? err.count <= 0
                ? "The e-mail address you have entered is invalid!"
                : err.toString()
              : err.toString(),
          isLoading: false,
        });
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <Box pb={2} pr={3} pl={3}>
          <label>
            Forgot your password? Enter your e-mail address below and we will
            send you detailed instructions on how to reset your password.
          </label>
          <TextField
            label="Email"
            name="email"
            fullWidth
            placeholder="Enter email"
            InputLabelProps={{
              style: { color: "#9f9234" },
            }}
            onChange={this.onTextFieldChange}
            helperText={<span className="red-text">{this.state.error}</span>}
          />
          <Grid container justify="center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" className="login-button mt-4 w-50">
                Send
              </Button>
            )}
          </Grid>
        </Box>
      </form>
    );
  }
}
