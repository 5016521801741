import { Box, Grid } from "@material-ui/core";
import React from "react";
import NewsCard from "./NewsCard";
import Moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import MoreButton from "../../Custom Components/MoreButton";
import config from "../../../config";
import { getTopicName } from "../../../js/auxFunctions";
import queryString from "query-string";

class NewsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: {},
      relatedNews: [],
      topics: [],
      isLoading: true,
      loadedImage: false,
    };
  }

  async componentDidMount() {
    let params = this.props.location
      ? queryString.parse(this.props.location.search)
      : "";
    let news = await (
      await axios.get("/api/news/" + this.props.match.params.id)
    ).data;
    let relatedNews = await (
      await axios.get(
        "/api/news/" +
          this.props.match.params.id +
          "/related/" +
          "?n=3" +
          (params.refID ? "&refID=" + params.refID : "")
      )
    ).data;

    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    this.setState({
      news: news,
      relatedNews: relatedNews,
      topics: topicsReq.data,
      isLoading: false,
    });

    document.title = "News " + news.title;
  }

  render() {
    const { news, relatedNews, isLoading, topics } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div className={this.props.preview ? "" : "content"}>
          <Box className="main-box">
            <div className="details-image-div">
              <img
                src={encodeURI(config.newsImageBasePath + news.mainPhoto)}
                className="details-image"
                style={this.state.loadedImage ? {} : { display: "none" }}
                onLoad={() => this.setState({ loadedImage: true })}
                alt="Banner"
              />
            </div>
            <Grid container justify="center">
              <Grid item xs={12} md={10}>
                <Grid container justify="center"></Grid>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3 className="title gold">{news.title}</h3>
                <h4 className="news-details-date paragraph">
                  {news.interestAreas
                    .map((id) => {
                      return getTopicName(id, topics);
                    })
                    .join(", ")}
                  {"\n\n"}
                  Posted{" "}
                  {news.firstName || news.middleName || news.lastName
                    ? "by " +
                      news.firstName +
                      " " +
                      news.middleName +
                      " " +
                      news.lastName
                    : ""}{" "}
                  on{" "}
                  {Moment(news.publishedDate.date).format(
                    "DD MMM yyyy | h:mm A"
                  )}
                </h4>
                <br></br>
                <br></br>
                <div
                  dangerouslySetInnerHTML={{
                    __html: news.content,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <br></br>
                <h4 className="subtitle fw-bold text-align-center">
                  Related News
                </h4>
                <br></br>
                <br></br>
                <Grid container spacing={2}>
                  {relatedNews.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={"Related #" + index}
                      >
                        <NewsCard
                          news={item}
                          showDescription={false}
                          refID={this.state.news._id}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container justify="center">
                  <MoreButton title="More News" href={config.newsBasePath} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      );
  }
}

export default NewsDetails;
