import axios from "axios";
import config from "../config";
import NavigationItem from "./tree/NavigationItem";
import TreeLinked from "./tree/TreeLinked";
import Resizer from "react-image-file-resizer";

export function hasFilteredTopics(filters, itemArray) {
    if (!filters || filters.length <= 0) return true;

    let flag = false;
    for (const topic of itemArray) {
        if (filters.includes(parseInt(topic))) flag = true;
    }

    return flag;
}

export function isEntityInArray(entity, entityArray) {
    const arrayOfIDs = entityArray.map(x => x._id);

    return arrayOfIDs.includes(entity._id);
}

export function getTopicName(matchingID, topics) {
    for (let topicObj of topics) {
        let { ID, name } = topicObj;
        if (parseInt(matchingID) === parseInt(ID)) return name;
    }

    return "";
};


// Must be type of NavigationItem
export function buildTree(items) {
    const tree = new TreeLinked(new NavigationItem(-1, "Start", ""));

    for (let item of items) {
        let navItem = new NavigationItem(item.ID, item.name, item.link);

        let node = tree.getNode(item.ID);
        let parentNode = tree.getNode(item.parentID);

        if (node) {
            if (node.parent.value.ID !== item.parentID) {
                // Remove node from parent's children
                tree.remove(node);
                if (parentNode) {
                    node.parent = parentNode;
                } else {
                    node.parent = tree.insert(
                        tree.root,
                        new NavigationItem(item.parentID, "", "")
                    );
                }
            } // if current parent ID matches parent ID from DB, then it's correct
        } else {
            if (parentNode) {
                tree.insert(parentNode, navItem);
            } else {
                let parentNode = tree.insert(
                    tree.root,
                    new NavigationItem(item.parentID, "", "")
                );
                tree.insert(parentNode, navItem);
            }
        }
    }

    return tree;
}

function addToArrayPreOrder(node, arr) {
    let nodeObj = {
        value: node.value,
        children: [],
        parent: node.parent,
    };

    arr.push(nodeObj);
    for (let child of node.children) {
        addToArrayPreOrder(child, nodeObj.children);
    }

    return arr;
}

export function tree2NavigationJSON(tree) {
    let arr = [];

    arr = addToArrayPreOrder(tree.root, arr);

    return arr[0].children;
}

export async function isLoggedIn() {
    const isLoggedInReq = await axios.get("/api/login/isLoggedIn");
    return isLoggedInReq.data ? isLoggedInReq.data : false;
}

export function getItemChildren(item, variable, increment, arr) {
    let itemObj = {
        name: item.value.name,
        link: item.value.link,
        variable: variable,
    };

    arr.push(itemObj);
    for (let child of item.children) {
        getItemChildren(child, variable + increment, increment, arr);
    }

    return arr;
}

export function getRegions(country) {
    if (!country) {
        return [];
    }
    return country[2].split("|").map((regionPair) => {
        let [regionName] = regionPair.split("~");
        return regionName;
    });
};

export function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function passwordTester(value) {
    return (
        !value ||
        !config.passwordRegex.test(value) ||
        value.length < 8 ||
        value.length > 16
    );
};

export function passwordTesterHelperText(value) {

    return !value
        ? "Required"
        : !config.passwordRegex.test(value)
            ? "Must have at least 1 uppercase, 1 lowercase, 1 digit and 1 special"
            : value.length < 8 || value.length > 16
                ? "Must be between 8 and 16 characters"
                : "";
};

export function nameTester(name) {
    return !name || config.specialRegex.test(name) || name.length > 25;
};

export function nameTesterHelperText(name) {
    return !name
        ? "Required"
        : config.specialRegex.test(name)
            ? "Invalid characters"
            : name.length > 25
                ? "Maximum characters exceeded"
                : "";
};

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const resizeImageFile = (file, width, height) => new Promise(resolve => {
    Resizer.imageFileResizer(file, width, height, file.name.split(".").pop(), 100, 0,
        uri => {
            const reducedFile = dataURLtoFile(uri, file.name.split(".")[0]);
            resolve(reducedFile);
        },
        'base64'
    );
});

export function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
};