import { Box, Grid } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const defaultArray = [
  "https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
];

class MultiSlider extends React.Component {
  render() {
    const {
      autoPlay,
      arrows,
      boxClassName,
      borderColor,
      desktopItems,
      autoPlaySpeed,
    } = this.props;
    return (
      <Box
        border={1}
        borderColor={borderColor || "#9F9232"}
        className={boxClassName}
      >
        <div
          className="pl-5 pr-5 h-100"
          style={{
            padding: "30px 0",
          }}
        >
          <Carousel
            className="h-100"
            additionalTransfrom={0}
            autoPlay={autoPlay}
            arrows={arrows}
            autoPlaySpeed={autoPlaySpeed || 1000}
            centerMode={true}
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: desktopItems || 5,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={false}
            slidesToSlide={1}
            swipeable
          >
            {this.props.items
              ? this.props.items.map((item, index) => {
                  return (
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ height: "100%" }}
                      key={"Item #" + index}
                    >
                      {this.props.baseRef ? (
                        <a href={this.props.baseRef + "/" + item._id}>
                          <img
                            draggable="false"
                            className="multi-slider-img pl-3 pr-3"
                            src={this.props.imageBaseRef + item.mainPhoto}
                            alt={"Image #" + index}
                          />
                        </a>
                      ) : (
                        <div>
                          <span className="multi-slider-helper" />
                          <img
                            draggable="false"
                            className="multi-slider-img pl-3 pr-3"
                            src={this.props.imageBaseRef + item}
                            alt={"Image #" + index}
                          />
                        </div>
                      )}
                    </Grid>
                  );
                })
              : defaultArray.map((value, index) => {
                  return (
                    <Box ml={2} mr={2}>
                      <span class="multi-slider-helper" />
                      <img
                        draggable="false"
                        className="multi-slider-img pl-3 pr-3"
                        src={value}
                        alt={"Image #" + index}
                      />
                    </Box>
                  );
                })}
          </Carousel>
        </div>
      </Box>
    );
  }
}

export default MultiSlider;
