import { Tooltip } from "@material-ui/core";
import DepartmentCard from "../AboutUs/DepartmentCard";
import React from "react";
import { withStyles } from "@material-ui/styles";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    maxWidth: 220,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default class HtmlTooltip extends React.Component {
  render() {
    return (
      <CustomTooltip
        title={<DepartmentCard department={this.props.department} hideImage />}
      >
        <a
          href={"mailto:" + this.props.department.email}
          className="yellow-link dont-break-out"
        >
          {this.props.text}
        </a>
      </CustomTooltip>
    );
  }
}
