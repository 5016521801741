import { Box } from "@material-ui/core";
import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

const defaultArray = [
  "https://insticc.org/images/portal/Banners/Frase_1/original.png",
  "https://insticc.org/images/portal/Banners/Frase_2/original.png",
  "https://insticc.org/images/portal/Banners/Frase_3/original.png",
  "https://insticc.org/images/portal/Banners/Frase_4/original.png",
  "https://insticc.org/images/portal/Banners/Frase_5/original.png",
];

class Slider extends Component {
  static defaultProps = {
    height: "100%",
  };

  render() {
    const { boxShadow, className, withArrows, withControls } = this.props;
    return (
      <Box boxShadow={boxShadow}>
        <Carousel
          controls={withControls ? true : false}
          className={className}
          style={{ backgroundColor: "transparent", height: this.props.height }}
          prevIcon={
            withArrows ? (
              <span aria-hidden="true" className="carousel-control-prev-icon" />
            ) : null
          }
          nextIcon={
            withArrows ? (
              <span aria-hidden="true" className="carousel-control-next-icon" />
            ) : null
          }
        >
          {this.props.items
            ? this.props.items.map((item, index) => {
                return (
                  <Carousel.Item key={"Item #" + index}>{item}</Carousel.Item>
                );
              })
            : defaultArray.map((value, index) => {
                return (
                  <Carousel.Item>
                    <img
                      key={"Item #" + index}
                      className="d-block mx-auto w-100"
                      src={value}
                      alt={"Image #" + index}
                    />
                  </Carousel.Item>
                );
              })}
        </Carousel>
      </Box>
    );
  }
}

export default Slider;
