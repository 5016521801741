import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { useAccordionToggle } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  open: {
    transform: "scaleY(1)",
  },
  close: {
    transform: "scaleY(-1)",
  },
}));

export default function AccordionToggleButton({ children, eventKey, isOpen }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => null);
  const classes = useStyles();
  const drawerToggle = () => {
    setOpen(!open);
  };
  const [open, setOpen] = React.useState(!isOpen);

  return (
    <IconButton
      aria-label="delete"
      onClick={() => {
        decoratedOnClick();
        drawerToggle();
      }}
      style={{ backgroundColor: "#D9DADC" }}
    >
      <ExpandMoreIcon
        className={clsx(!open && classes.close, open && classes.open)}
        fontSize="large"
        style={{ color: "#747679" }}
      />
      {children}
    </IconButton>
  );
}
