import { Box, Grid } from "@material-ui/core";
import React from "react";
import StandardButton from "../Custom Components/StandardButton";
import IndividualInterestArea from "./IndividualInterestArea";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { postLog, put } from "../../js/httpRequests";
import Tabs from "../Custom Components/Tabs";
import menuItems from "./MenuItems.json";

class InterestAreas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAreas: [],
      interestAreasTree: [],
      user: {},
      isLoading: true,
    };
  }

  async componentDidMount() {
    const userReq = await axios.get("/api/login/user");
    const topicsReq = await axios.post("/api/topics/find", {
      sort: {
        name: 1,
      },
    });
    const topics = topicsReq.data;
    const interestAreasTree = [];

    const parents = topics.filter((x) => x["parentIDs"].length <= 0);
    for (let parent of parents) {
      parent["ID"] = parent["ID"].toString();
      parent["children"] = [];

      for (let child of topics.filter((x) => {
        if (x["parentIDs"].length > 0)
          return x["parentIDs"].includes(parseInt(parent["ID"]));
        else return false;
      })) {
        child["ID"] = child["ID"].toString();
        child["parentId"] = child["parentIDs"]
          .filter((x) => x.toString() === parent["ID"])[0]
          .toString();
        parent["children"].push(child);
      }
      interestAreasTree.push(parent);
    }


    this.setState({
      interestAreasTree: interestAreasTree,
      selectedAreas: userReq.data.interestAreas,
      user: userReq.data,
      isLoading: false,
    });
  }

  onCheckBoxChange = (event) => {
    const { selectedAreas } = this.state;
    let selectedAreasCopy = [...selectedAreas];

    const id = event.target.name;

    // If added a child, and parent is not present, then it must add the parent
    let parent = this.getParent(id);
    if (parent !== null && !selectedAreasCopy.includes(parent["ID"]))
      selectedAreasCopy.push(parent["ID"]);

    if (selectedAreasCopy.includes(id)) {
      selectedAreasCopy = selectedAreasCopy.filter((x) => x !== id);

      //If removing a parent, the children must be removed too
      if (parent === null)
        selectedAreasCopy = selectedAreasCopy.filter(
          (x) =>
            !this.getNodeInformation(id)
              ["children"].map((x) => x["ID"])
              .includes(x)
        );
    } else {
      selectedAreasCopy.push(id);
    }
    this.setState((prevState) => ({
      selectedAreas: selectedAreasCopy,
      user: {
        ...prevState.user,
        interestAreas: selectedAreasCopy,
      },
    }));
  };

  getParent = (id) => {
    const { interestAreasTree } = this.state;

    for (let node of interestAreasTree) {
      const childrenIDs = node["children"].map((x) => x["ID"]);
      if (childrenIDs.includes(id)) return node;
    }

    return null;
  };

  getNodeInformation = (id) => {
    const { interestAreasTree } = this.state;
    const interestAreasTreeCopy = [...interestAreasTree];

    for (let node of interestAreasTreeCopy) {
      if (node["ID"] === id) return node;
      else
        for (let child of node["children"])
          if (child["ID"] === id) return child;
    }

    return null;
  };

  onSubmit = (event) => {
    event.preventDefault();

    put("/interestAreas", this.state.user, "users", () => {
      postLog(
        "User's Interest Areas updated",
        "User changed his interest areas",
        this.state.user.firstName + " " + this.state.user.lastName,
        this.state.user.email
      );
    });
  };

  buildSelectedAreasTree = () => {
    const { selectedAreas } = this.state;
    let tree = [];

    for (let id of selectedAreas) {
      
      const parent = this.getParent(id);
      
      if (!parent) {
        const node = Object.assign({}, this.getNodeInformation(id));
        node["children"] = [];
        tree.push(node);

        
      } else {
        let child = this.getNodeInformation(id);

        let childParent = tree.filter((x) => x["ID"] === child["parentId"])[0];
        childParent["children"].push(child);

      }
    }

    return tree;
  };

  render() {
    const { interestAreasTree, selectedAreas } = this.state;
    return (
      <div className="content">
        <Tabs items={menuItems} selected="Interest Areas" className="pt-5" />
        <Grid container className="pt-5">
          <Grid item xs={12} md={8} className="yellow-bg">
            <Box p={10}>
              <h4>
                <b>Interest Areas</b>
              </h4>
              <br></br>
              <br></br>
              <h5>
                <b>Please select your Interest Areas</b>
              </h5>
              {this.state.isLoading ? (
                <CircularProgress />
              ) : (
                <Grid container spacing={2}>
                  {interestAreasTree.map((interestArea, index) => {
                    return (
                      <Grid item xs={12} key={interestArea["ID"]}>
                        <IndividualInterestArea
                          interestArea={interestArea}
                          selectedAreas={selectedAreas}
                          onCheckBoxChange={this.onCheckBoxChange}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box p={10}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} xl={6}>
                  <h5>
                    <b>Selected Areas and Topics</b>
                  </h5>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <form onSubmit={this.onSubmit}>
                    <StandardButton type="submit" variant="outlined">
                      Save
                    </StandardButton>
                  </form>
                </Grid>
                <Grid item xs={12}>
                  {" "}
                  <p
                    className="paragraph body-text"
                    style={{ fontSize: 12, color: "#8f9092" }}
                  >
                    If you select the Area only, then all topics are considered
                    to be of interest, by default.{"\n"}Don't forget to save
                    your selection before leaving this page.
                  </p>
                </Grid>
              </Grid>
              {this.buildSelectedAreasTree().map((node, index) => {
                return (
                  <Box mt={5}>
                    <h6>
                      <b>{node["name"]}</b>
                    </h6>
                    {node["children"].map((child) => {
                      return <li>{child["name"]}</li>;
                    })}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default InterestAreas;
