import { Box } from "@material-ui/core";
import React from "react";
import InnerAccordion from "../Custom Components/InnerAccordion";
class AsASpeaker extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/AsSpeaker/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">As a Speaker</h3>
          <br></br>
          <InnerAccordion
            defaultActiveKey="1"
            isOpen
            eventKey="1"
            title="Benefits of Attending an INSTICC Event as a Speaker:"
            content={
              <div>
                Access to all event sessions and Keynote lectures;{"\n"}
                {"\n"}All registered speakers receive all event materials,
                including the Book of Abstracts and a digital copy of the
                Proceedings;{"\n"}
                {"\n"} Keynote lectures, discussion panels and paper
                presentations provide an overview of the state of the art of the
                event's area of interest and of all major research questions in
                the field;{"\n"}
                {"\n"} Getting feedback from reviewers, colleagues and senior
                scientists from all over the world;{"\n"}
                {"\n"} Authors benefit from a double-blind independent review
                process and from direct feedback and personalized improvement
                suggestions. All papers are published in the Proceedings under
                an ISBN and are made available in the SCITEPRESS Digital Library
                with an individual DOI and submitted for indexation to all major
                indexes;{"\n"}
                {"\n"} Researchers coming from regions of the world that have
                stringent financial constraints can apply to obtain grants that
                facilitate their access to the conference.
              </div>
            }
          />
          <InnerAccordion
            eventKey="2"
            title="Best Paper Awards:"
            content={
              <div>
                A "Best Paper Award" and a "Best Student Paper Award" will be
                conferred to the author(s) of a paper presented at the
                conference, to be chosen by the Program/Conference Chairs based
                on the best combined marks of paper reviewing, as assessed by
                the Program Committee, and paper presentation quality, as
                assessed by the session chairs at the conference venue.{"\n"} A
                "Best Poster Award" will also be attributed to the best paper
                presented as a poster and a "Best PhD project award" will be
                conferred to the student of a paper presented at the conference
                doctoral consortium, selected by the Doctoral Chair assessed by
                the Advisory Board.{"\n"} The awards will be announced and
                bestowed at the conference closing session.
              </div>
            }
          />
          <InnerAccordion
            eventKey="3"
            title="The author of an awarded paper, and co-authors if any,
              will be entitled to:"
            content={
              <div>
                A signed and stamped official award certificate;{"\n"}
                {"\n"} The announcement of their achievement on a special
                conference/congress webpage and on the INSTICC website and
                newsletter, as well as other outlets;{"\n"}
                {"\n"} Having the paper included in the list of conference best
                papers and invited to submit an extended and revised version to
                be published in a book or a journal, if a post-publication
                agreement is available for this particular conference;{"\n"}
                {"\n"} A one-year free membership of INSTICC, warranting full
                access to the{" "}
                <a
                  href="http://www.insticc.org/portal/Publications/DigitalLibrary.aspx"
                  target="_blank"
                  className="yellow-link"
                  rel="noopener noreferrer"
                >
                  SCITEPRESS Digital Library
                </a>
                . If already an{" "}
                <a
                  href="http://www.insticc.org/portal/Community/Membership.aspx"
                  className="yellow-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  F
                >
                  INSTICC member
                </a>
                , then this offer adds one year to her/his current membership;
                {"\n"}
                {"\n"}A voucher for a free or reduced registration fee in one
                event sponsored by INSTICC, valid during a 12 months period,
                including all conference materials (without the printed
                proceedings). This voucher is only available if the presenter
                attends the closing session and receives the award.
              </div>
            }
          />
        </Box>
      </div>
    );
  }
}

export default AsASpeaker;
