import axios from "axios";
import swal from "sweetalert";
import config from "../config";
import moment from "moment";

function prepareFormData(object) {
  let objectFD = new FormData();
  Object.keys(object).map((key) => {
    if (config.stringyFields.includes(key)) {
      objectFD.append(key, JSON.stringify(object[key]));
    }
    else if (Array.isArray(object[key])) {
      if (object[key].length <= 0)
        objectFD.append(key, "[]")
      for (let item of object[key])
        objectFD.append(key, item);
    } else {
      objectFD.append(key, object[key]);
    }
    return key;
  });

  return objectFD;
}

function handleResponse(res, customMessage, callback, noPopup) {
  const code = res.status ? res.status : res.response.status;
  if (code === 200 || code === 201) {
    if (!noPopup)
      swal(customMessage ? customMessage : res.data, {
        icon: "success",
      }).then(callback);
    else
      callback();
  }
  else
    throw res.response.data;
}

export function post(object, entity, callback, customMessage, dontParse, errorType, errorCallback) {
  let objectFD = prepareFormData(object);

  axios
    .post(`/api/${entity}?dontParse=${dontParse ? 'true' : 'false'}`, objectFD, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((res) => handleResponse(res, customMessage, callback))
    .catch((err) => {
      swal("Error", err.response ? err.response.data : err, errorType ? errorType : "error").then(() => errorCallback && errorCallback(err))
    });
}

export function put(id, object, entity, successCallback, customMessage, noPopup, errorType, errorCallback) {
  let objectFD = prepareFormData(object);

  axios
    .put(`/api/${entity}/${id}`, objectFD, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((res) => {
      return handleResponse(res, customMessage, successCallback, noPopup)
    })
    .catch((err) => {
      swal("Error", err.response ? err.response.data : err, errorType ? errorType : "error").then(() => errorCallback && errorCallback(err))
    });
}

export function put2(id, object, entity, successCallback, customMessage, noPopup, errorType, errorCallback) {
  
  let objectFD = prepareFormData(object);
  
  axios
    .put(`/api/${entity}/${id}`, objectFD, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((res) => {
      return handleResponse(res, customMessage, successCallback, noPopup)
    })
    .catch((err) => {
      swal("Error", err.response ? err.response.data : err, errorType ? errorType : "error").then(() => errorCallback && errorCallback(err))
    });
}

export function postLog(action, message, userName, userEmail) {

  let uri = action === "Login" ? `${window.location.protocol}//${window.location.hostname}` : window.location.href.split("?")[0]
  let page = "Portal INSTICC";
  let parameters = [
    {
      name: "Date",
      value: moment(Date.now()).format(),
    },
  ];
  let bodyObject = { page, uri, action, message, parameters, userName, userEmail }
  fetch(`https://set1.insticc.org/msl/api/log`, {
    method: "POST",
    body: JSON.stringify(bodyObject
    ),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
}

function getTemplateCode(action) {
  switch (action) {
    case "Confirm Account":
      return config.confirmAccountTemplateCode
    case "Forgot Password":
      return config.resetPasswordTemplateCode
    case "Membership Aquisition":
      return config.membershipAquisitionTemplateCode
    case "Invite to Honorary":
      return config.inviteToHonoraryTemplateCode
    case "Account Terminated":
      return config.terminateAccountTemplateCode
    default:
      return config.confirmAccountTemplateCode
  }
}

export async function sendEmail(action, user) {
  let templateCode = getTemplateCode(action);
  let templateIdReq = await axios.get(
    `https://www.insticc.org/Node/TEMIS/api/hermes/getIdTemplateFromTemplateCode/${templateCode}`
    , { withCredentials: false });

  let templateID = templateIdReq.data["idTemplate"];
  const body = {
    idTemplate: templateID,
    email: user.email,
    idPerson: user._id
  };
  let templateReq = await axios.post(
    "https://www.insticc.org/Node/temis/api/hermes/instantiateTemplateWithParameters",
    body,
    { withCredentials: false }
  );
  templateReq.data["body"] = templateReq.data["body"].replace("Select a title", "")
  const emailBody = {
    email: {
      Subject: templateReq.data["subject"],
      Body: templateReq.data["body"],
      Sender: "portal@insticc.org",
      Addressee: user.email,
      CC: null,
      BCC: null,
      IdContextType: 1,
      AddresseeName: user.firstName ? user.firstName + " " + user.lastName : "",
      IsBodyHtml: true,
    },
    options: {
      production: true,
    },
  };

  if (templateReq.data["isValid"]) {
    let jsonBody = {
      clientName: 'PORTAL',
      mailingType: action,
      mailingName: templateReq.data["subject"]
    }
    var mailinkKeysData
    await axios.post('https://www.insticc.org/maiav4/api/mailings', jsonBody, { withCredentials: false })
      .then(async (mailingdata) => {
        mailinkKeysData = mailingdata.data

        emailBody.options["mailingKey"] = mailinkKeysData.mailingKey
        emailBody.options["clientTypeKey"] = mailinkKeysData.clientTypeKey
        await axios.post(
          "https://www.insticc.org/maiav4/api/emails/send",
          emailBody,
          { withCredentials: false }
        );
      })
    return true;
  }
  else
    return false;
}