import React, { Component } from "react";
import MaterialTable from "material-table";
import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import EventCard from "./EventCard";
import { Pagination } from "@material-ui/lab";
//import { mainTopics } from "./mainTopics";
import SearchIcon from "@material-ui/icons/Search";
import ChipMultiSlider from "../Custom Components/ChipMultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import RoundTextField from "../Custom Components/RoundTextField";
import Moment from "moment";
import { hasFilteredTopics } from "../../js/auxFunctions";

const itemsPerPage = 4;

const getTopicName = (matchingID, topics) => {
  for (let topicObj of topics) {
    let { ID, name } = topicObj;
    if (parseInt(matchingID) === parseInt(ID)) return name;
  }

  return "";
};

const columns = (topics) => {
  return [
    { title: "Acronymn", field: "title" },
    {
      title: "Topics",
      field: "topics",
      render: (rowData) => {
        return rowData.topics
          .map((topicID) => {
            return getTopicName(topicID, topics);
          })
          .join(", ");
      },
    },
    {
      title: "Submission Date",
      field: "submissionDate",
      render: (rowData) => {
        return Moment(rowData.submissionDate).format("DD MMM yyyy");
      },
    },
    {
      title: "Realization Date",
      render: (rowData) => {
        return (
          (Moment(rowData.startDate).format("MMM") ===
          Moment(rowData.endDate).format("MMM")
            ? Moment(rowData.startDate).format("DD-")
            : Moment(rowData.startDate).format("DD MMM - ")) +
          Moment(rowData.endDate).format("DD MMM yyyy")
        );
      },
      customSort: (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    },
  ];
};

const numberOfPages = (events, topics) => {
  let pages = 0;
  events.map((date) => {
    pages +=
      Object.values(date)[0].filter((x) => {
        return hasFilteredTopics(topics, x.interestAreas);
      }).length <= 0
        ? 0
        : 1;

    return pages;
  });

  return pages;
};

const hasAnyEventByTopic = (date, topics) => {
  return (
    Object.values(date)[0].filter((x) => {
      return hasFilteredTopics(topics, x.interestAreas);
    }).length > 0
  );
};

const filteredEventList = (list, selectedTopics) => {
  return list.filter((x) => {
    return hasFilteredTopics(selectedTopics, x.topics);
  });
};

class SubmissionDeadlines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
      isLoading: true,
      isListLoading: true,
      events: [],
      listEvents: [],
      mainTopics: [],
      filterOptions: [],
      selectedTopics: [],
      viewMode: "card",
    };
  }

  handlePaginationChange = (event, value) => {
    const diff = value - this.state.page;
    this.setState({
      page: value,
      startIndex: this.state.startIndex + diff * itemsPerPage,
      endIndex: this.state.endIndex + diff * itemsPerPage,
    });
  };

  onChipClick = (value) => {
    let newSelected = [...this.state.selectedTopics];
    if (newSelected.includes(value)) newSelected = [];
    else {
      newSelected = [];
      newSelected.push(value);
    }

    this.setState({
      selectedTopics: newSelected,
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  handleRadioChange = (event) => {
    this.setState({ viewMode: event.target.value });
  };

  async componentDidMount() {
    const resBySubmissionDate = await axios.get(
      "/api/events/bySubmissionDate/0"
    );
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });
    
    const events = resBySubmissionDate.data || [];
    let mainTopics = [];
    await fetch(
      "https://www.insticc.org/PrimorisWebServices/api/event/MainTopics"
    )
      .then((response) => response.json())
      .then((apiMainTopics) => (mainTopics = apiMainTopics))
      .catch(() => console.error("Failed to fetch API."));
    this.setState({
      events: events,
      filterOptions: topicsReq.data || [],
      isLoading: false,
      mainTopics: mainTopics,
    });

    var date = new Date();
    date.setHours(date.getHours() + 36);
    const eventsReq = await axios.post("/api/events/find", {
      query: {
        submissionDate: { $gt: date },
        $or: [{ visibleOn: null }, { visibleOn: { $lt: Date.now() } }],
      },
      sort: {
        "submissionDate.date": 1,
      },
    });
    let customData = [];
    eventsReq.data.map((x) => {
      customData.push({
        id: x._id,
        title: x.title + " / " + x.submissionType,
        topics: x.interestAreas,
        submissionDate: x.submissionDate,
        startDate: x.startDate,
        endDate: x.endDate,
      });

      return x;
    });
    this.setState({
      listEvents: customData,
      isListLoading: false,
    });
  }

  render() {
    const {
      events,
      listEvents,
      isLoading,
      isListLoading,
      mainTopics,
      filterOptions,
      selectedTopics,
      viewMode,
    } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/SubmissionDeadlines/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box pb-4">
          <h3 className="title gold">Next Submission Deadlines</h3>
          <br></br>
          <p className="body-text">
            Here you will find the submission dates for the INSTICC events that
            have an open call for papers. Using the buttons next to each event
            you can access its website or submit a paper via the PRIMORIS event
            management system.
          </p>
          <br></br>
          <div style={{ display: "none" }}>
            <RoundTextField
              placeholder="Search"
              placeholderColor="#A9ABAD"
              placeholderFontSize="22px"
              height="60px"
              borderRadius="50px"
              baseBorderColor="#ACA563"
              hoverBorderColor="#ACA563"
              focusedBorderColor="#ACA563"
              startAdornment={
                <InputAdornment
                  position="start"
                  style={{ margin: "0 30px", color: "#A9ABAD" }}
                >
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              }
            />
          </div>
        </Box>
        {!isLoading ? (
          <div>
            <div>
              <h4 className="filter-by-text mt-2">FILTER BY:</h4>
              <div className="pb-4">
                <ChipMultiSlider
                  filterOptions={filterOptions}
                  onChipClick={this.onChipClick}
                />
              </div>
              {!isListLoading && (
                <div>
                  <h4 className="filter-by-text">VIEW MODE:</h4>
                  <Grid container justify="center" className="mb-5">
                    <RadioGroup
                      row
                      name="viewMode"
                      value={viewMode}
                      onChange={this.handleRadioChange}
                    >
                      <FormControlLabel
                        value="card"
                        control={
                          <Radio color="primary" style={{ display: "none" }} />
                        }
                        label={
                          <img
                            src={
                              viewMode === "card"
                                ? "https://mdbootstrap.com/img/svg/hamburger3.svg?color=a6953f"
                                : "https://mdbootstrap.com/img/svg/hamburger3.svg?color=808080"
                            }
                            width="40"
                            height="40"
                            alt="Card"
                            title="Card View"
                          />
                        }
                        labelPlacement="start"
                        className="pr-5"
                      />
                      <FormControlLabel
                        value="list"
                        control={
                          <Radio color="primary" style={{ display: "none" }} />
                        }
                        label={
                          <img
                            src="/list-icon.png"
                            width="50"
                            height="50"
                            alt="List"
                            title="List View"
                            style={{
                              filter:
                                viewMode === "list"
                                  ? "invert(54%) sepia(60%) saturate(394%) hue-rotate(13deg) brightness(95%) contrast(90%)"
                                  : "invert(52%) sepia(0%) saturate(3993%) hue-rotate(74deg) brightness(95%) contrast(98%)",
                            }}
                          />
                        }
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                </div>
              )}
            </div>
            <Box className="main-box" style={{ paddingTop: 0 }}>
              {viewMode === "card" ? (
                <div>
                  <Grid container spacing={5}>
                    {events
                      .filter((x) => {
                        return hasAnyEventByTopic(x, selectedTopics);
                      })
                      .map((date, index) => {
                        if (
                          index >= this.state.startIndex &&
                          index <= this.state.endIndex
                        )
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              key={"Event Date Card #" + index}
                            >
                              <EventCard
                                dateObject={date}
                                mainTopics={mainTopics}
                                withCalendar
                                selectedTopics={selectedTopics}
                                borderRadius="50px"
                              />
                            </Grid>
                          );
                        return null;
                      })}
                    {Object.keys(events).length <= 0 && (
                      <h3 className="text-align-center">
                        No submission deadlines avaialable
                      </h3>
                    )}
                  </Grid>
                  <Grid container justify="center">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      style={{ padding: "50px" }}
                      count={Math.ceil(
                        numberOfPages(events, selectedTopics) / itemsPerPage
                      )}
                      page={this.state.page}
                      onChange={this.handlePaginationChange}
                    />
                  </Grid>
                </div>
              ) : (
                <MaterialTable
                  columns={columns(filterOptions)}
                  data={filteredEventList(listEvents, selectedTopics)}
                  onRowClick={(e, rowData) => {
                    return this.props.history.push(
                      "/SubmissionDeadlines/" + rowData.id
                    );
                  }}
                  options={{
                    toolbar: false,
                    search: false,
                    pageSize: 20, // make initial page size
                    pageSizeOptions: [5, 10, 20, 50], // rows selection options
                  }}
                />
              )}
            </Box>
          </div>
        ) : (
          <div className="center-box" style={{ height: "50vh" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default SubmissionDeadlines;
