import {
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import config, { fixedRoleOptions, titleOptions } from "../../../config";
import { CountryRegionData } from "react-country-region-selector";
import { Autocomplete } from "@material-ui/lab";
import StandardButton from "../../Custom Components/StandardButton";
import { postLog, put } from "../../../js/httpRequests";

export default class UserManagement extends React.Component {
  state = {
    isCityOpen: false,
    cityOptions: [],
    isCityLoading: false,
    isAdmin: false,
    user: {
      roles: [],
      email: "",
      alternativeEmail: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      country: "",
      state: "",
      city: "",
      address: "",
      zipCode: "",
      mobilePhone: "",
      fax: "",
      url: "",
      organization: "",
      department: "",
      jobTitle: "",
      isActive: true,
      isForgotten: false,
    },
    loggedUser: {},
  };

  async componentDidMount() {
    let user = await (
      await axios.get("/api/users/" + this.props.match.params.id)
    ).data;
    const isAdmin = await (await axios.get("/api/login/isAdmin")).data;
    const loggedUser = await (await axios.get("/api/login/user")).data;
    if (user) {
      for (let countryItem of CountryRegionData)
        if (countryItem[0] === user.country) user.country = countryItem;
      user.city = { name: user.city, value: user.city };
      user.isForgotten = user.end && user.isActive === false;
      this.setState({ user: user, isAdmin: isAdmin, loggedUser: loggedUser });
    } else {
      alert("Error fetching user");
      window.location.replace("/backoffice/user");
    }
  }

  onTextFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  onActiveChange = (a, value) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        isActive: value,
      },
    }));
  };

  onForgottenChange = (a, value) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        isForgotten: value,
      },
    }));
  };

  onCityTFChange = async (event) => {
    const value = event.target.value;
    if (value && value.length >= 3) {
      this.setState({
        isCityLoading: true,
      });
      const req = await axios.post(
        `https://www.insticc.org/venus/api/1.0.0/countries/${this.state.user.country[1]}/cities/find`,
        { query: { city: value } },
        { withCredentials: false }
      );
      this.setState({
        cityOptions: req.data.map((x) => {
          return {
            name: x["AccentCity"],
            value: x["AccentCity"],
          };
        }),
        isCityLoading: false,
      });
    } else
      this.setState({
        isCityLoading: false,
        cityOptions: [],
      });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let user = Object.assign({}, this.state.user);
    if (!user.isForgotten) {
      user.country = user.country[0];
    }

    user.city = user.city.value;
    user.isActive = user.isForgotten ? false : true;

    put(user._id, user, "users", () => {
      postLog(
        "User updated",
        "An admin/manager updated information of another user",
        this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName,
        this.state.loggedUser.email
      );
      window.location.href = "/backoffice/user";
    });
  };

  render() {
    const {
      _id,
      roles,
      email,
      alternativeEmail,
      title,
      firstName,
      middleName,
      lastName,
      country,
      state,
      city,
      address,
      zipCode,
      mobilePhone,
      fax,
      url,
      organization,
      department,
      jobTitle,
      isActive,
      end,
      isForgotten,
    } = this.state.user;
    const {
      isCityOpen,
      cityOptions,
      isCityLoading,
      isAdmin,
      loggedUser,
    } = this.state;
    return (
      <div className="content">
        <div className="p-5">
          <form onSubmit={this.onSubmit}>
            <h4 className="yellow-text">
              <b>Manage User</b>
            </h4>
            <br></br>
            <Paper elevation={3} className="p-5">
              <h5>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={11}>
                    <b>User Personal Details</b>
                  </Grid>
                  <Grid item sm={1}>
                    <Grid container justify="flex-end">
                      {loggedUser._id !== _id && (
                        <FormControlLabel
                          control={
                            !end ? (
                              <Tooltip
                                title={
                                  <span className="paragraph">
                                    Turn the account active/inactive. This
                                    allows the user to log in or not.{"\n"}Keeps
                                    data in DB.
                                  </span>
                                }
                              >
                                <Switch
                                  checked={isActive}
                                  onChange={this.onActiveChange}
                                  name="isActive"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <span className="paragraph">
                                    Forget the account.{"\n"}This action
                                    permanently deletes data from DB.
                                  </span>
                                }
                              >
                                <Switch
                                  checked={isForgotten}
                                  onChange={this.onForgottenChange}
                                  name="isForgotten"
                                />
                              </Tooltip>
                            )
                          }
                          label={!end ? "Active" : "Forgotten"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </h5>
              <br></br>
              <Grid container spacing={3} className={end ? "disabled" : ""}>
                <Grid item xs={12} sm={6}>
                  <label>Email:</label>
                  <TextField
                    error={!config.emailRegex.test(email)}
                    helperText={
                      !config.emailRegex.test(email)
                        ? "Invalid email format"
                        : ""
                    }
                    value={email}
                    disabled={end}
                    name="email"
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Alternative Email:</label>
                  <TextField
                    error={
                      alternativeEmail &&
                      !config.emailRegex.test(alternativeEmail)
                    }
                    helperText={
                      alternativeEmail &&
                      !config.emailRegex.test(alternativeEmail)
                        ? "Invalid email format"
                        : ""
                    }
                    value={alternativeEmail}
                    disabled={end}
                    name="alternativeEmail"
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Title:</label>
                  <TextField
                    select
                    value={title}
                    disabled={end}
                    name="title"
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  >
                    {titleOptions.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>First Name:</label>
                  <TextField
                    error={!firstName}
                    helperText={!firstName && !end ? "Required" : ""}
                    value={firstName}
                    disabled={end}
                    name="firstName"
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Middle Name</label>
                  <TextField
                    value={middleName}
                    name="middleName"
                    disabled={end}
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Last Name:</label>
                  <TextField
                    error={!lastName}
                    helperText={!lastName && !end ? "Required" : ""}
                    value={lastName}
                    name="lastName"
                    disabled={end}
                    required
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label>Country</label>
                  <TextField
                    select
                    value={country}
                    name="country"
                    disabled={end}
                    required={!end}
                    error={!country}
                    helperText={!country && !end ? "Required" : ""}
                    fullWidth
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  >
                    {CountryRegionData.map((option, index) => (
                      <MenuItem key={option[0]} value={option}>
                        {option[0]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label>State</label>
                  <TextField
                    value={state}
                    name="state"
                    fullWidth
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label>City</label>
                  <Autocomplete
                    fullWidth
                    disabled={end || !country}
                    open={isCityOpen}
                    onOpen={() => {
                      this.setState({
                        isCityOpen: true,
                      });
                    }}
                    onClose={() => {
                      this.setState({
                        isCityOpen: false,
                      });
                    }}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    options={cityOptions}
                    loading={isCityLoading}
                    onChange={(e, value) => {
                      this.setState((prevState) => ({
                        user: {
                          ...prevState.user,
                          city: value,
                        },
                      }));
                    }}
                    value={city}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          width: "100%",
                          height: "40px",
                        }}
                        variant="outlined"
                        required
                        onChange={this.onCityTFChange}
                        onClick={this.onCityTFChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isCityLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <label>Address</label>
                  <TextField
                    value={address}
                    name="address"
                    fullWidth
                    multiline
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label>Zip Code</label>
                  <TextField
                    value={zipCode}
                    name="zipCode"
                    fullWidth
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Mobile Phone</label>
                  <TextField
                    value={mobilePhone}
                    name="mobilePhone"
                    fullWidth
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <label>Fax</label>
                  <TextField
                    value={fax}
                    name="fax"
                    fullWidth
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <label>URL/Homepage</label>
                  <TextField
                    value={url}
                    name="url"
                    fullWidth
                    disabled={end}
                    onChange={this.onTextFieldChange}
                    variant="outlined"
                  />
                </Grid>
                {isAdmin ? (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <label>Roles</label>
                        <Autocomplete
                          fullWidth
                          multiple
                          getOptionSelected={(option, value) => {
                            return option === value;
                          }}
                          value={roles}
                          disabled={end}
                          getOptionLabel={(option) => option}
                          options={["User", "Admin", "Manager"]}
                          onChange={(e, newValue) =>
                            this.setState((prevState) => ({
                              user: {
                                ...prevState.user,
                                roles: [
                                  ...fixedRoleOptions,
                                  ...newValue.filter(
                                    (option) =>
                                      fixedRoleOptions.indexOf(option) === -1
                                  ),
                                ],
                              },
                            }))
                          }
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={option}
                                {...getTagProps({ index })}
                                disabled={
                                  fixedRoleOptions.indexOf(option) !== -1
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
            {!end && (
              <Paper elevation={3} className="p-5 mt-3">
                <h5>
                  <b>User Professional Details</b>
                </h5>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <label>Organization</label>
                    <TextField
                      value={organization}
                      name="organization"
                      multiline
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label>Department</label>
                    <TextField
                      value={department}
                      name="department"
                      multiline
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label>Job Title</label>
                    <TextField
                      value={jobTitle}
                      name="jobTitle"
                      multiline
                      fullWidth
                      onChange={this.onTextFieldChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>
            )}
            <br></br>
            <Grid container justify="center">
              <StandardButton type="submit" variant="outlined">
                Save
              </StandardButton>
            </Grid>
          </form>
        </div>
      </div>
    );
  }
}
