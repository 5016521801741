import { Box, createMuiTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import React from "react";
import ResearcherCard from "./ResearcherCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../../config";
import MoreButton from "../../Custom Components/MoreButton";
import { getTopicName } from "../../../js/auxFunctions";
import queryString from "query-string";

function buildHref(link, year, event) {
  return (
    "<a href='" +
    link +
    "' rel='noopener noreferrer' target='_blank'>" +
    event +
    " " +
    year +
    "</a>"
  );
}

function getRelevantRoleText(participations, limit) {
  let arr = [];
  let flag = false;

  for (let i = 0; i < participations.length; i++) {
    if (i >= limit) break;

    let participation = participations[i];
    for (let j = 0; j < arr.length; j++) {
      if (arr[j].startsWith(participation.role)) {
        arr[j] +=
          ", " +
          buildHref(
            participation.link,
            participation.year,
            participation.acronym
          );
        flag = true;
      }
    }

    // Not found, create a new
    if (!flag) {
      switch (participation.role) {
        case "Keynote Speaker":
        case "Best Paper Award":
        case "Best Student Paper Award":
          arr.push(
            participation.role +
              " at " +
              buildHref(
                participation.link,
                participation.year,
                participation.acronym
              )
          );
          break;
        case "Conference Chair":
        case "Program Chair":
          arr.push(
            participation.role +
              " of " +
              buildHref(
                participation.link,
                participation.year,
                participation.acronym
              )
          );
          break;
        case "Workshop Chair":
        case "Special Session Chair":
          arr.push(
            participation.role +
              " of " +
              participation.acronym +
              (participation.mainEventAcronym
                ? " at " +
                  buildHref(
                    participation.link,
                    participation.year,
                    participation.mainEventAcronym
                  )
                : "")
          );
          break;
        default:
          arr.push(
            participation.role +
              " - " +
              buildHref(
                participation.link,
                participation.year,
                participation.acronym
              )
          );
          break;
      }
    }
    flag = false;
  }

  return arr.join("\n");
}

const customBPs = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 800, md: 960, lg: 1280, xl: 1920 },
  },
});

class ResearcherDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      researcher: {},
      relatedNews: [],
      topics: [],
      isLoading: true,
      currentWidth: 0,
    };
  }

  orderParticipationsDesc = () => {
    let { researcher } = this.state;
    researcher.participations.sort(function (a, b) {
      if (a.year === b.year) return 0;
      else if (a.year < b.year) return 1;
      else if (a.year > b.year) return -1;

      return 0;
    });
  };

  updateWidth = () => {
    this.setState({ currentWidth: window.innerWidth });
  };

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    const res = await axios.get(
      "/api/researchers/" + this.props.match.params.id
    );
    const relatedRes = await axios.get(
      "/api/researchers/" +
        this.props.match.params.id +
        "/related/" +
        "?n=4" +
        (params.refID ? "&refID=" + params.refID : "")
    );
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    this.setState({
      researcher: res.data,
      relatedResearchers: relatedRes.data,
      topics: topicsReq.data,
      isLoading: false,
    });
    const researcher = res.data;
    document.title =
      "Researcher - " +
      researcher.firstName +
      " " +
      researcher.middleName +
      " " +
      researcher.lastName;

    this.orderParticipationsDesc();
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  }

  render() {
    const { researcher, relatedResearchers, isLoading, topics } = this.state;
    if (isLoading)
      return (
        <div className="content center-box" style={{ height: "100vh" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div className="content">
          <img
            src="https://insticc.org/images/portal/Banners/Researchers/original.png"
            alt="Banner"
            style={{ width: "100%" }}
          ></img>
          <Box className="main-box">
            <img
              className="center"
              style={{ borderRadius: "100%" }}
              src={config.researchersImageBasePath + researcher.mainPhoto + '?=' + Math.random()}
              width="300"
              height="300"
              alt="Profile"
            />
            <br></br>
            <h2 className="title gold text-align-center">
              {researcher.title} {researcher.firstName} {researcher.middleName}{" "}
              {researcher.lastName}
            </h2>
            <h4 className="researcher-details-subtitle text-align-center">
              {researcher.affiliation} | {researcher.location}
            </h4>
            <h4
              className="researcher-details-subtitle text-align-center paragraph"
              dangerouslySetInnerHTML={{
                __html: getRelevantRoleText(researcher.participations, 3),
              }}
            ></h4>
            <br></br>
            <br></br>
            <div
              dangerouslySetInnerHTML={{
                __html: researcher.content,
              }}
            />
            <br></br>
            <p>
              <span className="researcher-details-subtitle">Topics:</span>{" "}
              {researcher.interestAreas
                .map((id) => {
                  return getTopicName(id, topics);
                })
                .join(", ")}
            </p>
            <br></br>
            <h4 className="subtitle fw-bold text-align-center">
              Related Researchers
            </h4>
            <MuiThemeProvider theme={customBPs}>
              <Grid container spacing={1} component={Box} p={5}>
                {relatedResearchers
                  .filter((x, i) => {
                    return (
                      (this.state.currentWidth <= 1600 && i < 3) ||
                      this.state.currentWidth > 1600
                    );
                  })
                  .map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={this.state.currentWidth <= 1600 ? 4 : 3}
                        key={"Related" + index}
                      >
                        <ResearcherCard
                          imageXS={4}
                          contentXS={8}
                          researcher={item}
                          refID={this.state.researcher._id}
                          showDescription={false}
                          borderRadius="50px"
                          imageHeight="100px"
                          imageWidth="100px"
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid container justify="center">
                <MoreButton
                  title="More Reseachers"
                  href={config.researchersBasePath}
                />
              </Grid>
            </MuiThemeProvider>
          </Box>
        </div>
      );
  }
}

export default ResearcherDetails;
