import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { departments } from "../AboutUs/departmentsArray";
import HtmlTooltip from "../Custom Components/HtmlTooltip";

class ReasonsToPublish extends Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/ReasonsToPublish/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Reasons to Publish</h3>
          <br></br>
          <p className="paragraph body-text">
            Till today, scientific publications remain the main drive of science
            advancement and knowledge dissemination. INSTICC publication
            activities include the publication of proceedings from sponsored
            events and publications in academic journals and books, often
            published in cooperation with international partners.
            {"\n\n"}
            <ul>
              <li>
                A rigorous double blind review process performed by highly
                qualified researchers, including feedback and improvement
                suggestions;
              </li>{" "}
              <li>
                Reputable publications, with under strict quality-assurance
                policies, innovative focus and anti-plagiarism control;
              </li>{" "}
              <li>
                Submission to all the major indexing instances, including DBLP,
                Elsevier EI, SCOPUS, Thomson Reuters Proceedings Citation Index
                and INSPEC;
              </li>{" "}
              <li>
                All publications are registered with CrossRef - the official DOI
                registration agency for scholarly and professional publications
              </li>
            </ul>
          </p>
          <br></br>
          <h4 className="subtitle">
            <b>Supporting Research</b>
          </h4>
          <p></p>
          <p className="paragraph body-text">
            Research Groups are often at the forefront of research and are
            unique in their full dedication and commitment towards innovation
            and the advancement of technology. INSTICC can provide Research
            Groups and Research Projects with further opportunities for
            divulging their work, from international conferences to academic
            journals, from the INSTICC newsletters to the INSTICC networks of
            researchers, academics and practitioners.{"\n"}Those interested in
            publishing books, reports, newsletters, or any other scientific
            publication with INSTICC, in the scope of a research group or
            research project, should contact our{" "}
            <HtmlTooltip
              department={departments[3]}
              text="Institutional Relations Office"
            />
            .
          </p>
        </Box>
      </div>
    );
  }
}

export default ReasonsToPublish;
