module.exports = {
    newsBasePath: "/NewsArchive",
    newsImageBasePath: "",
    researchersBasePath: "/ResearchersArchive",
    researchersImageBasePath: "",
    keynotesInterviewsBasePath: "/KeynotesInterviewsArchive",
    keynotesInterviewsImageBasePath: "/KL/",
    eventsBasePath: "/SubmissionDeadlines",
    eventsImageBasePath: "",
    partnersBasePath: "/PartnersArchive",
    partnersImageBasePath: "",
    attendAnEventImageBasePath: "/AttendAnEvent/",
    usersImagePath: "/Users/",
    attendAnEventItems: 20,
    confirmAccountTemplateCode: "PRIMORIS.N.Person.14",
    resetPasswordTemplateCode: "PRIMORIS.N.Person.15",
    membershipAquisitionTemplateCode: "PRIMORIS.N.PERSON.16",
    inviteToHonoraryTemplateCode: "PRIMORIS.N.Person.17",
    terminateAccountTemplateCode: "PRIMORIS.N.Person.19",
    fixedRoleOptions: ["User"],
    managerRoleOptions: [],
    adminRoleOptions: ["Admin", "Manager"],
    titleOptions: [
        { value: 1, label: "Select a title" },
        { value: 2, label: "Mr." },
        { value: 3, label: "Mrs." },
        { value: 4, label: "Ms." },
        { value: 5, label: "Dr." },
        { value: 6, label: "Prof." },
        { value: 7, label: "Eng." },
    ],
    stringyFields: ["insertedDate", "publishedDate", "memberships", "period", "membershipSource", "institutionalData"],
    simpleArrays: ["interestAreas", "roles"],
    emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    specialRegex: /[*|":<>[\]{}`\\()';@&$]/,
    passwordRegex:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).+$/,
    //new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~\\\-]).+$")
};
