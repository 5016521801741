import { Card, CardActionArea, Grid } from "@material-ui/core";
import React from "react";

export default class ResultItem extends React.Component {
  render() {
    const {
      photo,
      video,
      title,
      subtitle,
      description,
      href,
      width,
      maxHeight,
    } = this.props;
    return (
      <Card elevation={5}>
        <CardActionArea href={href}>
          <Grid container>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              {photo ? (
                <div className="frame" style={{ margin: 0 }}>
                  <span className="img-center-helper" />
                  <img
                    src={photo}
                    alt={title}
                    style={{
                      width: width || "100%",
                      maxHeight: maxHeight || "none",
                      verticalAlign: "middle",
                    }}
                  />
                </div>
              ) : null}
              {video ? <iframe src={video} title={title} /> : null}
            </Grid>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              <div className="p-2">
                <h4 style={{ fontSize: "100%" }}>{title}</h4>
                <h5 style={{ fontSize: "100%" }}>{subtitle}</h5>
                <br></br>
                {description}
              </div>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
