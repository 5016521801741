import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import Axios from "axios";
import {
  buildTree,
  tree2NavigationJSON,
  getItemChildren,
} from "../js/auxFunctions";




const increment = 1;

function renderNavbarColumn(item) {
  let children = [];
  getItemChildren(item, 0, increment, children);
  children.shift();

  let childrenDiv = [];

  children.forEach((x) => {
    childrenDiv.push(
      <a href={x.link} className="white-link" key={"Item: " + x.name}>
        <h5 className={"footer-column-item-" + x.variable}>
          <b>{x.name}</b>
        </h5>
      </a>
    );
  });

  let mainDiv = (
    <Grid item component={Box} xs key={"Main item: " + item.value.name}>
      <Grid container>
        <Grid item xs={11} className="footer-item">
          <h3 className="footer-column-title gold">
            <b>{item.value.name}</b>
          </h3>
          <br></br>
          {childrenDiv}
        </Grid>
        <Grid item xs={1}>
          <hr className="vertical-hr" />
        </Grid>
      </Grid>
    </Grid>
  );

  return mainDiv;
}

class Footer extends React.Component {
  state = {
    jsonTree: [],
  };

  async componentDidMount() {
    let jsonTree;
    if (!this.props.jsonTree) {
      const navigationData = await (
        await Axios.post("/api/navigations/find", {
          query: {
            type: "frontoffice",
          },
          sort: {
            ID: 1
          }
        })
      ).data;
      const navigationItems = navigationData ? navigationData : [];

      let tree = buildTree(navigationItems);
      jsonTree = tree2NavigationJSON(tree);
    } else jsonTree = this.props.jsonTree;
    this.setState({ jsonTree: jsonTree });
  }

  componentDidUpdate() {
    if (this.props.jsonTree)
      if (this.props.jsonTree.length > 0 && !this.state.completed)
        this.setState({ jsonTree: this.props.jsonTree, completed: true });
  }

  render() {
    const { jsonTree } = this.state;
    return (
      <Box className="footer-bg main-box" boxShadow={10}>
        <Grid container justify="space-evenly" spacing={5}>
          {jsonTree.map((x) => {
            return renderNavbarColumn(x);
          })}
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <img
              src="/insticc-footer.png"
              style={{ width: "70%" }}
              alt="FAQs"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justify="flex-end" alignItems="center">
              <IconButton
                arial-label="Vimeo"
                className="no-outline"
                href="https://vimeo.com/insticc"
                target="_blank"
              >
                <img src="/vimeo-icon.png" width="30" alt="Vimeo" />
              </IconButton>
              <IconButton
                arial-label="Twitter"
                className="no-outline"
                href="https://twitter.com/insticc?lang=en"
                target="_blank"
              >
                <img src="/twitter-icon.png" width="30" alt="Twitter" />
              </IconButton>
              <IconButton
                arial-label="Facebook"
                className="no-outline"
                href="https://www.facebook.com/INSTICC"
                target="_blank"
              >
                <img src="/face-icon.png" width="30" alt="Facebook" />
              </IconButton>
              <IconButton
                arial-label="Youtube"
                className="no-outline"
                href="https://www.youtube.com/channel/UCz_xak2FNgsjqvzxOeGO3NA"
                target="_blank"
              >
                <img src="/youtube-icon.png" width="30" alt="Youtube" />
              </IconButton>
              <Button
                href="/Faqs"
                className="ml-1 no-outline"
                style={{ borderRadius: "50px" }}
                startIcon={
                  <Grid container justify="center">
                    <img
                      src="/faqs.png"
                      width="100"
                      className="ml-2 mb-1"
                      alt="FAQs"
                    />
                  </Grid>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        
      </Box>
    );
  }
}

export default withRouter(Footer);
