import { Box, Grid } from "@material-ui/core";
import React from "react";
import MultiSlider from "../MultiSlider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import config from "../../config";
import MoreButton from "../Custom Components/MoreButton";
import HtmlTooltip from "../Custom Components/HtmlTooltip";
import { departments } from "../AboutUs/departmentsArray";

class AcademicPartners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      partners: [],
    };
  }

  async componentDidMount() {
    const res = await axios.get("/api/partners/byType/Academic Partner");
    this.setState({
      partners: res.data,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, partners } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/AcademicPartners/original.png"
          alt="Banner"
          style={{ width: "100%" }}
        ></img>
        <Box className="main-box">
          <h3 className="title gold">Academic Partners</h3>
          <br></br>
          <br></br>
          <h4 className="subtitle">
            Partnership Opportunities for Academic Partners
          </h4>
          <br></br>
          <p className="paragraph body-text">
            The development of scientific networks is of vital importance to
            universities and other academic institutions. Research Groups are
            often at the forefront of research and are unique in their full
            dedication and commitment towards innovation and the advancement of
            technology.{"\n"} A partnership with INSTICC publications or INSTICC
            events can be beneficial for students and research groups,
            increasing their visibility, showcasing their research and expanding
            their networks in the fields of Information, Control and
            Communication, from international conferences to the INSTICC
            networks of researchers, academics and practitioners.
          </p>
          <br></br>
          <h4 className="subtitle">INSTICC Events</h4>
          <br></br>
          <p className="paragraph body-text">
            Universities and other academic institutions can benefit from
            INSTICC events by presenting their research and scientific results,
            be it by presenting a paper or hosting a tutorial or instructional
            course, by contributing towards panels and discussions in the
            event's field of interest or by presenting their organization, be it
            by setting up an exhibition booth, by profiling themselves in the
            event's web presence or printed materials or by suggesting keynote
            speakers or even new events that can be organized cooperatively.
            {"\n\n"}
            Universities are also excellent candidates to hosting an academic
            event - scientists generally favor their relaxed environment over
            congress centers and hotels and they are an opportunity for the
            institution to present itself and its facilities to researchers,
            graduate students and professors worldwide.
          </p>
          <br></br>
          <h4 className="subtitle">Contact</h4>
          <br></br>
          <p className="paragraph body-text">
            INSTICC is open to partnership possibilities beyond the examples
            mentioned here and welcomes suggestions and cooperation proposals.
            Those interested in cooperating with INSTICC should contact our{" "}
            <HtmlTooltip
              department={departments[3]}
              text="Institutional Relations Office"
            />
            .
          </p>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <MultiSlider
              items={partners}
              autoPlay
              borderColor="transparent"
              arrows={false}
              baseRef={config.partnersBasePath}
              imageBaseRef={config.partnersImageBasePath}
            />
          )}
          <Grid container justify="center" className="pt-5">
            <MoreButton
              title="More Academic Partners"
              href={config.partnersBasePath + "?type=Academic Partner"}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default AcademicPartners;
