import { Card, CardActionArea, Grid } from "@material-ui/core";
import React from "react";
import config from "../../../config";

class NewsCard extends React.Component {
  render() {
    

    if (!this.props.textOnTop) {
      return (
        <Card elevation={0} className="transparent-bg">
          <CardActionArea
            href={
              config.newsBasePath +
              "/" +
              this.props.news._id +
              (this.props.refID ? "?refID=" + this.props.refID : "")
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Grid container justify="center">
                  <img
                    className="card-image"
                    src={config.newsImageBasePath + this.props.news.mainPhoto}
                    alt="News"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <br></br>
                <div className="pl-2 pr-2">
                  <h4
                    className="news-card-title"
                    style={{ height: this.props.titleHeight }}
                  >
                    {this.props.showDescription == true ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.news.highlightedText,
                        }}
                      />
                    ) : <b>{this.props.news.title}</b>}
                  </h4>
                  <br></br>

                </div>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      );
    } else {
      return (

        <Card
          elevation={0}
          className="homepage-news-highlight-card"
          style={{ backgroundColor: "transparent" }}
        >
          <CardActionArea
            href={config.newsBasePath + "/" + this.props.news._id}
            className="h-100"
          >
            <div className="h-100">
              <img
                className="homepage-news-highlight-image"
                src={config.newsImageBasePath + this.props.news.mainPhoto}
                alt="News"
              />
              <div className="homepage-news-highlight-text">
                <h5 className="line-clamp">
                  {this.props.showDescription == true ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.news.highlightedText.replace(/<span .*?>/g,"").replace('</span>',""),
                      }}
                    />
                  ) : this.props.news.title}</h5>
              </div>
              <div className="homepage-news-highlight-bottom-div" />
            </div>
          </CardActionArea>
        </Card>
      );
    }
  }
}

export default NewsCard;
