import { Box } from "@material-ui/core";
import React from "react";

class ProposeAnEvent extends React.Component {
  render() {
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/ProposeAnEvent/original.png"
          alt="Banner"
          className="w-100"
        ></img>
        <Box className="main-box">
          <h4 className="title gold">
            Why co-locate your event with an INSTICC event?
          </h4>
          <br></br>
          <p className="body-text">
            <ul>
              <li>
                Logistics are taken care of and you can be entirely dedicated to
                the scientific aspects of your event;
              </li>
              <br></br>
              <li>
                Event website can be created and/or updated and managed by a
                professional team;
              </li>
              <br></br>
              <li>
                Event management system support, enabling control and/or simply
                supervision of all main steps of the process, including
                submission, review, selection, registration and others;
              </li>
              <br></br>
              <li>
                Dedicated personnel to follow-up all communication exchanges
                with authors and reviewers at the event secretariat;
              </li>
              <br></br>
              <li>
                Ease of publication, post-publication and indexation of papers
                presented at your event (proceedings, book of abstracts,
                technical program, digital library and more).
              </li>
            </ul>
            <p className="mt-5">
              We will be happy to work with you and configure the best possible
              event plan; our experience with more than 500 scientific/technical
              events, in all continents, makes it easier to resolve all
              organizing issues for you, so you can concentrate on the
              scientific/technical aspects of the event.
            </p>
            <p className="paragraph">
              If you want to know more about the types of events that can be
              organized with INSTICC, we would like to invite you to check our{" "}
              <a
                href="/SubmissionDeadlines"
                target="_blank"
                className="yellow-link"
                rel="noopener noreferrer"
              >
                events webpage
              </a>
              .{"\n"} To propose an event please contact us at{" "}
              <a href="mailto:events@insticc.org" className="yellow-link">
                events@insticc.org
              </a>
              .
            </p>
          </p>
        </Box>
      </div>
    );
  }
}

export default ProposeAnEvent;
