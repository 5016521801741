import React, { Component } from "react";
import { Box } from "@material-ui/core";

class Copyright extends Component {
  render() {
    return (
      <div className="content">
        <img src="https://insticc.org/images/portal/Banners/Copyright/original.png" alt="Banner" className="w-100"></img>
        <Box className="main-box">
          <h3 className="title gold">Copyright</h3>
          <br></br>
          <p className="paragraph body-text">
            In all INSTICC publications, the copyright to the contribution is
            transferred from the Author to the Publisher, i.e. Science and
            Technology Publications, (SCITEPRESS) Lda. The copyright transfer
            covers the rights to reproduce and distribute the contribution,
            including reprints, translations, photographic reproductions,
            microform, electronic form (offline, online), or any other
            reproductions of similar nature, including book publication.{"\n"}
            {"\n"}The Author retains the rights to publish the contribution in
            his/her own website or in his/her employer’s website, as long as it
            is clearly stated in which publication or event it was originally
            published in and a link to the original publication or event is
            made.{"\n"}
            {"\n"}The Author warrants that his/her contribution is original,
            except for such excerpts from copyrighted works as may be included
            with the permission of the copyright holder and author thereof, that
            it contains no libelous statements and does not infringe on any
            copyright, trademark, patent, statutory right, or propriety right of
            others. The Author signs for and accepts responsibility for
            releasing this material on behalf of any and all co-authors.
            {"\n"}
            {"\n"}In return for these rights, the publisher agrees to have the
            identified contribution published, at its own cost and expense, in
            the publication or conference proceedings the paper is submitted to.
            {"\n"}
            {"\n"}
            <b className="bold">Please note:</b> This is a shortened version of
            the copyright agreement which authors will sign when publishing at
            an INSTICC event or publication. This agreement can vary and it is
            important to read the specific copyright agreement for each event or
            publication thoroughly.{"\n"}
          </p>
        </Box>
      </div>
    );
  }
}

export default Copyright;
