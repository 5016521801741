import { Box, Grid, InputAdornment } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import KeynoteInterviewCard from "./KeynoteInterviewCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ChipMultiSlider from "../../Custom Components/ChipMultiSlider";
import { hasFilteredTopics } from "../../../js/auxFunctions";
import RoundTextField from "../../Custom Components/RoundTextField";
import SearchIcon from "@material-ui/icons/Search";

const itemsPerPage = 100;

function nameContainsSearchInput(searchInput, researcher) {


  const { personTitle, firstName, middleName, lastName, title, event } = researcher;
  const fullName =
    (event ? event.year + " " : "") +
    (title ? title + " " : "") +
    (personTitle ? personTitle + " " : "") +
    (firstName ? firstName + " " : "") +
    (middleName ? middleName + " " : "") +
    lastName;

  return (fullName !== undefined ? fullName.toLowerCase().includes(searchInput.toLowerCase()) : "");

}

class KeynotesInterviewsArchive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
      isLoading: true,
      keynotesInterviews: [],
      filterOptions: [],
      selectedTopics: [],
      searchInput: "",
    };
  }

  handlePaginationChange = (event, value) => {
    const diff = value - this.state.page;
    this.setState({
      page: value,
      startIndex: this.state.startIndex + diff * itemsPerPage,
      endIndex: this.state.endIndex + diff * itemsPerPage,
    });
  };

  onChipClick = (value) => {
    let newSelected = [...this.state.selectedTopics];
    if (newSelected.includes(value)) newSelected = [];
    else {
      newSelected = [];
      newSelected.push(value);
    }

    this.setState({
      selectedTopics: newSelected,
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  async componentDidMount() {
    const res = await axios.get("/api/keynotesInterviews");
    const topicsReq = await axios.post("/api/topics/find", {
      query: {
        isFilter: true,
      },
      sort: {
        name: 1,
      },
    });

    res.data.forEach((e) => {
      let topicsName = [];
      e.interestAreas.forEach((e) => {
        let foundTopics = topicsReq.data.find((t) => t.ID === e);
        if (foundTopics) {
          topicsName.push(foundTopics.name);
        }

      });
      e.topics = topicsName;
    });

    this.setState({
      keynotesInterviews: res.data || [],
      filterOptions: topicsReq.data || [],
      isLoading: false,
    });
    
  }
  onTextFieldChange = (event) => {

    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.resetNavigation();
  };

  resetNavigation = () => {
    this.setState({
      page: 1,
      startIndex: 0,
      endIndex: itemsPerPage - 1,
    });
  };

  render() {
    const {
      keynotesInterviews,
      isLoading,
      filterOptions,
      selectedTopics,
      searchInput,
    } = this.state;
    return (
      <div className="content">
        <img
          src="https://insticc.org/images/portal/Banners/KeynotesAndInterviews/original.png"
          alt="Banner"
          className="w-100"
        />
        {!isLoading && (
          <div className="pt-5 pb-5">
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <RoundTextField
                placeholder="Search"
                placeholderColor="#A9ABAD"
                placeholderFontSize="22px"
                height="60px"
                borderRadius="20px"
                baseBorderColor="#ACA563"
                hoverBorderColor="#ACA563"
                focusedBorderColor="#ACA563"
                onChange={this.onTextFieldChange}
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ margin: "0 30px", color: "#A9ABAD" }}
                  >
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                }
                name="searchInput"
              />
            </div>
            <h4 class="kl-card-title" style={{ marginLeft: 189 }}><b>Select a keyword to filter</b></h4>
            <ChipMultiSlider
              filterOptions={filterOptions}
              onChipClick={this.onChipClick}
            />
          </div>
        )}
        <Box className="main-box" style={{ paddingTop: 0 }}>
          {isLoading ? (
            <div className="center-box" style={{ height: "50vh" }}>
              <CircularProgress />
            </div>
          ) : (
              <div>
                <Grid container component={Box} spacing={5}>
                  {keynotesInterviews
                    .filter(function (item, index) {
                      return hasFilteredTopics(
                        selectedTopics,
                        item.interestAreas
                      ) &&
                        nameContainsSearchInput(searchInput, item);
                    })
                    .map((keynoteInterview, index) => {
                      if (
                        index >= this.state.startIndex &&
                        index <= this.state.endIndex
                      )
                        return (
                          <Grid item xs={12} sm={6} lg={3}>
                            <KeynoteInterviewCard
                              keynoteInterview={keynoteInterview}

                              minTitleHeight="80px"
                              showDescription={true}
                            />
                          </Grid>
                        );
                      return null;
                    })}
                  {keynotesInterviews.length <= 0 && (
                    <h3 className="text-align-center">No news avaialable</h3>
                  )}
                </Grid>
              </div>
            )}
          <Grid container justify="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              style={{ padding: "50px" }}
              count={Math.ceil(
                keynotesInterviews.filter((x) => {
                  return hasFilteredTopics(selectedTopics, x.interestAreas) &&
                    nameContainsSearchInput(searchInput, x);
                }).length / itemsPerPage
              )}
              page={this.state.page}
              onChange={this.handlePaginationChange}
            />
          </Grid>
        </Box>
      </div>
    );
  }
}

export default KeynotesInterviewsArchive;
