import { Button, Grid } from "@material-ui/core";
import React from "react";

export default class Tabs extends React.Component {
  state = {
    sm: 12 / this.props.items.length,
  };

  render() {
    const { sm } = this.state;
    const { items, selected, fullWidthItems, spacing, className } = this.props;
    return (
      <Grid
        container
        justify="center"
        className={className}
        spacing={spacing || 3}
      >
        {items.map((x) => (
          <Grid item xs={12} sm={sm}>
            <Grid container justify="center" alignItems="center">
              {selected !== x.name ? (
                <Button
                  fullWidth={fullWidthItems}
                  variant="outlined"
                  className="white-outline-button"
                  href={x.href}
                >
                  {x.name}
                </Button>
              ) : (
                <Button
                  fullWidth={fullWidthItems}
                  className="gray-button"
                  href={x.href}
                >
                  {x.name}
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}
